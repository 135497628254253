// Context for Project Data (Global State)
import { createContext, useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserProvider";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const apiUrl = process.env.REACT_APP_API_URL;

// Create Context
export const UserDataContext = createContext(); 

// Provide Context
export const UserDataProvider = ({ children }) => {
    const { user, logout } = useContext(UserContext);
    const [userFirstName, setUserFirstName] = useState("");
    const [userProfilePic, setUserProfilePic] = useState("");
    const hasFetched = useRef(false);
    const navigate = useNavigate();



    const handleLogout = async () => {
        try {
            if (!user || !user.token) {
                return;
            }

            const response = await fetch(`${apiUrl}/api/logout/`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'An error occurred');
            }
    
            const data = await response.json();
            
            logout(); 

            toast.success(data.message, {
                position: "bottom-right",
            });
    
            setTimeout(() => navigate("/login"), 100);
    
        } catch (error) {
            console.error("Logout Error:", error); 
            toast.error(`${error.message || "Logout failed"}`, {
                position: "bottom-right"
            });
        }
    };


    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                if (!user || !user.token) return; 
                
                const response = await fetch(`${apiUrl}/api/user-details/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });

                if (response.status === 401) {
                    console.warn("Unauthorized access, redirecting...");
                    navigate("/"); // Redirect to home if token is expired/invalid
                    return;
                }
    
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
    
                const data = await response.json();
                setUserFirstName(data.user_firstname);
                setUserProfilePic(data.user_img);
            } catch (error) {
                console.error("Error fetching user profile:", error);
            }
        };
    
        fetchUserProfile();
    }, [user]);


    



  return (
    <UserDataContext.Provider value={{ userFirstName, setUserFirstName, userProfilePic, setUserProfilePic, handleLogout  }}>
      {children}
    </UserDataContext.Provider>
  );
};

