import React, { useContext, useState, useEffect, useRef } from "react";
import ProjectWorkspaceNav from "./ProjectWorkspaceNav";
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import { useProject } from "./ProjectData";
import TeamMemberContext from "./TeamMemberContext";
import { FaAngleDown } from "react-icons/fa6";
import "../Styles/AddTeamPermissions.css";

const AddTeamPermission = () => {
    const { activeProject } = useProject();
    const { formData, setFormData } = useContext(TeamMemberContext);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const accessName = [
        "View",
        "Edit",
        "Review",
        "Approve",
        "Full Access"
    ]




    const FlexibleDiv = ({ width, children, bgc }) => {
        return (
            <div
                style={{
                    width: width,
                    border: "0.5px solid #a1a1a1",
                    borderRadius: "10px",
                    backgroundColor: bgc,
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                }}
            >
                {children}
            </div>
        );
    };


    return (
        <>
            <div id="userpage_container">

                <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader pageName={`Add Team Member Permissions`} />
                    <div className="ProjectWorkspaceBody">
                        <div className="team_permission_frame">
                            <p className="text14px600 mt-2">Custom Permissions (Optional)<FaAngleDown color="#c4c4c4"/></p>

                            <div className="row team_permissions_form_body mt-4">
                                <div className="col-md-4">
                                    <p className="team_permissions_title">Project Overview & Scope</p>
                                    <div className="permission_checkbox">
                                    {accessName.map((access, index) => (
                                        <label key={index} className="d-flex align-items-center gap-2">
                                            <input
                                                type="radio"
                                                name="projectOverviewAndScope"
                                                className="custom-radio"
                                                value={access}
                                                onChange={handleChange}
                                                checked={formData.projectOverviewAndScope === access} 
                                            />
                                            <span>{access}</span>
                                        </label>
                                    ))}
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <p className="team_permissions_title">Task & Workflow Management</p>
                                    <div className="permission_checkbox">
                                    {accessName.map((access, index) => (
                                        <label key={index} className="d-flex align-items-center gap-2">
                                            <input
                                                type="radio"
                                                name="taskAndWorkflowManagement"
                                                className="custom-radio"
                                                value={access}
                                                onChange={handleChange}
                                                checked={formData.taskAndWorkflowManagement === access} 
                                            />
                                            <span>{access}</span>
                                        </label>
                                    ))}
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <p className="team_permissions_title">Project Files & Documentation</p>
                                    <div className="permission_checkbox">
                                    {accessName.map((access, index) => (
                                        <label key={index} className="d-flex align-items-center gap-2">
                                            <input
                                                type="radio"
                                                name="projectFilesAndDocumentations"
                                                className="custom-radio"
                                                value={access}
                                                onChange={handleChange}
                                                checked={formData.projectFilesAndDocumentations === access} 
                                            />
                                            <span>{access}</span>
                                        </label>
                                    ))}
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <p className="team_permissions_title">Team Management & Collaboration</p>
                                    <div className="permission_checkbox">
                                    {accessName.map((access, index) => (
                                        <label key={index} className="d-flex align-items-center gap-2">
                                            <input
                                                type="radio"
                                                name="teamManagenentAndCollaboration"
                                                className="custom-radio"
                                                value={access}
                                                onChange={handleChange}
                                                checked={formData.teamManagenentAndCollaboration === access} 
                                            />
                                            <span>{access}</span>
                                        </label>
                                    ))}
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <p className="team_permissions_title">Budget & Financial Control</p>
                                    <div className="permission_checkbox">
                                    {accessName.map((access, index) => (
                                        <label key={index} className="d-flex align-items-center gap-2">
                                            <input
                                                type="radio"
                                                name="budgetAndFinancialControl"
                                                className="custom-radio"
                                                value={access}
                                                onChange={handleChange}
                                                checked={formData.budgetAndFinancialControl === access} 
                                            />
                                            <span>{access}</span>
                                        </label>
                                    ))}
                                    </div>

                                </div>


                            </div>




                            <div className="ru_2f2f2f_div">
                                <p className="text16px600">Team Member Invitation – Seat Cost Notification</p>
                                <p className="text12px400 mt-2">💡 Adding a new team member may require an additional seat purchase.</p>
                                <ul className="mt-4 d-flex flex-column gap-2">
                                    <li className="text12px400">Plan Limit: Your current plan allows 5 seats, and you have 4 seats occupied.</li>
                                    <li className="text12px400">New Member Role: Project Manager</li>
                                    <li className="text12px400">Additional Seat Cost: N20/month (Billed Annually: N240/year)</li>
                                    <li className="text12px400">Billing Confirmation: This amount will be added to your next billing cycle.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <ProjectWorkspaceChat projectId={activeProject.id} pageName="Project Team" />
            </div>
        </>
    );
};

export default AddTeamPermission;
