import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Styles/PasswordResetLinkPage.css'


const PasswordResetLink=() =>{
    const location = useLocation();
    const email = location.state?.email || "";



    return (
        <>
        {/* Password reset link Section Start */}
        <section className='password_reset_section'>
            <div className='row'>
                <div className='col-md-7 col-lg-4 text-center'>
                    <div align='center' className='mb-4'>
                        <img src='/static/images/big_message_icon.png' className='img-fluid' alt='DesignXcel Logo'/>
                    </div>
                    <p className='verify_email_title'>A verification link has been sent to<br/>{email}. Click on the link to update<br/>your password.</p> 
                    <Link className='change_email_link' to='/forgot-password'>Change Email Address</Link>
                    <div align='center'>
                        <img src='/static/images/designxcel_logo.png' className='img-fluid logo' alt='Designxcel Logo'/>
                    </div>
                </div>
            </div>
        </section>
        {/* Password reset link Section End */}
        </>
    )
}


export default PasswordResetLink