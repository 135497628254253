import React, { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from "../UserProvider";
import ProjectWorkspaceNav from "./ProjectWorkspaceNav";
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter";
import "../Styles/Hire.css";

const ContractAndPayment = () => {
    const [isChecked, setIsChecked] = useState(false);
    const { user } = useContext(UserContext);
    const [chatHistory, setChatHistory] = useState([]);

    // Address Bar for the In-App Browser

    // Ref for the chat container
    const chatContainerRef = useRef(null);

    // Scroll to the last message
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth", // Adds the smooth scrolling effect
            });
        }
    };

    // Scroll to the last message on load or when messages change
    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);

    const FlexibleDiv = ({ width, children, bgc }) => {
        return (
            <div
                style={{
                    width: width,
                    border: "0.5px solid #a1a1a1",
                    borderRadius: "10px",
                    backgroundColor: bgc,
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                }}
            >
                {children}
            </div>
        );
    };

    const handleChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <>
            <div id="userpage_container">
                <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader />
                    <div className="ProjectWorkspaceBody">
                        <div className="file_preview_frame">
                            <div class="d-flex justify-content-between mt-4">
                                <div style={{ width: "49%" }}>
                                    <FlexibleDiv width="100%" bgc="#09090b">
                                        <div className="">
                                            <p className="text16px600 ">Contract Details</p>
                                            <p className="a1a1a112px500 mt-2">
                                                Review the terms and conditions of your agreement
                                            </p>
                                            <div>
                                                <p className="text12px500 mt-4">Project Scope</p>
                                                <p className="a1a1a112px500 mt-2">
                                                    Development of web application including UI/UX design,
                                                    frontend development, and backend implementation as
                                                    per the provided specifications.
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text12px500 mt-4">Team Composition</p>
                                                <p className="a1a1a112px500 mt-2">1x UI/UX Designer</p>
                                            </div>
                                            <div>
                                                <p className="text12px500 mt-4">Timeline</p>
                                                <p className="a1a1a112px500 mt-2">
                                                    12 weeks from project start date
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text12px500 mt-4">Deliverables</p>

                                                <ul>
                                                    <li className="a1a1a112px500 mt-2">
                                                        Complete UI/UX design files
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </FlexibleDiv>
                                </div>
                                <div
                                    style={{
                                        width: "49%",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 16,
                                    }}
                                >
                                    <FlexibleDiv width="100%" bgc="#09090b">
                                        <div className="">
                                            <p className="text16px600 ">Terms & Conditions</p>
                                            <p className="text12px500 mt-2">
                                                1. Payment Terms: Payment will be processed according to
                                                the selected payment schedule. All payments are
                                                non-refundable once the work has commenced.
                                                <br />
                                                <br />
                                                2. Intellectual Property: All intellectual property
                                                rights of the delivered work will be transferred to the
                                                client upon final payment.
                                                <br />
                                                <br />
                                                3. Confidentiality: Both parties agree to maintain
                                                confidentiality of all project-related information.
                                                <br />
                                                <br />
                                                4. Change Requests: Any changes to the project scope may
                                                affect the timeline and cost, and will be handled
                                                through a formal change request process.
                                            </p>
                                        </div>
                                    </FlexibleDiv>
                                    <FlexibleDiv width="100%" bgc="#09090b">
                                        <div className="">
                                            <p className="text16px600 ">Payment Details</p>
                                            <div className="d-flex">
                                                <div style={{ width: "45%" }}>
                                                    <p className="a1a1a112px500 mt-4">Payment Schedule</p>
                                                    <p className="a1a1a112px500 mt-4">Payment Method</p>
                                                    <p className="text12px500 mt-4">Total Project Cost</p>
                                                    <p className="a1a1a112px500 mt-4">
                                                        First Payment Due
                                                    </p>
                                                </div>
                                                <div style={{ width: "45%" }}>
                                                    <p className="a1a1a112px500 mt-4">
                                                        Monthly Installments
                                                    </p>
                                                    <p className="a1a1a112px500 mt-4">Bank Transfer</p>
                                                    <p className="text12px500 mt-4">N12,000,000</p>
                                                    <p className="text12px500 mt-4">N1,000,000</p>
                                                </div>
                                            </div>
                                            <div className="d-flex items-center gap-2 mt-2">
                                                {" "}
                                                <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={handleChange}
                                                    className="w-4"
                                                />
                                                <p className="a1a1a112px500 ">
                                                    Monthly Installments
                                                </p>
                                            </div>
                                        </div>
                                    </FlexibleDiv>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProjectWorkspaceFooter />
                </div>
                <ProjectWorkspaceChat />
            </div>
        </>
    );
};

export default ContractAndPayment;
