import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import Nav from './Nav';
import Footer from "./Footer";
const apiUrl = process.env.REACT_APP_API_URL;

const LiveChat =() =>{
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null);
    


    const handleInputChange = (e) => {
        setInput(e.target.value);
    };
    
    



    return (
        <>
        <Nav />
        { /* Home header start */}
        <section className="live_chat_section mt-5 py-5">
            <div className="container">
                <div className="row">
                    <div className="col live_chat_header" align="center">
                        <h1>Live Chat</h1>
                    </div>
                    <div className="col live_chat_sub_header" align="center">
                        <h6>Chat with our AI service center, Chatbot AI which will help you reach us faster.</h6>
                    </div>
                    <div className="col live_chat_box" align="center">
                        <div>
                            <div className="live_chat_system">
                                <img src="/static/images/live_chat_icon.png" className="img-fluid"s alt="live chat icon" />
                                <p>Welcome to Chatbot, where i help bring the solutions to you! Select an option</p>
                            </div>
                            <div className="live_chat_system_btn">
                                <span>Technical Issues</span>
                                <span>Subscription Issues</span>
                                <span>None of the above</span>
                            </div>
                        </div>
                        




                        <div class="input-group">
                            <textarea class="form-control" placeholder="Type your request or ask for help"></textarea>
                            <span class="input-group-text">
                                <div><img src="/static/images/send_message_icon.png" className="img-fluid" alt='send message icon' /></div>
                            </span>
                        </div>
                    </div>
                    



                </div>
            </div>
        </section>
        { /* Home header end */}
      

       
        <Footer />
        </>
    )
}


export default LiveChat