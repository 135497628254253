import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserProvider';
import ProjectWorkspaceNav from './ProjectWorkspaceNav';
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import { SidebarContext } from "./Sidebar";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter";
import '../Styles/FilesAndResources.css';
const apiUrl = process.env.REACT_APP_API_URL;



const FilesAndResources = () => {
    const { isSidebarVisible } = useContext(SidebarContext);
    const [chatSession, setChatSession] = useState(null);
    const { user } = useContext(UserContext);
    const [input, setInput] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    // Address Bar for the In-App Browser
    const [browserUrl, setBrowserUrl] = useState("No preview links found");
    const navigate = useNavigate();

    // Ref for the chat container
    const chatContainerRef = useRef(null);

    // Scroll to the last message
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth", // Adds the smooth scrolling effect
            });
        }
    };

    // Scroll to the last message on load or when messages change
    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);




    // useEffect(() => {
    //     const fetchInitialPrompt = async () => {
    //         try {
    //             setLoading(true);
    //             setError(null);
    //             const response = await fetch(`${apiUrl}/api/chat/start/`, {
    //                 method: 'GET',
    //                 credentials: 'include',
    //                 headers: {
    //                     Authorization: `Bearer ${user.token}`,
    //                 },
    //             });

    //             if (!response.ok) {
    //                 throw new Error(`HTTP error! status: ${response.status}`);
    //             }

    //             const data = await response.json();

    //             console.log(data)
    //             // Update state with the fetched data
    //             if (data.status === "new_session") {
    //                 setChatSession(data.session_id);
    //                 setChatHistory([{ text: data.message, type: "system" }]);
    //             } else if (data.status === "existing_session") {
    //                 setChatSession(data.session_id);
    //                 setChatHistory(data.messages.map((msg) => ({
    //                     text: msg.text,
    //                     type: msg.type,
    //                 })));
    //             }
    //         } catch (err) {
    //             // Handle errors
    //             setError('Failed to load chat session. Please try again.');
    //             console.error(err);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchInitialPrompt();
    // }, [user]);

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSendMessage();
        }
    };

    const handleSendMessage = async () => {
        console.log(input)
        if (!input.trim() || !chatSession) return;

        // Append user message to chat history immediately
        setChatHistory((prevHistory) => [...prevHistory, { text: input, type: "user" }]);
        setInput(""); // Clear the input field

        try {
            setLoading(true);
            setError(null);

            const response = await fetch(`${apiUrl}/api/chat/response/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`,
                },
                body: JSON.stringify({
                    session_id: chatSession,
                    user_response: input,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.status === "complete" || data.status === "incomplete") {
                setChatHistory((prevHistory) => [
                    ...prevHistory,
                    { text: data.message, type: "system" },
                ]);

                if (data.redirect_url) {
                    navigate(data.redirect_url);
                }
            }
        } catch (err) {
            setError('Failed to send message. Please try again.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <div id="userpage_container">
                <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader />
                    <div className="ProjectWorkspaceBody">
                        {!loading ? (
                            <div className="empty_preview_frame">
                                <img src="/static/images/ghost_icon.png" className="img-fluid ghost_icon" alt="ghost icon" />
                                <p>Your work is in progress at the moment.</p>
                                <button className="btn"><img src='/static/images/add_new_file_icon.png' className='img-fluid add_file_icon' alt='new folder icon' />Add File</button>
                            </div>
                        ) : (
                            <div className="file_preview_frame">
                                <div>
                                    <input type="text" className="form-control" placeholder="Search for files, links and resources" />
                                </div>
                                <div className="file_menu_tabs">
                                    <button className="btn active">Project Files</button>
                                    <button className="btn">Links</button>
                                    <button className="btn">Media</button>
                                </div>
                                <div>
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th>Linked To</th>
                                                <th>Version</th>
                                                <th>Owner</th>
                                                <th>Access</th>
                                                <th>Last Updated</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Project Brief</td>
                                                <td>Project Overview</td>
                                                <td>V2.1</td>
                                                <td>Owner</td>
                                                <td>Confidential</td>
                                                <td>25-01-2025</td>
                                                <td><img src="/static/images/project_info_tick_icon.png" className="img-fluid table_icon" alt="tick icon" /></td>
                                                <td>...</td>
                                            </tr>
                                            <tr>
                                                <td>Dashboard</td>
                                                <td>Dashboard</td>
                                                <td>V2.1</td>
                                                <td>Owner</td>
                                                <td>Feature Based</td>
                                                <td>25-01-2025</td>
                                                <td><img src="/static/images/project_info_tick_icon.png" className="img-fluid table_icon" alt="tick icon" /></td>
                                                <td>...</td>
                                            </tr>
                                            <tr>
                                                <td>User Flow</td>
                                                <td>Entire Project</td>
                                                <td>V2.1</td>
                                                <td>Owner</td>
                                                <td>Task Based</td>
                                                <td>25-01-2025</td>
                                                <td><img src="/static/images/error_icon.png" className="img-fluid table_icon" alt="tick icon" /></td>
                                                <td>...</td>
                                            </tr>
                                            <tr>
                                                <td>User Flow</td>
                                                <td>Entire Project</td>
                                                <td>V2.1</td>
                                                <td>Owner</td>
                                                <td>Restricted</td>
                                                <td>25-01-2025</td>
                                                <td><img src="/static/images/progress_icon.png" className="img-fluid table_icon" alt="tick icon" /></td>
                                                <td>...</td>
                                            </tr>
                                            <tr>
                                                <td>User Flow</td>
                                                <td>Entire Project</td>
                                                <td>V2.1</td>
                                                <td>Owner</td>
                                                <td>Public</td>
                                                <td>25-01-2025</td>
                                                <td><img src="/static/images/progress_icon.png" className="img-fluid table_icon" alt="tick icon" /></td>
                                                <td>...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        )}





                    </div>
                    <ProjectWorkspaceFooter />
                </div>
                <ProjectWorkspaceChat />
            </div>
        </>

    )

}

export default FilesAndResources