import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormTokenContext } from './FormTokenProvider';
import { UserContext } from './UserProvider';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Styles/Login.css'
const apiUrl = process.env.REACT_APP_API_URL;


const Login =() =>{
    const {formToken} = useContext(FormTokenContext);
    const { login } = useContext(UserContext); // Set user context
    const navigate = useNavigate();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isScreenLargeEnough, setIsScreenLargeEnough] = useState(true);
    const mediumBreakpoint = 1024;
    const screenWidth = window.innerWidth;
    const[User, setUser] = useState({
        honeyPot: '',
        email: '',
        pwd : '',
    });
    const [isFormValid, setIsFormValid] = useState(false);
    
    
    useEffect(() => {
        // Check if all text fields are filled and the checkbox is checked
        const allFieldsFilled =
            User.email.trim() !== "" && User.pwd.trim() !== ""

        setIsFormValid(allFieldsFilled);
    }, [User]);


    const fieldChange =(e) =>{
        setUser({
            ...User, [e.target.name]:e.target.value
        });
    }


    useEffect(() => {
        const checkScreenSize = () => {
          setIsScreenLargeEnough(window.innerWidth >= mediumBreakpoint);
        };
    
        // Initial check
        checkScreenSize();
    
        // Add event listener to detect window resize
        window.addEventListener('resize', checkScreenSize);
    
        return () => {
          window.removeEventListener('resize', checkScreenSize);
        };
    }, []);





    const loginUser =(e) =>{
        e.preventDefault();

        if (!isScreenLargeEnough) {
            toast.error('Login is only allowed on larger screen devices.', {
                position: "bottom-right"
            });
            return;
        }

        if (User.honeyPot){
            toast.error('Bad Request.', {
                position: "bottom-right"
            });
            return;
        }

        const trimmedUser = {
            email: User.email?.trim() || '',
            pwd: User.pwd?.trim() || '',
            screen_width: screenWidth
        };

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!trimmedUser.email || !trimmedUser.pwd) {
            toast.error('All fields are required.', {
                position: "bottom-right"
            });
            return;
        }

        if (!emailPattern.test(trimmedUser.email)) {
            toast.error('Please enter a valid email address.', {
                position: "bottom-right"
            });
            return;
        }

        fetch(`${apiUrl}/api/login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': formToken, 
            },
            body: JSON.stringify(trimmedUser),
        })
        .then(async response => {
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'An error occurred');
            }
            return response.json();
        })
        .then(data => {
            toast.success(data.message, {
                position: "bottom-right"
            });

            if (data.access_token) {
                login(data.access_token); // Save token
            }

            if (data.navigate_to === "/verify-email") {
                navigate(data.navigate_to, { state: { email: trimmedUser.email } }); // Redirect user
            } else{
                navigate(data.navigate_to,); // Redirect user
            }
        })
        .catch(error => {
            toast.error(`${error}`, {
                position: "bottom-right"
            });
        });
    }

    const handleGoogleLogin = () => {
        if (!isScreenLargeEnough) {
            toast.error('Account creation is only allowed on larger screen devices.', {
                position: "bottom-right"
            });
            return;
        }
        window.location.href = `${apiUrl}/api/google-login/`;
    };
    



    

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevState) => !prevState);
    };




    return (
        <>
        {/* Sign In Section Start */}
        <section className='signin_section'>
            <div className='row'>
                <div className='col-md-7 col-lg-5'>
                    <h1 >Sign In</h1>
                    <p className='py-0'>Welcome back! Login to continue your journey!</p>
                    <form onSubmit={loginUser}>
                        <div className="field-group mt-3 mb-3">
                            <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder=""
                            name='email'
                            value={User.email}
                            onChange={fieldChange}
                            />
                            <label htmlFor="floatingInput">Email Address</label>
                            <img src='/static/images/email_icon.png' className='img-fluid email_icon' alt='email icon' />
                            <input type='hidden' name='honeyPot' value={User.honeyPot} onChange={fieldChange} />
                        </div>
                        <div className="field-group mb-2">
                            <input
                            type={isPasswordVisible ? "text" : "password"}
                            className="form-control"
                            id="floatingPassword"
                            placeholder=""
                            name='pwd'
                            value={User.pwd}
                            onChange={fieldChange}
                            />
                            <label htmlFor="floatingPassword">Password</label>
                            <i className={`toggle-password fa ${ isPasswordVisible ? "fa-eye-slash" : "fa-eye" } position-absolute`} id="togglePassword" onClick={togglePasswordVisibility}></i>
                        </div>
                        <div className='fogot_pass_box'>
                            <Link to='/forgot-password' className='forgot_password'>Forgot Password?</Link>
                        </div>
                        <div className='d-grid'>
                            <button type='submit' className={`btn btn-lg ${isFormValid ? "active-btn" : "disabled-btn"}`} disabled={!isFormValid}>Login</button>
                        </div>
                    </form>
                    <p>Don’t yet have an account? <Link className='account_link' to='/register'>Create an account</Link></p>
                    <hr/>
                    <div className='d-grid google_sign_in_button_box'>
                        <button className='btn btn-lg' onClick={handleGoogleLogin}>Continue with Google</button>
                        <img src='/static/images/google_icon.png' className='img-fluid google_icon' alt='google icon' />
                    </div>
                    <div align='center'>
                        <Link to="/"><img src='/static/images/designxcel_logo.png' className='img-fluid' alt='Designxcel Logo'/></Link>
                    </div>
                </div>
            </div>
        </section>
        {/* Sign In Section End */}
        </>
    )
}


export default Login