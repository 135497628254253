import React, {createContext, useContext, useState, useEffect, useRef} from "react";
import { UserContext } from '../UserProvider';
const apiUrl = process.env.REACT_APP_API_URL;



const AiHelper = createContext();





export const AiProvider =  ({ children }) => {
    const { user } = useContext(UserContext);
    const [insight, setInsight] = useState(null);
    const [pageMessage, setPageMessage] = useState("");
    const [aiChatHistory, setAiChatHistory] = useState([]);



    const fetchAIInsights = async (projectId, pageName) => {
        try {
            const response = await fetch(`${apiUrl}/api/insights/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`,
                },
                body: JSON.stringify({
                    project_id: projectId,
                    page_name: pageName
                }),
            });
    
            if (!response.ok) throw new Error("Failed to fetch AI insights");
    
            const data = await response.json();
            setPageMessage(data.page_message);
            setInsight(data.insight);
        } catch (error) {
            console.error("Error fetching AI Page insights:", error);
        }
    }
    return (
        <AiHelper.Provider value={{ insight, pageMessage, aiChatHistory, fetchAIInsights }}>
            {children}
        </AiHelper.Provider>
    );
}



export const useAiHelper = () => useContext(AiHelper);


