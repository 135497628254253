import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserProvider";
import ProjectWorkspaceNav from "./ProjectWorkspaceNav";
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import { SidebarContext } from "./Sidebar";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter";
import "../Styles/Hire.css";
const apiUrl = process.env.REACT_APP_API_URL;

const Hire = () => {
    const [chatSession, setChatSession] = useState(null);
    const { user } = useContext(UserContext);
    const [input, setInput] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    // Address Bar for the In-App Browser

    const navigate = useNavigate();

    // Ref for the chat container
    const chatContainerRef = useRef(null);

    // Scroll to the last message
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth", // Adds the smooth scrolling effect
            });
        }
    };

    // Scroll to the last message on load or when messages change
    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);

    // useEffect(() => {
    //     const fetchInitialPrompt = async () => {
    //         try {
    //             setLoading(true);
    //             setError(null);
    //             const response = await fetch(`${apiUrl}/api/chat/start/`, {
    //                 method: 'GET',
    //                 credentials: 'include',
    //                 headers: {
    //                     Authorization: `Bearer ${user.token}`,
    //                 },
    //             });

    //             if (!response.ok) {
    //                 throw new Error(`HTTP error! status: ${response.status}`);
    //             }

    //             const data = await response.json();

    //             console.log(data)
    //             // Update state with the fetched data
    //             if (data.status === "new_session") {
    //                 setChatSession(data.session_id);
    //                 setChatHistory([{ text: data.message, type: "system" }]);
    //             } else if (data.status === "existing_session") {
    //                 setChatSession(data.session_id);
    //                 setChatHistory(data.messages.map((msg) => ({
    //                     text: msg.text,
    //                     type: msg.type,
    //                 })));
    //             }
    //         } catch (err) {
    //             // Handle errors
    //             setError('Failed to load chat session. Please try again.');
    //             console.error(err);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchInitialPrompt();
    // }, [user]);

    
    const FlexibleDiv = ({ width, children, bgc }) => {
        return (
            <div
                style={{
                    width: width,
                    border: "0.5px solid #a1a1a1",
                    borderRadius: "10px",
                    backgroundColor: bgc,
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                }}
            >
                {children}
            </div>
        );
    };

    const ProfileIndicator = () => {
        return (
            <div className="position-relative d-inline-block">
                <div
                    className="bg-white border border-secondary rounded-circle"
                    style={{
                        width: "89px",
                        height: "89px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {/* <img
                        src="https://via.placeholder.com/50"
                        alt="User"
                        className="rounded-circle w-20 h-20"
                    /> */}
                </div>

                <div
                    className="position-absolute bg-success rounded-circle border border-white"
                    style={{
                        width: "32px",
                        height: "32px",
                        bottom: "8px",
                        right: "4px",
                    }}
                ></div>
            </div>
        );
    };

    return (
        <>
            <div id="userpage_container">
                <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader />
                    <div className="ProjectWorkspaceBody">
                        <div className="file_preview_frame">
                            <p className="a1a1a112px500 mt-2 w-50">
                                Let our expert team handle your project from start to finish
                            </p>

                            <div class="d-flex justify-content-between mt-4">
                                <FlexibleDiv width="49%" bgc="#09090b">
                                    <div className="d-flex justify-content-between">
                                        <div className="">
                                            <p className="text16px600 ">How It Works</p>
                                            <p className="text12px500 mt-4">1. Submit Request</p>
                                            <p className="a1a1a112px500 mt-2">
                                                Fill out the project details form with your requirements
                                            </p>
                                            <p className="text12px500 mt-4">2. Review Proposal</p>
                                            <p className="a1a1a112px500 mt-2">
                                                Receive a detailed team proposal and project timeline
                                            </p>

                                            <p className="text12px500 mt-4">3. Approve & Start</p>
                                            <p className="a1a1a112px500 mt-2">
                                                Review and approve the contract to begin work
                                            </p>
                                        </div>
                                    </div>
                                </FlexibleDiv>
                                <div className="" style={{ width: "48%" }}>
                                    <p className="text12px500 ">Why Choose DesignXcel?</p>
                                    <p className="a1a1a112px500 mt-2">
                                        ✨ Expert Team Management
                                        We handle all aspects of team coordination and project execution<br />
                                        <br />
                                        🚀 Rapid Deployment
                                        Get your project started quickly with our pre-vetted talent pool<br />
                                        <br />
                                        💯 Quality Guaranteed
                                        Our work comes with a satisfaction guarantee
                                    </p>

                                    <div className="d-flex gap-4 mt-4">        <button className="ru_whiteBtn">Continue to Team Selection</button><button className="ru_blackBtn">Cancel</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProjectWorkspaceFooter />
                </div>
                <ProjectWorkspaceChat />
            </div>
        </>
    );
};

export default Hire;
