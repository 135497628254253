import React, { useContext} from 'react';
import { Link,  useNavigate } from 'react-router-dom';
import NotificationModal from "../UserContext/NotificationModal";
import { useNotification } from "../UserContext/NotificationContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserDataContext } from '../UserContext/UserData';
const apiUrl = process.env.REACT_APP_API_URL;






function NotificationProfile() {
     const { openModal, isModalOpen } = useNotification();
     const {  userProfilePic, handleLogout } = useContext(UserDataContext);
     const navigate = useNavigate();

    return (
        <>
        {/* Notification Icon and User Dropdown */}
        <div className="d-flex align-items-center">
            <div className="notification_icon">
                <img onClick={openModal} src="/static/images/notification_icon.png" alt="Notification Icon" />
            </div>
            <div className="user-dropdown">
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <img src={`/static/user_profile_pictures/${userProfilePic}`} className="img-fluid dashboard_user_profile_pic" alt="user profile pic" loading="lazy" />
                        <a
                            className="nav-link"
                            id="navbarDropdown"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="fas fa-angle-down"></i>
                        </a>
                        <ul
                            className="dropdown-menu dropdown-menu-end user_dropdown_menu"
                            aria-labelledby="navbarDropdown"
                        >
                            <h5 className="dropdown-item">Profile</h5>
                            <li>
                                <Link className="dropdown-item" onClick={handleLogout}>
                                    Logout
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            {isModalOpen && <NotificationModal />} {/* Render modal conditionally */}
        </div>
    </>
    );
}

export default NotificationProfile;
