import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Styles/PasswordUpdated.css';


const PasswordUpdated=() =>{
    const navigate = useNavigate();



    return (
        <>
        {/* Password Updated Section Start */}
        <section className='password_updated_section'>
            <div className='row'>
                <div className='col-md-7 col-lg-4 text-center'>
                <div align='center' className='mb-4'>
                        <img src='/static/images/big_sent_icon.png' className='img-fluid' alt='Designxcel Logo'/>
                    </div>
                    <p className='password_updated_title'>Password Updated!</p> 
                    <div className='d-grid mt-4 mb-4'>
                            <button className='btn btn-lg' onClick={(e)=>navigate('/login')}>Login</button>
                    </div>
                   
                    <div align='center'>
                        <img src='/static/images/designxcel_logo.png' className='img-fluid logo' alt='Designxcel Logo'/>
                    </div>
                </div>
            </div>
        </section>
        {/* Password Updated Section End */}
        </>
    )
}


export default PasswordUpdated