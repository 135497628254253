import React, { useState , useContext, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { FormTokenContext } from './FormTokenProvider';
import Nav from './Nav';
import Footer from "./Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const apiUrl = process.env.REACT_APP_API_URL;

const LetsTalk =() =>{
   const {formToken} = useContext(FormTokenContext);
   const [formData, setFormData] = useState({
        honeyPot: "",
        fullname: "",
        email: "",
        messageType: "",
        projectDesc: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (formData.honeyPot){
            toast.error('Bad Request.', {
                position: "top-right"
            });
            setLoading(false);
            return;
        }
        const trimmedForm = {
            fullname: formData.fullname?.trim() || '',
            email: formData.email?.trim() || '',
            messageType: formData.messageType?.trim() || '',
            projectDesc: formData.projectDesc?.trim() || ''
        };

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!trimmedForm.fullname || !trimmedForm.email || !trimmedForm.messageType || !trimmedForm.projectDesc) {
            toast.error('All fields are required.', {
                position: "bottom-right"
            });
            setLoading(false);
            return;
        };

        if (!emailPattern.test(trimmedForm.email)) {
            toast.error('Please enter a valid email address.', {
                position: "bottom-right"
            });
            setLoading(false);
            return;
        };

        try {
            const response = await fetch(`${apiUrl}/api/lets-talk/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': formToken, 
                },
                body: JSON.stringify(trimmedForm),
            })

            // ✅ Parse JSON response first
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'An error occurred');
            }

            if (data.message === "Let's Talk Request submitted successfully") {
                toast.success(`Hi! ${formData.fullname}, One of our representatives will contact you shortly.`, {
                    position: "top-right"
                });
                setFormData({
                    honeyPot: "",
                    fullname: "",
                    email: "",
                    messageType: "",
                    projectDesc: ""
                });
                navigate('/')
            } else {
                toast.error(`${data.message}`, {
                    position: "bottom-right"
                });
            }
            
        } catch (err) {
            setError("Failed to send. Please check your internet connection.");
            toast.error(`${err.message}`, { position: "bottom-right" });
        } finally {
            setLoading(false);
        }
    };
    

    



    return (
        <>
        <Nav />
        { /* Home header start */}
        <section className="lets_talk_section mt-1 py-3">
            <div className="container">
                <div className="row">
                    <div className="landing_page_back_button">
                        <button className="btn" onClick={() => navigate(-1)}><img src="/static/images/back_icon.png" className="img-fluid" alt="back icon"  />Back</button>
                    </div>
                    <div className="col lets_talk_header" align="center">
                        <h1>Let’s Talk!</h1>
                        <Link className="btn live_chat_btn" to="/live-chat"><img src="/static/images/live_chat_icon.png" className="img-fluid" alt="live  chat icon" />Live Chat</Link>
                    </div>
                    <div className="col lets_talk_sub_header" align="center">
                        <h6>Want to send a message? Use the contact form or start a conversation with ChatBot.</h6>
                    </div>
                    <div className="col-md-5">
                        <form onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor='fullname'>Full Name</label>
                                <input type='text' className='form-control' id='fullname' name='fullname' value={formData.fullname} onChange={handleInputChange} placeholder='John Doe'/>
                                <input type='hidden' name='honeyPot' value={formData.honeyPot} onChange={handleInputChange} />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='email'>Email Address</label>
                                <input type='email' className='form-control' id='email' name='email'  value={formData.email} onChange={handleInputChange} placeholder='example@example.com' />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='message_type'>Message Type</label>
                                <select id="message_type" className='form-select' name="messageType" value={formData.messageType} onChange={handleInputChange}>
                                    <option value="">Please select</option>
                                    <option value="General Enquiry">General Enquiry</option>
                                    <option value="Project SetUp">Project SetUp</option>
                                    <option value="Request Follow Up">Request Follow Up</option>
                                    <option value="Custom Request">Custom Request</option>
                                    <option value="Schedule A Call">Schedule A Call</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='project_desc'>Project Description</label>
                                <textarea className='form-control' placeholder='Enter project description here..' id='project_desc' name='projectDesc' value={formData.projectDesc} onChange={handleInputChange} />
                            </div>
                            <div align='start'>
                                <button className='btn btn-lg submit_button' type='submit' disabled={loading}>{loading ? "Sending..." : "Send Message"}</button>
                            </div>
                        </form>

                    </div>





                    



                </div>
            </div>
        </section>
        { /* Home header end */}
      

       
        <Footer />
        </>
    )
}


export default LetsTalk