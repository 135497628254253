import React from 'react';
import '../Styles/TalentCreateProfile.css'



const TalentCreateProfile =() =>{
    

    return (
        <>
        {/* Register Section Start */}
        <section className='talent_create_profile_section'>
            <div className='row'>
                <div className='col-md-7 col-lg-4'>
                    <h1>Welcome to DesignXcel Talent Hub!</h1>
                    <p>Ready to join a network of top design & development talents? Let's get started!</p>
                    <form >
                        <div className='name_box'>
                            <div className="field-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fname"
                                    name='fname'
                                    placeholder=""
                                    autocomplete="off"
                                    />
                                <label htmlFor="fname">First Name</label>
                                <input type='hidden' name='honeyPot'/>
                            </div>
                            <div className="field-group">
                                <input
                                type="text"
                                className="form-control"
                                id="lname"
                                name='lname'
                                placeholder=""
                                autocomplete="off"
                                />
                                <label htmlFor="lname">Last Name</label>
                            </div>
                        </div>
                        <div className='mb-3'>
                            <label htmlFor='address'>Address</label>
                            <textarea className='form-control' rows='2' placeholder='' id='address' name='address'/>
                        </div>
                        <div className='mb-3'>
                            <label htmlFor=''>Location</label>
                            <div className='location_box'>
                                <div className='mb-1'>
                                    <input type='text' className='form-control' id='email' name='email' placeholder='state' />
                                </div>
                                <div>
                                    <select className='form-select' name="country">
                                        <option value="">Country</option>
                                        <option value="Nigeria">Nigeria</option>
                                        
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='mb-1'>
                            <label htmlFor='phone_number'>Phone Number</label>
                            <input type='tel' className='form-control' id='phone_number' name='phone_number'  placeholder='Phone Number' />
                        </div>
                        <div className='d-grid mt-2'>
                            <button type='submit' className='btn btn-lg'>Continue</button>
                        </div>
                    </form>
                    <div align='center'>
                        <img src='/static/images/designxcel_logo.png' className='img-fluid' alt='Designxcel Logo'/>
                    </div>
                </div>
            </div>
        </section>
        {/* Register Section End */}
        </>
    )
}


export default TalentCreateProfile