import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserProvider';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GoogleCallback = () => {
    const { login, setUser  } = useContext(UserContext);
    const navigate = useNavigate();
    const [navigated, setNavigated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Check for Google login callback token
        const url = new URL(window.location.href);
        const accessToken = url.searchParams.get("token");
        const message = url.searchParams.get("message");

        if (loading) return;
        if (navigated) return;

        if (accessToken) {
            login(accessToken);
            setUser({ token: accessToken });
            if (message === 'Account created successfully.' || message === 'Profile incomplete. Please complete your profile.') {
                toast.info('Please complete your profile to continue.', {
                    position: "bottom-right",
                });
                navigate('/create-profile');
            } else if (message === 'Account logged in successfully.') {
                toast.success('Logged in successfully via Google!', {
                    position: "bottom-right",
                });
                navigate('/dashboard');
            }
            setNavigated(true);
        } else {
            toast.error('Google login failed. Please try again.', {
                position: "bottom-right",
            });
            navigate('/login');
            setNavigated(true); // Set flag to prevent further navigation
        }
    }, [login, setUser, navigate, navigated, loading]);

    useEffect(() => {
        // Set loading to false after handling callback
        setLoading(false);
    }, []);

    return <div>Processing Google login...</div>; // Loading state
};

export default GoogleCallback;



