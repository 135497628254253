import React from 'react';
import '../Styles/TalentSkillsFormA.css'



const TalentSkillsFormA =() =>{
    

    return (
        <>
        {/* Register Section Start */}
        <section className='talent_skills_n_exp_form_a_section'>
            <div className='row'>
                <div className='col-md-7 col-lg-4'>
                    <h1>Skills & Experience</h1>
                    <form>
                        <div className='mt-4 mb-3'>
                            <label htmlFor='phone_number'>What's your primary skillset? Select all that apply.</label>
                            <select className='form-select' name="skill_set">
                                <option value="">Please select</option>
                            </select>
                        </div>
                        <div className='group_box mb-4'>
                            <div>
                                <label htmlFor=''>Primary Job interest</label>
                                <select className='form-select' name="country">
                                    <option value="">Please select</option>
                                    <option value="Nigeria">Nigeria</option>
                                    
                                </select>
                            </div>
                            <div>
                                <label htmlFor=''>Years of Experience</label>
                                <input type='text' className='form-control' id='email' name='email' placeholder='Enter a number' />
                            </div>
                        </div>
                        <div className='mb-3 project_link_title'>
                            <p>Project Links</p>
                            <small>Add links to your projects (Githib repositories, design porfolios, live websites, etc.)</small>
                        </div>

                        <div className='mb-1 project_link_box'>
                            <input type='text' className='form-control' id='email' name='email' placeholder='Project URL' />
                            <select className='form-select' name="country" >
                                <option value="">Project Type</option>
                            </select>
                            <textarea className='form-control' rows='2' placeholder='Brief description of your project' id='address' name='address' />
                        </div>

                        <div className='mt-2'>
                            <button type='submit' className='btn btn-lg'>Continue</button>
                        </div>

                        <p>Add project Files (Optional)</p>
                        <small>File Format: <strong>JPG</strong> or <strong>PNG</strong>. Recommended Size: At least 800*400px. Max File Size is up to <strong>2MB</strong></small>


                        <div className='profile_img mt-1 mb-3'>
                            
                        </div>
                        <div className='d-grid mt-2'>
                            <button type='submit' className='btn btn-lg'>Continue</button>
                        </div>
                    </form>
                    <div align='center'>
                        <img src='/static/images/designxcel_logo.png' className='img-fluid' alt='Designxcel Logo'/>
                    </div>
                </div>
            </div>
        </section>
        {/* Register Section End */}
        </>
    )
}


export default TalentSkillsFormA