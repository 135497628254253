import React, {useContext, useState, useEffect, useRef, useCallback } from "react";
import { useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from '../UserProvider';
import { useProject } from "./ProjectData";
import ProjectWorkspaceNav from './ProjectWorkspaceNav';
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter"
import '../Styles/AddNewTask.css';
const apiUrl = process.env.REACT_APP_API_URL;



const AddNewTask =() =>{
    const { activeProject } = useProject();
    const { user } = useContext(UserContext);
    const [taskName, setTaskName] = useState("");
    const [taskType, setTaskType] = useState("");
    const [taskDescription, setTaskDescription] = useState("");
    const [linkedTo, setLinkedTo] = useState("");
    const [assignees, setAssignees] = useState([]); // Store multiple assigned users
    const [attachments, setAttachments] = useState([]);
    const [showSearch, setShowSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const fileInputRef = useRef(null);
    const searchRef = useRef(null);
    const buttonRef = useRef(null); // Ref for the button




    // const [input, setInput] = useState("");
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const navigate = useNavigate();



    // Hide Search when not in use 
    const handleClickOutside = useCallback((event) => {
        if (
          searchRef.current &&
          !searchRef.current.contains(event.target) && // If not inside search box
          buttonRef.current && 
          !buttonRef.current.contains(event.target) // If not inside the button
        ) {
          setShowSearch(false);
          setSearchQuery("");
          setSearchResults([]);
        }
    }, []);
    
    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [handleClickOutside]);

    



    const fetchUsers = async (query) => {
        if (!query) return setSearchResults([]);
        try {
            const response = await fetch(`${apiUrl}/api/search-users?query=${encodeURIComponent(query)}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user.token}`,
                }
            });
            
            if (!response.ok) {
                throw new Error("Failed to fetch users");
            }
    
            const data = await response.json();  // Parse JSON response
    
            setSearchResults(data.users || []); // Ensure it's always an array
        } catch (error) {
            setSearchResults([]); // Reset on error
        }
    };

    // Handle selecting a user
    const handleSelectUser = (user, event) => {
        event.stopPropagation(); // Prevent event from bubbling to parent
        if (!assignees.some(a => a.id === user.id)) {
            setAssignees([...assignees, user]); // Add user if not already selected
        }
        setSearchQuery("");
        setSearchResults([]);
        };

    // Handle removing a user
    const handleRemoveUser = (id) => {
        setAssignees(assignees.filter(user => user.id !== id));
    };

    // Trigger file input when button is clicked
    const handleAddAttachment = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";  // Reset input field
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
    
        if (!event.target.files || event.target.files.length === 0) {
            return;
        }
    
        const newFiles = Array.from(event.target.files);
    
        const allowedTypes = ["application/pdf", "application/msword", "image/jpeg", "image/jpg", "image/png"];
    
        // Get existing file names for comparison
        const existingFileNames = new Set(attachments.map(file => file.name));
    
        // Filter valid and non-duplicate files
        const validFiles = newFiles.filter(file => 
            allowedTypes.includes(file.type) && !existingFileNames.has(file.name)
        );
    
        if (validFiles.length > 0) {
            setAttachments(prev => [...prev, ...validFiles]);  // Update state
        } else {
            console.warn("All selected files are either invalid or duplicates.");
            toast.error(`Only unique PDF, DOC, JPEG, JPG, and PNG files are allowed.`, {
                position: "bottom-right"
            });
        }
    };
    

    const removeAttachment = (index) => {
        setAttachments(prev => prev.filter((_, i) => i !== index));
    };
   

    // const handleInputChange = (e) => {
    //     setInput(e.target.value);
    // };
    


    const handleTaskSubmit = async (e) => {
        e.preventDefault();
    
        if (!taskName.trim() || !taskType.trim() || !taskDescription.trim() || assignees.length === 0) {
            toast.error('All fields are required.', {
                position: "bottom-right"
            });
            return;
        }
    
        try {
            setLoading(true);
            setError(null);
    
            const formData = new FormData();
            formData.append("name", taskName.trim());
            formData.append("type", taskType.trim());
            formData.append("description", taskDescription.trim());
            formData.append("project_id", activeProject.id); 
    
            // Append assignees as a JSON string (assuming backend expects this)
            formData.append("assignees", JSON.stringify(assignees.map(user => user.id)));
    
            // Append each attachment file
            attachments.forEach((file, index) => {
                formData.append(`attachments`, file); // `attachments[]` if the backend expects an array
            });

            for (let [key, value] of formData.entries()) {
            }
    
            const response = await fetch(`${apiUrl}/api/add-task/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
                body: formData, // Send FormData
            });
    
            if (response.status === 401) {
                console.warn("Unauthorized access, redirecting...");
                navigate("/login");
                return;
            }
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            if (data.status === "Task created successfully") {
                toast.success(`Task created successfully`, {
                    position: "bottom-right"
                });
    
                // Reset form after submission
                setTaskName("");
                setTaskType("");
                setTaskDescription("");
                setAssignees([]);
                setAttachments([]);
            }
        } catch (err) {
            setError('Failed to create task. Please try again.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    
    


    return(
        <>
        <div id="userpage_container">
            <ProjectWorkspaceNav />
                <div className="project_workspace_container"> 
                    <ProjectWorkspaceHeader pageName={`Add Task`} />
                    <div className="add_new_task">
                        <div className="row">
                            <div className="col-md-5 form_box">
                                <label htmlFor="">Task Name</label>
                                <input type="text" className="form-control" placeholder="Enter task name" value={taskName}
                                onChange={(e) => setTaskName(e.target.value)} />
                            </div>
                            <div className="col-md-5 form_box">
                                <label htmlFor="">Task Type</label>
                                <select className="form-select" id="floatingSelectGrid" value={taskType}
                                onChange={(e) => setTaskType(e.target.value)}>
                                    <option selected>Open this select menu</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5 form_box">
                                <label htmlFor="">Task Description</label>
                                <textarea className='form-control' rows='4' placeholder='Enter task description' id='task_desc' name='taskDesc' value={taskDescription}
                                onChange={(e) => setTaskDescription(e.target.value)} />
                            </div>
                            <div className="col-md-5 px-0">
                                <div className="row last_row_targ">
                                    <div className="col form_box" ref={buttonRef} onClick={() => setShowSearch(true)} style={{ cursor:"pointer"}}>
                                        <label htmlFor="">Assign to</label>
                                            <div className="assignee-container">
                                            {assignees.map((user) => (
                                            <span key={user.id} className="assignee-tag">
                                                {user.name}
                                                <button onClick={() => handleRemoveUser(user.id)} className="btn remove-btn">×</button>
                                            </span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col form_box">
                                        <label htmlFor="">Linked to</label>
                                        <select className="form-select" id="floatingSelectGrid" disabled>
                                            <option selected>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-5 attachment_and_assignee">
                                <div className="attachment_box">
                                    <button className="btn attachment_btn" onClick={handleAddAttachment}>Add an Attachment</button>
                                    <input type="file" multiple onChange={handleFileChange} ref={fileInputRef} style={{ display: "none" }} accept=".pdf,.doc,.jpeg,.jpg,.png" />
                                    <div className="attachments-preview">
                                        {attachments.map((file, index) => (
                                            <div key={index} className="attachment-item">
                                                <span>{file.name}</span>
                                                <button className="btn remove-btn" onClick={() => removeAttachment(index)}>x</button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                    {showSearch && (
                                    <>
                                        <input
                                            ref={searchRef}
                                            type="text"
                                            className="form-control search_task_user"
                                            placeholder="Enter Email"
                                            value={searchQuery}
                                            onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                            fetchUsers(e.target.value);
                                            }}
                                        />

                                        <div className="search-results">
                                            {searchResults.map((user) => (
                                            <div key={user.id} className="assignee_deets" onClick={(e) => handleSelectUser(user, e)}>
                                                <img src={`/static/user_profile_pictures/${user.img}`} className="img-fluid" alt="user profile picture" />
                                                <div>
                                                    <p>{user.name}</p>
                                                    <p>{user.role}</p>
                                                </div>
                                            </div>
                                            ))}
                                        </div>
                                    </>
                                    )}
                                    </div>
                                </div>


                            </div>
                            <div className="col-md-5 linked_to_info">

                            </div>

                        </div>









                        {/* <div className="add_new_task_body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row add_task_question_header">
                                        <div className="col-lg-5">
                                            <label htmlFor="">Task Name</label>
                                            <input type="text" className="form-control" placeholder="Enter task name" />
                                        </div>
                                        <div className="col-lg-5">
                                            <label htmlFor="">Task Type</label>
                                            <select className="form-select" id="floatingSelectGrid">
                                                <option selected>Open this select menu</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>

                                    </div>
                                    
                                </div>
                                <div className="col-lg-5">
                                    <div class="form-floating">
                                        <select class="form-select" id="floatingSelectGrid">
                                            <option selected>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                        <label for="floatingSelectGrid">Select File</label>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <label htmlFor="">Linked To</label>
                                    <select class="form-select" id="floatingSelectGrid">
                                        <option selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                <div className="col-lg-5">
                                    <div class="form-floating">
                                        <select class="form-select" id="floatingSelectGrid">
                                            <option selected>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                        <label for="floatingSelectGrid">Linked to</label>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div class="form-floating">
                                        <select class="form-select" id="floatingSelectGrid">
                                            <option selected>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                        <label for="floatingSelectGrid">Team Access</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row linked_to_info_box">
                                <h4>Linked To:</h4>
                                <div className="row linked_to_info">
                                    <div className="col">
                                        <div>
                                            <img src="/static/images/project_info_tick_icon.png" className="img-fluid project_info_tick_icon" alt="tick icon" />
                                            <h6>Project</h6>
                                        </div>
                                        <ul>
                                            <li><img src="/static/images/project_info_check_icon.png" className="img-fluid project_info_check_icon" alt="check icon" />Brief</li>
                                            <li><img src="/static/images/project_info_check_icon.png" className="img-fluid project_info_check_icon" alt="check icon" />Timeline</li>
                                        </ul>

                                    </div>
                                    <div className="col">
                                        <div>
                                            <img src="/static/images/project_info_tick_icon.png" className="img-fluid project_info_tick_icon" alt="tick icon" />
                                            <h6>Feature</h6>
                                        </div>
                                        <ul>
                                            <li><img src="/static/images/project_info_check_icon.png" className="img-fluid project_info_check_icon" alt="check icon" />Authentication</li>
                                            <li><img src="/static/images/project_info_check_icon.png" className="img-fluid project_info_check_icon" alt="check icon" />Dashboard</li>
                                            <li><img src="/static/images/project_info_check_icon.png" className="img-fluid project_info_check_icon" alt="check icon" />Profile</li>
                                            <li><img src="/static/images/project_info_check_icon.png" className="img-fluid project_info_check_icon" alt="check icon" />Approval</li>
                                            <li><img src="/static/images/project_info_check_icon.png" className="img-fluid project_info_check_icon" alt="check icon" />Access Control</li>
                                        </ul>

                                    </div>
                                    <div className="col">
                                        <div>
                                            <img src="/static/images/project_info_tick_icon.png" className="img-fluid project_info_tick_icon" alt="tick icon" />
                                            <h6>Task</h6>
                                        </div>
                                        <ul>
                                            <li><img src="/static/images/project_info_check_icon.png" className="img-fluid project_info_check_icon" alt="check icon" />Complete User flow</li>
                                            <li><img src="/static/images/project_info_check_icon.png" className="img-fluid project_info_check_icon" alt="check icon" />Review dashboard</li>
                                            <li><img src="/static/images/project_info_check_icon.png" className="img-fluid project_info_check_icon" alt="check icon" />Check form fields</li>
                                            <li><img src="/static/images/project_info_check_icon.png" className="img-fluid project_info_check_icon" alt="check icon" />Approve design system</li>
                                            <li><img src="/static/images/project_info_check_icon.png" className="img-fluid project_info_check_icon" alt="check icon" />Full Access Control</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                           <div className="row project_info_save_box">
                                <button className="btn save_button">Save</button>
                           </div>
                        </div> */}
                        <div className="row project_info_save_box">
                            <button onClick={handleTaskSubmit} className="btn save_button">Save</button>
                        </div>
                    </div>
                    
                    <ProjectWorkspaceFooter />
                </div>
            <ProjectWorkspaceChat projectId={activeProject.id} pageName="Add Task" />
        </div>
        </>

    )

}

export default AddNewTask