import React from "react";
import { Link } from 'react-router-dom';


const Footer =() =>{

    return(
        <>
        { /* Footer start */}
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <img src="/static/images/designxcel_logo.png" className="img-fluid" alt="Designxcel Logo" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 col-lg-6">
                            <h4>Our Products</h4>
                            <p>CraftDeck - <span>Build with tools, teams, platforms, AI</span></p>
                            <p>GoCreate - <span>Tools to create and refine ideas.</span></p>
                            <p>Designxcel - <span>On-demand design and development service</span></p>
                        </div>
                        <div className="col-md-7 col-lg-6 quick_link_box">
                            <div className="row">
                                <div className="col-md-6 quick_links">
                                    <ul>
                                        <li><img className="img-fluid" src="/static/images/message_icon.png" alt="message icon" /><Link to="mailto:info@designxcel.com">info@designxcel.com</Link></li>
                                        <li><img className="img-fluid" src="/static/images/telephone_icon.png" alt="telephone icon" /><Link to="tel:+2349069461804">+234-(0)9069461804</Link></li>
                                    </ul>
                                </div>
                                <div className="col-md-6 widget_a">
                                    <div>
                                        <Link to='https://web.facebook.com/getdesignxcel/' target="_blank">
                                            <img src="/static/images/facebook_icon.png" className="img-fluid" alt="facebook icon"/>
                                        </Link>
                                        <Link to='https://www.instagram.com/getdesignxcel/' target="_blank">
                                            <img src="/static/images/instagram_icon.png" className="img-fluid" alt="instagram icon"/>
                                        </Link>
                                        <Link to='https://www.linkedin.com/company/getdesignxcel/' target="_blank">
                                            <img src="/static/images/linkedin_icon.png" className="img-fluid" alt="linkedin icon"/>
                                        </Link>
                                    </div>
                                    <small>&copy; 2024 Designxcel. All Rights Reserved.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        { /* Footer end */}
        </>
    )
}


export default Footer