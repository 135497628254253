import React from "react";
import { Link , useNavigate} from "react-router-dom";
import { useProject } from "./ProjectData";
import DashboardHeader from "./DashboardHeader";
import DashboardNav from './DashboardNav';
import '../Styles/Dashboard.css';
import '../Styles/Projects.css';


const Projects =() =>{
    const { projects, handleCreateNewProject, handleProjectClick } = useProject();
    const navigate = useNavigate();



    return (
        <>
        <div id="userpage_container">
            <DashboardNav />
            <div id="dashboard_containter">
            <DashboardHeader />
                <main>
                    {/* New Project Link start */}
                    <div className="new_project_quick_link">
                        <img src="/static/images/projects_page_icon.png" className="img-fluid" alt="designxcel_logo" />
                        <div className="new_project_link">
                            <Link to='/dashboard'>
                            <img src="/static/images/less_than_icon.png" className="img-fluid" alt="back" />
                            </Link>
                            <Link onClick={(e)=>handleCreateNewProject(e)} className="btn btn-lg"><img src="/static/images/dashboard_develop_icon.png" className="img-fluid"/>New Project</Link>
                        </div>
                    </div>
                    {/* Dashboard quick links end */}
                    {/* Dashboard recently viewed start */}
                    <div className="dashboard_recently_viewed">
                        <div className="container">
                        <p>Active</p>
                        <div class="dasboard_preview_box">
                          {projects.length === 0 ? (
                                <div className="dashboard_empty_preview">
                                <img src="/static/images/ghost_icon.png" className="img-fluid ghost_icon" alt="ghost icon"/>
                                <p>No projects added yet.</p>
                                <button className="btn" onClick={(e)=>handleCreateNewProject(e)}><img src='/static/images/add_new_file_icon.png' className='img-fluid add_file_icon' alt='new folder icon'/>Start New</button>
                                </div>
                                ) : (
                                projects.reduce((rows, project, index) => {
                                if (index % 2 === 0) {
                                    rows.push([]);
                                }
                                rows[rows.length - 1].push(project);
                                return rows;
                                }, []).map((rowProjects, rowIndex) => (
                                <div className="row mb-4" key={rowIndex}>
                                <div className="row mb-4">
                                    {rowProjects.map((project) => {
                                    let statusClass = "";
                                    switch (project.status) {
                                        case "Draft":
                                        case "Pending Review":
                                        statusClass = "pending-status";
                                        break;
                                        case "In Progress":
                                        statusClass = "in_progress";
                                        break;
                                        case "Completed":
                                        statusClass = "active-status";
                                        break;
                                        default:
                                        statusClass = "";
                                    }

                                    return (
                                        <div className="col-md-5" key={project.id} onClick={() => handleProjectClick(project)}>
                                        <div className="card_top">
                                            <h5 className={statusClass}>{project.status}</h5>
                                            <span>Last Updated {project.last_updated}</span>
                                        </div>
                                        <div>
                                            <p>{project.name}</p>
                                            <div className="card_bottom">
                                            <span>{project.goal || "No Goal Set"}</span>
                                            <div>
                                                <span>No Team Added Yet</span>
                                                {/* <img
                                                className="img-fluid img_1"
                                                src="/static/images/bcs1.png"
                                                alt="Project 1"
                                                />
                                                <img
                                                className="img-fluid img_2"
                                                src="/static/images/bcs2.png"
                                                alt="Project 2"
                                                />
                                                <img
                                                className="img-fluid img_3"
                                                src="/static/images/bcs1.png"
                                                alt="Project 3"
                                                />
                                                <img
                                                className="img-fluid img_4"
                                                src="/static/images/bcs2.png"
                                                alt="Project 4"
                                                /> */}
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    );
                                    })}
                                </div>
                                </div>
                                ))  
                            )}
                        </div>



                        </div>
                    </div>
                    {/* Dashboard recently view end */}
                </main>
            </div>
        </div>
        </>
    )
}

export default Projects