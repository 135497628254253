import React, { useState, useEffect, useRef } from "react";
import ProjectWorkspaceNav from './ProjectWorkspaceNav';
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import '../Styles/Meetings.css'
import MeetingCard from "../Components/Meetings/MeetingCard";
import ScheduleModal from "../Components/Meetings/ScheduleModal/ScheduleModal";

const Meetings = () => {
  const [chatHistory, setChatHistory] = useState([]);
  // Ref for the chat container
  const chatContainerRef = useRef(null);

  // Top-level tabs
  const [activeTopTab, setActiveTopTab] = useState("website");
  // Sub-level tabs
  const [activeSubTab, setActiveSubTab] = useState("upcoming");
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  // Dummy data for demonstration
  const upcomingMeetings = [
    {
      title: "Weekly Progress Review",
      date: "Tomorrow at 2:00 PM",
      description: "Design Review",
      duration: "60 min",
      participants: 6,
      peopleNote: "Kunle & 5 others attending",
      actionLabel: "Join Meeting",
      onActionClick: () => alert("Joining meeting..."),
    },
  ];

  const pastMeetings = [
    {
      title: "Project Kickoff",
      date: "January 15, 2025",
      description: "Design Review",
      duration: "60 min",
      participants: 6,
      peopleNote: "Kunle & 5 others attended",
      actionLabel: "View Recording",
      onActionClick: () => alert("Viewing recording..."),
    },
  ];

  const meetingNotes = [
    {
      title: "Design Review Meeting",
      date: "January 19, 2025",
      description: "Design Review",
      duration: "60 min",
      participants: 6,
      peopleNote: "Kunle & 5 others attended",
      notes:
        "Key decisions made regarding the navigation layout and color scheme. Team agreed on implementing the new sidebar design in the next sprint.",
    },
  ];

  // Render the appropriate list based on activeSubTab
  const renderMeetings = () => {
    if (activeSubTab === "upcoming") {
      return upcomingMeetings.map((meeting, idx) => (
        <MeetingCard key={idx} {...meeting} />
      ));
    } else if (activeSubTab === "past") {
      return pastMeetings.map((meeting, idx) => (
        <MeetingCard key={idx} {...meeting} />
      ));
    } else {
      // Meeting notes
      return meetingNotes.map((meeting, idx) => (
        <MeetingCard key={idx} {...meeting} />
      ));
    }
  };

  // Scroll to the last message
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth", // Adds the smooth scrolling effect
      });
    }
  };

  // Scroll to the last message on load or when messages change
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  return (
    <>
      <div id="userpage_container">
        <ProjectWorkspaceNav />
        <div className="project_workspace_container">
          <header className="header">
            {/* Page Title */}
            <h1 className="page-title">Meetings & Communication</h1>

            {/* Top-level tabs */}
            <div>
              <button
                className={`top-tab-btn ${activeTopTab === "website" ? "active" : ""
                  }`}
                onClick={() => setActiveTopTab("website")}
              >
                Website.v21
              </button>
              <button
                className={`top-tab-btn bg-orange ${activeTopTab === "project" ? "active" : ""
                  }`}
                onClick={() => setActiveTopTab("project")}
              >
                Project Brief v21
              </button>
            </div>
         </header>
          <main className="main">
            {/* Sub-level tabs */}
            <div className="sub-tabs">
              <div className="btn-nav">
                <button
                  className={`sub-tab-btn ${activeSubTab === "upcoming" ? "active" : ""
                    }`}
                  onClick={() => setActiveSubTab("upcoming")}
                >
                  Upcoming
                </button>
                <button
                  className={`sub-tab-btn ${activeSubTab === "past" ? "active" : ""}`}
                  onClick={() => setActiveSubTab("past")}
                >
                  Past Meetings
                </button>
                <button
                  className={`sub-tab-btn ${activeSubTab === "notes" ? "active" : ""
                    }`}
                  onClick={() => setActiveSubTab("notes")}
                >
                  Meeting Notes
                </button>

              </div>
              <button className="top-tab-btn" onClick={() => setShowScheduleModal(true)}>
                + Schedule Meeting
              </button>

              {/* Conditionally render the Schedule Modal */}
              {showScheduleModal && (
                <ScheduleModal onClose={() => setShowScheduleModal(false)} />
              )}
            </div>

            {/* List of Meetings */}
            <div className="meetings-list">{renderMeetings()}</div>
         </main>
        </div>
        <ProjectWorkspaceChat />
      </div>
    </>

  )

}

export default Meetings