import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { FormTokenContext } from './FormTokenProvider';
import Nav from './Nav';
import Footer from "./Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const apiUrl = process.env.REACT_APP_API_URL;

const CustomQuote =() =>{
    const {formToken} = useContext(FormTokenContext);
    const [formData, setFormData] = useState({
        honeyPot: "",
        fullname: "",
        email: "",
        company: "",
        projectDesc: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (formData.honeyPot){
            toast.error('Bad Request.', {
                position: "top-right"
            });
            return;
        }
        const trimmedForm = {
            fullname: formData.fullname?.trim() || '',
            email: formData.email?.trim() || '',
            company: formData.company?.trim() || '',
            projectDesc: formData.projectDesc?.trim() || ''
        };

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!trimmedForm.fullname || !trimmedForm.email || !trimmedForm.company || !trimmedForm.projectDesc) {
            toast.error('All fields are required.', {
                position: "top-right"
            });
            return;
        };

        if (!emailPattern.test(trimmedForm.email)) {
            toast.error('Please enter a valid email address.', {
                position: "top-right"
            });
            return;
        };

        try {
            const response = await fetch(`${apiUrl}/api/custom-quote/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': formToken, 
                },
                body: JSON.stringify(trimmedForm),
            })

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'An error occurred');
            }

            const data = await response.json();
            if (data.message === 'Quote submitted successfully') {
                toast.success(`Hi! ${formData.fullname}, your quote has been submitted successfully.`, {
                    position: "top-right"
                });
                setFormData({
                    honeyPot: "",
                    fullname: "",
                    email: "",
                    company: "",
                    projectDesc: ""
                });
                document.location.href('/')
            } else {
                toast.error(`${data.message}`, {
                    position: "top-right"
                });
            }
            
        } catch (err) {
            setError("Failed to send Quote. Please check your internet connection.");
            toast.error(`${error}`, {
                position: "top-right"
            });
        } finally {
            setLoading(false);
        }
    };
    



    return (
        <>
        <Nav />
        { /* Home header start */}
        <section className="custom_quote_section mt-1 py-3">
            <div className="container">
                <div className="row">
                    <div className="landing_page_back_button">
                        <button className="btn" onClick={() => navigate(-1)}><img src="/static/images/back_icon.png" className="img-fluid" alt="back icon"  />Back</button>
                    </div>
                    <div className="col custom_quote_header" align="center">
                        <h1>Custom Quote</h1>
                    </div>
                    <div className="col custom_quote_sub_header" align="center">
                        <h6>Need something tailored just for you? Let us know, and we will create a custom solution</h6>
                    </div>
                    <div className="col-md-5">
                        <form onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor='fullname'>Full Name</label>
                                <input type='text' className='form-control' id='fullname' name='fullname' value={formData.fullname} onChange={handleInputChange} placeholder='John Doe'/>
                                <input type='hidden' name='honeyPot' value={formData.honeyPot} onChange={handleInputChange} />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='email'>Email Address</label>
                                <input type='email' className='form-control' id='email' name='email' value={formData.email} onChange={handleInputChange} placeholder='example@example.com' />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='email'>Organisation/Company</label>
                                <input type='text' className='form-control' id='company' name="company" placeholder="Organisation/Company" value={formData.company} onChange={handleInputChange} />
                            </div>
                            <div className='mb-4'>
                                <label htmlFor='project_desc'>Project Description</label>
                                <textarea className='form-control' rows='4' placeholder='Enter project description here' id='project_desc' name='projectDesc' value={formData.projectDesc} onChange={handleInputChange} />
                            </div>
                            <div align='start'>
                                <button className='btn btn-lg submit_button' type='submit' disabled={loading}>{loading ? "Sending..." : "Send Message"}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        { /* Home header end */}
      

       
        <Footer />
        </>
    )
}


export default CustomQuote