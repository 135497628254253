import React, { useContext, useState, useEffect, useRef } from "react";
import ProjectWorkspaceNav from "./ProjectWorkspaceNav";
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter";
import "../Styles/Hire.css";

const Tasks = () => {

    const [chatHistory, setChatHistory] = useState([]);


    // Address Bar for the In-App Browser



    // Ref for the chat container
    const chatContainerRef = useRef(null);

    // Scroll to the last message
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth", // Adds the smooth scrolling effect
            });
        }
    };


    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);




    const FlexibleDiv = ({ width, children, bgc }) => {
        return (
            <div
                style={{
                    width: width,
                    border: "0.5px solid #a1a1a1",
                    borderRadius: "10px",
                    backgroundColor: bgc,
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    flexShrink: 0,
                }}
            >
                {children}
            </div>
        );
    };


    return (
        <>
            <div id="userpage_container" style={{ width: "100%" }}>
                <ProjectWorkspaceNav />
                <div className="project_workspace_container" style={{
                    overflowX: "scroll",
                    whiteSpace: "nowrap",

                }}>
                    <ProjectWorkspaceHeader />
                    <div className="ProjectWorkspaceBody" >
                        <div className="file_preview_frame" >
                            <p className="text16px600 mt-2 w-50">📌 Project Tasks</p>

                            <div class="  mt-4" style={{
                                gap: "16px",
                                overflowX: "scroll",

                                display: "flex",
                            }}>
                                <FlexibleDiv width="220px" bgc="#1e1e1e">
                                    <div className="">
                                        <p className="text16px600 ">🔄 Backlog</p>
                                        <p className="text12px500 mt-2">Ideas & Upcoming Tasks</p>
                                        <p className="a1a1a112px500 mt-2">No tasks</p>
                                        <div>
                                            <input className="ru_input" placeholder="Add new task" />
                                        </div>
                                    </div>
                                </FlexibleDiv>
                                <FlexibleDiv width="220px" bgc="#1e1e1e" >
                                    <div className="">
                                        <p className="text16px600 ">✅ To Do</p>
                                        <p className="text12px500 mt-2">Approved & Ready to Start</p>
                                        <p className="a1a1a112px500 mt-2">No tasks</p>

                                    </div>
                                </FlexibleDiv>
                                <FlexibleDiv width="220px" bgc="#1e1e1e" >
                                    <div className="">
                                        <p className="text16px600 ">🚧 In Progress</p>
                                        <p className="text12px500 mt-2">Currently Being Worked On</p>
                                        <p className="a1a1a112px500 mt-2">No tasks</p>

                                    </div>
                                </FlexibleDiv>
                                <FlexibleDiv width="220px" bgc="#1e1e1e" >
                                    <div className="">
                                        <p className="text16px600 ">📝 Ready for Review</p>
                                        <p className="text12px500 mt-2">Awaiting Feedback/Approval</p>
                                        <p className="a1a1a112px500 mt-2">No tasks</p>

                                    </div>
                                </FlexibleDiv>
                                <FlexibleDiv width="220px" bgc="#1e1e1e" >
                                    <div className="">
                                        <p className="text16px600 ">👍 Approved</p>
                                        <p className="text12px500 mt-2">Reviewed & Accepted</p>
                                        <p className="a1a1a112px500 mt-2">No tasks</p>

                                    </div>
                                </FlexibleDiv>
                                <FlexibleDiv width="220px" bgc="#1e1e1e" >
                                    <div className="">
                                        <p className="text16px600 ">🏁 Completed</p>
                                        <p className="text12px500 mt-2">Finalized & Delivered</p>
                                        <p className="a1a1a112px500 mt-2">No tasks</p>

                                    </div>
                                </FlexibleDiv>
                                <FlexibleDiv width="220px" bgc="#1e1e1e" >
                                    <div className="">
                                        <p className="text16px600 ">⛔ Blocked</p>
                                        <p className="text12px500 mt-2">Requires Attention</p>
                                        <p className="a1a1a112px500 mt-2">No tasks</p>

                                    </div>
                                </FlexibleDiv>

                            </div>
                        </div>
                    </div>
                    <ProjectWorkspaceFooter />
                </div>
                <ProjectWorkspaceChat />
            </div >
        </>
    );
};

export default Tasks;
