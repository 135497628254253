import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormTokenContext } from './FormTokenProvider';
import { UserContext } from './UserProvider';
import './Styles/TalentRegister.css'
const apiUrl = process.env.REACT_APP_API_URL;



const TalentRegister =() =>{
    const {formToken} = useContext(FormTokenContext);
    const { login } = useContext(UserContext); // Set user context
    const navigate = useNavigate();
    const [isPasswordaVisible, setIsPasswordaVisible] = useState(false);
    const [isPasswordbVisible, setIsPasswordbVisible] = useState(false);
    const [isScreenLargeEnough, setIsScreenLargeEnough] = useState(true);
    const [strength, setStrength] = useState(0);
    const [isMatch, setIsMatch] = useState(null);
    const mediumBreakpoint = 1024;
    const screenWidth = window.innerWidth;
    const[newUser, setNewUser] = useState({
        honeyPot: '',
        fname: '',
        lname: '',
        serviceType: '',
        email: '',
        pwd : '',
        confirmPwd : '',
        tos : ''
    });


    const checkStrength = (password) => {
        const hasUpper = /[A-Z]/.test(password);
        const hasLower = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSymbol = /[!@#$%^&*(),.?\":{}|<>]/.test(password);
        const strengthScore = hasUpper + hasLower + hasNumber + hasSymbol;
        setStrength(strengthScore);
    };

    useEffect(() => {
        if (newUser.pwd) {
          checkStrength(newUser.pwd);
        }
    }, [newUser.pwd]);

    useEffect(() => {
        if (newUser.confirmPwd.length > 0) {
            setIsMatch(newUser.pwd === newUser.confirmPwd);
        } else {
            setIsMatch(null);
        }
    }, [newUser.pwd, newUser.confirmPwd]);
    


    const fieldChange =(e) =>{
        setNewUser({
            ...newUser, [e.target.name]:e.target.value
        });
    }

    useEffect(() => {
        const checkScreenSize = () => {
            setIsScreenLargeEnough(window.innerWidth >= mediumBreakpoint);
        };
    
        // Initial check
        checkScreenSize();
    
        // Add event listener to detect window resize
        window.addEventListener('resize', checkScreenSize);
    
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);


    const createNewUser =(e) =>{
        e.preventDefault();
        if (!isScreenLargeEnough) {
            toast.error('Account creation is only allowed on larger screen devices.', {
                position: "top-right"
            });
            return;
        }

        if (newUser.honeyPot){
            toast.error('Bad Request.', {
                position: "top-right"
            });
            return;
        }

        const trimmedNewUser = {
            fname: newUser.fname?.trim() || '',
            lname: newUser.lname?.trim() || '',
            email: newUser.email?.trim() || '',
            apply_as: newUser.serviceType?.trim() || '',
            pwd: newUser.pwd?.trim() || '',
            confirmPwd: newUser.confirmPwd?.trim() || '',
            tos: newUser.tos,
            screen_width: screenWidth,
        };

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

         if (!trimmedNewUser.fname || !trimmedNewUser.lname || !trimmedNewUser.email || !trimmedNewUser.pwd || !trimmedNewUser.confirmPwd || !trimmedNewUser.tos || !trimmedNewUser.apply_as ) {
            toast.error('All fields are required.', {
                position: "top-right"
            });
            return;
        }

        if (trimmedNewUser.pwd !== trimmedNewUser.confirmPwd){
            toast.error('Passwords do not match.', {
                position: "top-right"
            });
            return;
        }


        if (!trimmedNewUser.tos) {
            toast.error('You must agree to the terms of service.', {
                position: "top-right"
            });
            return;
        }

        if (!emailPattern.test(trimmedNewUser.email)) {
            toast.error('Please enter a valid email address.', {
                position: "top-right"
            });
            return;
        }
        fetch(`${apiUrl}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': formToken, 
            },
            body: JSON.stringify(trimmedNewUser),
        })
        .then(async response => {
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'An error occurred');
            }
            return response.json();
        })
        .then(data => {
            const token = data.access_token;
            login(token);
            toast.info('Please complete your profile to continue.', {
                position: "top-right",
            });
            navigate('/create-profile'); 
        })
        .catch(error => {
            toast.error(`${error}`, {
                position: "top-right"
            });
        });
    }

    const handleGoogleLogin = () => {
        if (!isScreenLargeEnough) {
            toast.error('Account creation is only allowed on larger screen devices.', {
                position: "top-right"
            });
            return;
        }
        window.location.href = `${apiUrl}/api/google-login/`;
    };

    const togglePasswordaVisibility = () => {
        setIsPasswordaVisible((prevState) => !prevState);
    };

    const togglePasswordbVisibility = () => {
        setIsPasswordbVisible((prevState) => !prevState);
    };

    return (
        <>
        {/* Register Section Start */}
        <section className='talent_register_section'>
            <div className='row'>
                <div className='col-md-7 col-lg-4'>
                    <h1>Create your account</h1>
                    <p>Kindly fill in your information to register on Designxcel</p>
                    <form onSubmit={createNewUser}>
                        <div className='name_box'>
                            <div className="field-group mb-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fname"
                                    name='fname'
                                    value={newUser.fname}
                                    onChange={fieldChange}
                                    placeholder=""
                                    autocomplete="off"
                                    />
                                <label htmlFor="fname">First Name</label>
                                <input type='hidden' name='honeyPot' value={newUser.honeyPot} onChange={fieldChange} />
                            </div>
                            <div className="field-group mb-2">
                                <input
                                type="text"
                                className="form-control"
                                id="lname"
                                name='lname'
                                value={newUser.lname}
                                onChange={fieldChange}
                                placeholder=""
                                autocomplete="off"
                                />
                                <label htmlFor="lname">Last Name</label>
                            </div>
                        </div>
                        <div className='field-group mb-2'>
                            <select className='form-select' id="serviceType" name="serviceType" value={newUser.serviceType} onChange={fieldChange}>
                                <option value="">i'm applying as...</option>
                            </select>
                            <label htmlFor='service_type'>Sercvice Type</label>
                        </div>
                        <div className="field-group mb-2">
                            <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder=""
                            name='email'
                            value={newUser.email}
                            onChange={fieldChange}
                            autocomplete="off"
                            />
                            <label htmlFor="floatingInput">Email Address</label>
                            <img src='/static/images/email_icon.png' className='img-fluid email_icon' alt='email icon' />
                        </div>
                        <div className="field-group mb-0">
                            <input
                            type={isPasswordaVisible ? "text" : "password"}
                            className="form-control"
                            id="floatingPassword"
                            placeholder=""
                            name='pwd'
                            value={newUser.pwd}
                            onChange={fieldChange}
                            autocomplete="off"
                            />
                            <label htmlFor="floatingPassword">Password</label>
                            <i className={`toggle-password fa ${ isPasswordaVisible ? "fa-eye-slash" : "fa-eye" } position-absolute`} id="togglePassword" onClick={togglePasswordaVisibility}></i>
                        </div>
                        {newUser.pwd && (
                        <div className="d-flex gap-2 password_strength_box">
                            <div className="d-flex gap-2 password_strength_indicator">
                                <span>Password Strength:</span>
                                <div
                                    className={`rounded bg-${strength > 0 ? (strength >= 2 ? (strength === 4 ? "success" : "warning") : "danger") : "secondary"}`}
                                    style={{ height: "3px", width: "40px" }}
                                ></div>
                                <div
                                    className={`rounded bg-${strength >= 2 ? (strength === 4 ? "success" : "warning") : "secondary"}`}
                                    style={{ height: "3px", width: "40px" }}
                                ></div>
                                <div
                                    className={`rounded bg-${strength === 4 ? "success" : "secondary"}`}
                                    style={{ height: "3px", width: "40px" }}
                                ></div>
                            </div>
                            <p className={`font-semibold ${strength === 4 ? "text-green-500" : strength >= 2 ? "text-orange-500" : "text-red-500"}`}>
                            {strength === 4 ? "Strong" : strength >= 2 ? "Medium" : "Weak"}</p>
                        </div>)
                        }
                        <div className="field-group mb-2">
                            <input
                            type={isPasswordbVisible ? "text" : "password"}
                            className="form-control"
                            id="floatingPassword1"
                            placeholder=""
                            name='confirmPwd'
                            value={newUser.confirmPwd}
                            onChange={fieldChange}
                            />
                            <label htmlFor="floatingPassword1">Confirm Password</label>
                            <i className={`toggle-password fa ${ isPasswordbVisible ? "fa-eye-slash" : "fa-eye" } position-absolute`} id="togglePassword" onClick={togglePasswordbVisibility}></i>
                        </div>
                        {isMatch !== null && (
                            <small className='match_info'>
                                {isMatch ? "Passwords matches" : "Passwords do not match"}
                            </small>
                        )}

                        <div className="form-check mb-2">
                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" name='tos'
                                value={newUser.tos}
                                onChange={(e) => setNewUser({ ...newUser, tos: e.target.checked })} />
                            <label className="form-check-label" for="flexCheckDefault">
                                I agree to the terms of service of Designxcel
                            </label>
                        </div>
                        <div className='d-grid mt-2'>
                            <button type='submit' className='btn btn-lg'>Sign Up</button>
                        </div>
                    </form>
                    <p>Already have an account? <Link className='account_link' to='/login'>Login</Link></p>
                    <hr/>
                    <div className='d-grid talent_register_button_box'>
                        <button className='btn btn-lg' onClick={handleGoogleLogin}>Continue with Google</button>
                        <img src='/static/images/google_icon.png' className='img-fluid google_icon' alt='google icon' />
                    </div>
                    <div align='center'>
                         <Link to="/"><img src='/static/images/designxcel_logo.png' className='img-fluid' alt='Designxcel Logo'/></Link>
                    </div>
                </div>
            </div>
        </section>
        {/* Register Section End */}
        </>
    )
}


export default TalentRegister