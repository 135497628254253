import React, {useContext, useState, useEffect, useRef} from "react";
import { useNavigate} from 'react-router-dom';
import { UserContext } from '../UserProvider';
import ProjectWorkspaceNav from './ProjectWorkspaceNav';
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import { SidebarContext } from "./Sidebar";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter"
import '../Styles/ProjectBriefAndScope.css';
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
const apiUrl = process.env.REACT_APP_API_URL;



const ProjectBriefAndScope =() =>{
    const { isSidebarVisible } = useContext(SidebarContext);
    const { user } = useContext(UserContext);
    

    // Scroll to the last message



    // useEffect(() => {
    //     const fetchInitialPrompt = async () => {
    //         try {
    //             setLoading(true);
    //             setError(null);
    //             const response = await fetch(`${apiUrl}/api/chat/start/`, {
    //                 method: 'GET',
    //                 credentials: 'include',
    //                 headers: {
    //                     Authorization: `Bearer ${user.token}`,
    //                 },
    //             });

    //             if (!response.ok) {
    //                 throw new Error(`HTTP error! status: ${response.status}`);
    //             }
       
    //             const data = await response.json();

    //             console.log(data)
    //             // Update state with the fetched data
    //             if (data.status === "new_session") {
    //                 setChatSession(data.session_id);
    //                 setChatHistory([{ text: data.message, type: "system" }]);
    //             } else if (data.status === "existing_session") {
    //                 setChatSession(data.session_id);
    //                 setChatHistory(data.messages.map((msg) => ({
    //                     text: msg.text,
    //                     type: msg.type,
    //                 })));
    //             }
    //         } catch (err) {
    //             // Handle errors
    //             setError('Failed to load chat session. Please try again.');
    //             console.error(err);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchInitialPrompt();
    // }, [user]);

    

    

    


    return(
        <>
        <div id="userpage_container">
            <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader />
                    <div className="add_new_task_body">
                        <div className="row">
                            <div className="col-lg-5">
                                <p>Document Preview</p>
                                <div>
                                    <img src="/static/images/pdf_file_icon.png" className="img-fluid" alt="empty file icon" />
                                </div>
                                <h4>Version History</h4>
                                <div>
                                    <img src="/static/version_history_icon.png" className="img-fluid version_history_img" alt="version history icon"/>
                                    <div>
                                        <div>
                                            <span>v2.1</span>
                                            <span>by John Doe</span>
                                            <span>2025-01-25</span>
                                        </div>
                                        <small>Updated timeline and deliverables</small>

                                    </div>

                                </div>
                            </div>





                            <div className="col-lg-5">
                                <div>
                                    <p>Details</p>
                                    <button className="Approve">Approve</button>
                                </div>

                               <div className="row">
                                    <div>
                                        <h6>Status</h6>
                                        <h4>Approved</h4>
                                    </div>
                                    <div>
                                        <h6>Owner</h6>
                                        <h4>John Joe</h4>
                                    </div>
                               </div>
                               <div className="row">
                                    <div>
                                        <h6>Version</h6>
                                        <h4>v2.1</h4>
                                    </div>
                                    <div>
                                        <h6>Last Updated</h6>
                                        <h4>2023-01-25</h4>
                                    </div>
                               </div>
                               <p>Linked Items</p>
                               <div>
                                    <p><img src="/static/images/clip_icon.png" className="img-fluid" alt="clip icon"/>Scope Review Meeting </p>
                                    <button className="btn">Task</button>
                               </div>

                            </div>
                        </div>
                    </div>
                </div>
            <ProjectWorkspaceChat />
        </div>
        </>

    )

}

export default ProjectBriefAndScope