import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormTokenContext } from './FormTokenProvider';
import './Styles/Register.css'
const apiUrl = process.env.REACT_APP_API_URL;


const Register =() =>{
    const {formToken} = useContext(FormTokenContext);
    const navigate = useNavigate();
    const [isPasswordaVisible, setIsPasswordaVisible] = useState(false);
    const [isPasswordbVisible, setIsPasswordbVisible] = useState(false);
    const [isScreenLargeEnough, setIsScreenLargeEnough] = useState(true);
    const mediumBreakpoint = 1024;
    const screenWidth = window.innerWidth;
    const[newUser, setNewUser] = useState({
        honeyPot: '',
        fname: '',
        lname: '',
        email: '',
        pwd : '',
        confirmPwd : '',
        tos : ''
    });
    const [isFormValid, setIsFormValid] = useState(false);


    useEffect(() => {
        // Check if all text fields are filled and the checkbox is checked
        const allFieldsFilled =
            newUser.fname.trim() !== "" &&
            newUser.lname.trim() !== "" &&
            newUser.email.trim() !== "" &&
            newUser.pwd.trim() !== "" &&
            newUser.confirmPwd.trim() !== "" &&
            newUser.tos; // Checkbox must be checked

        setIsFormValid(allFieldsFilled);
    }, [newUser]);


    const fieldChange =(e) =>{
        setNewUser({
            ...newUser, [e.target.name]:e.target.value
        });
    }

    useEffect(() => {
        const checkScreenSize = () => {
            setIsScreenLargeEnough(window.innerWidth >= mediumBreakpoint);
        };
    
        // Initial check
        checkScreenSize();
    
        // Add event listener to detect window resize
        window.addEventListener('resize', checkScreenSize);
    
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);


    const createNewUser =(e) =>{
        e.preventDefault();
        if (!isScreenLargeEnough) {
            toast.error('Account creation is only allowed on larger screen devices.', {
                position: "bottom-right"
            });
            return;
        }

        if (newUser.honeyPot){
            toast.error('Bad Request.', {
                position: "bottom-right"
            });
            return;
        }

        const trimmedNewUser = {
            fname: newUser.fname?.trim() || '',
            lname: newUser.lname?.trim() || '',
            email: newUser.email?.trim() || '',
            pwd: newUser.pwd?.trim() || '',
            confirmPwd: newUser.confirmPwd?.trim() || '',
            tos: newUser.tos,
            screen_width: screenWidth,
        };

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!trimmedNewUser.fname || !trimmedNewUser.lname || !trimmedNewUser.email || !trimmedNewUser.pwd || !trimmedNewUser.confirmPwd || !trimmedNewUser.tos) {
            toast.error('All fields are required.', {
                position: "bottom-right"
            });
            return;
        }

        if (trimmedNewUser.pwd !== trimmedNewUser.confirmPwd){
            toast.error('Passwords do not match.', {
                position: "bottom-right"
            });
            return;
        }

        if (!trimmedNewUser.tos) {
            toast.error('You must agree to the terms of service.', {
                position: "bottom-right"
            });
            return;
        }

        if (!emailPattern.test(trimmedNewUser.email)) {
            toast.error('Please enter a valid email address.', {
                position: "bottom-right"
            });
            return;
        }
        fetch(`${apiUrl}/api/create-account/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': formToken, 
            },
            body: JSON.stringify(trimmedNewUser),
        })
        .then(async response => {
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'An error occurred');
            }
            return response.json();
        })
        .then(data => {
            toast.success(`${data.message}`, {
                position: "bottom-right"
            });
            navigate("/verify-email", { state: { email: trimmedNewUser.email } });

        })
        .catch(error => {
            toast.error(`${error}`, {
                position: "bottom-right"
            });
        });
    }

    
    const handleGoogleLogin = () => {
        if (!isScreenLargeEnough) {
            toast.error('Account creation is only allowed on larger screen devices.', {
                position: "bottom-right"
            });
            return;
        }
        window.location.href = `${apiUrl}/api/google-login/`;
    };

    const togglePasswordaVisibility = () => {
        setIsPasswordaVisible((prevState) => !prevState);
    };

    const togglePasswordbVisibility = () => {
        setIsPasswordbVisible((prevState) => !prevState);
    };

    return (
        <>
        {/* Register Section Start */}
        <section className='register_section'>
            <div className='row'>
                <div className='col-md-7 col-lg-4'>
                    <h1>Create your account</h1>
                    <p>Kindly fill in your information to register on DesignXcel</p>
                    <form onSubmit={createNewUser}>
                        <div className='name_box mb-2'>
                            <div className="field-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fname"
                                    name='fname'
                                    value={newUser.fname}
                                    onChange={fieldChange}
                                    placeholder=""
                                    autocomplete="off"
                                    />
                                <label htmlFor="fname">First Name</label>
                                <input type='hidden' name='honeyPot' value={newUser.honeyPot} onChange={fieldChange} />
                            </div>
                            <div className="field-group">
                                <input
                                type="text"
                                className="form-control"
                                id="lname"
                                name='lname'
                                value={newUser.lname}
                                onChange={fieldChange}
                                placeholder=""
                                autocomplete="off"
                                />
                                <label htmlFor="lname">Last Name</label>
                            </div>
                        </div>
                        <div className="field-group mb-2">
                            <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder=""
                            name='email'
                            value={newUser.email}
                            onChange={fieldChange}
                            autocomplete="off"
                            />
                            <label htmlFor="floatingInput">Email Address</label>
                            <img src='/static/images/email_icon.png' className='img-fluid email_icon' alt='email icon' />
                        </div>
                        <div className="field-group mb-2">
                            <input
                            type={isPasswordaVisible ? "text" : "password"}
                            className="form-control"
                            id="floatingPassword"
                            placeholder=""
                            name='pwd'
                            value={newUser.pwd}
                            onChange={fieldChange}
                            autocomplete="off"
                            />
                            <label htmlFor="floatingPassword">Password</label>
                            <i className={`toggle-password fa ${ isPasswordaVisible ? "fa-eye-slash" : "fa-eye" } position-absolute`} id="togglePassword" onClick={togglePasswordaVisibility}></i>
                        </div>
                        <div className="field-group mb-2">
                            <input
                            type={isPasswordbVisible ? "text" : "password"}
                            className="form-control"
                            id="floatingPassword1"
                            placeholder=""
                            name='confirmPwd'
                            value={newUser.confirmPwd}
                            onChange={fieldChange}
                            autocomplete="off"
                            />
                            <label htmlFor="floatingPassword1">Confirm Password</label>
                            <i className={`toggle-password fa ${ isPasswordbVisible ? "fa-eye-slash" : "fa-eye" } position-absolute`} id="togglePassword" onClick={togglePasswordbVisibility}></i>
                        </div>
                        <div className='form_check_box'>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" name='tos'
                                    value={newUser.tos}
                                    onChange={(e) => setNewUser({ ...newUser, tos: e.target.checked })} />
                                <label className="form-check-label" for="flexCheckDefault">
                                    I agree to the terms of service of Designxcel
                                </label>
                            </div>
                        </div>
                        <div className='d-grid mt-2'>
                            <button type='submit' className={`btn btn-lg ${isFormValid ? "active-btn" : "disabled-btn"}`} disabled={!isFormValid}>Register</button>
                        </div>
                    </form>
                    <p>Already have an account? <Link className='account_link' to='/login'>Login</Link></p>
                    <hr/>
                    <div className='d-grid google_sign_in_button_box'>
                        <button className='btn btn-lg' onClick={handleGoogleLogin}>Continue with Google</button>
                        <img src='/static/images/google_icon.png' className='img-fluid google_icon' alt='google icon' />
                    </div>
                    <div align='center'>
                         <Link to="/"><img src='/static/images/designxcel_logo.png' className='img-fluid' alt='Designxcel Logo'/></Link>
                    </div>
                </div>
            </div>
        </section>
        {/* Register Section End */}
        </>
    )
}


export default Register