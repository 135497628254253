import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../UserProvider';
import { useProject } from "./ProjectData";
import ProjectWorkspaceNav from './ProjectWorkspaceNav';
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import { SidebarContext } from "./Sidebar";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter";
import '../Styles/ProjectTeam.css'
const apiUrl = process.env.REACT_APP_API_URL;



const ProjectTeam = () => {
    const { activeProject } = useProject();
    const [chatSession, setChatSession] = useState(null);
    const { user } = useContext(UserContext);
    const [input, setInput] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [data, setData] = useState(null);

    const navigate = useNavigate();

   
    const FlexibleDiv = ({ width, children, bgc }) => {
        return (
            <div
                style={{
                    width: width,
                    border: '0.5px solid #a1a1a1',
                    borderRadius: '10px',
                    backgroundColor: bgc,
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px"
                }}
            >
                {children}
            </div>
        );
    };

    const FlexibleDivTwo = ({ width, children }) => {
        return (
            <div style={{ width: width }}>
                {children}
            </div>
        );
    };

    const ProfileIndicator = () => {
        return (
            <div className="position-relative d-inline-block">

                <div
                    className="bg-white border border-secondary rounded-circle"
                    style={{
                        width: "32px",
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {/* <img
                        src="https://via.placeholder.com/50"
                        alt="User"
                        className="rounded-circle w-20 h-20"
                    /> */}
                </div>


                <div
                    className="position-absolute bg-success rounded-circle border border-white"
                    style={{
                        width: "12px",
                        height: "12px",
                        bottom: "8px",
                        right: "4px",
                    }}
                ></div>
            </div>
        );
    };



    return (
        <>
            <div id="userpage_container">
                <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader pageName={`Team`} />
                    <div className="ProjectWorkspaceBody">

                        <div className="file_preview_frame project_team" style={{ overflowY: "scroll" }}>
                            <p className="text15px">Project Team Overview</p>
                            <p className="text12px mt-4">Assign and manage your team members<br /> according to your project needs
                            </p>
                            <div class="d-flex gap-3 mt-4">
                                <FlexibleDiv width="35%" bgc='#09090b'>
                                    <p className="text24px">0</p>
                                    <p className="text12pxBold mt-4">Total Members</p>
                                </FlexibleDiv>
                                <FlexibleDiv width="25%" bgc='#09090b'>
                                    <p className="text12pxBold">Project Lead</p>
                                    <p className="text12pxBold mt-2">None added yet</p>
                                </FlexibleDiv>
                                <FlexibleDiv width="40%" bgc='#09090b'>      <p className="text12pxBold">Current Focus</p>
                                    <p className="text12pxBold mt-2">None</p></FlexibleDiv>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-center mt-5">


                                <p className="text12pxBold">Team Members & Roles</p>

                                <Link to={`/project/workspace/${activeProject.id}/${activeProject.name}/add-team-member`} className="btn AddMemberBtn">Add Team Member</Link>
                            </div>
                            {(!data || data.length === 0) ? (

                                <div class="d-flex flex-row justify-content-between   mt-4">
                                    <FlexibleDiv width="48%" bgc='#09090b'>
                                        <p className="text16px600">💼 Hire DesignXcel</p>
                                        <p className="a1a1a111px500 mt-2">Let DesignXcel handle everything. Our expert team takes over project management.</p>
                                        <button className="whiteBtn mt-2">Start Hiring Process</button>
                                    </FlexibleDiv>
                                    <FlexibleDiv width="48%" bgc='#30363D'>
                                        <p className="text16px600">🌍 Talent & Agency Marketplace</p>
                                        <p className="a1a1a111px500 mt-2">Browse and hire top-rated talents or agencies directly.</p>
                                        <button className="whiteBtn mt-2">Access Marketplace</button>
                                    </FlexibleDiv>

                                </div>


                            ) : (
                                <div className="container mt-5">

                                    <div
                                        style={{
                                            display: 'flex',
                                            backgroundColor: '#30363D',
                                            color: 'white',

                                            borderRadius: "10px",
                                            paddingTop: '10px',
                                            paddingBottom: '10px',
                                            paddingLeft: '20px',
                                            paddingRight: '20px',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <FlexibleDivTwo width="20%">
                                            <p>Member</p>
                                        </FlexibleDivTwo>
                                        <FlexibleDivTwo width="20%">
                                            <p>Current Focus</p>
                                        </FlexibleDivTwo>
                                        <FlexibleDivTwo width="13.33%">
                                            <p>Source</p>
                                        </FlexibleDivTwo>
                                        <FlexibleDivTwo width="13.33%">
                                            <p>Status</p>
                                        </FlexibleDivTwo>
                                        <FlexibleDivTwo width="13.33%">
                                            <p>Tasks</p>
                                        </FlexibleDivTwo>
                                        <FlexibleDivTwo width="20%">
                                            <p>Last Active</p>
                                        </FlexibleDivTwo>



                                    </div>


                                    <div
                                        style={{
                                            display: 'flex',
                                            padding: '10px',
                                            justifyContent: 'space-between',
                                        }}
                                    >

                                        <FlexibleDivTwo width="20%">
                                            <div className="d-flex gap-4">
                                                <ProfileIndicator />
                                                <div>        <p className="text14px600">Sarah Wilson</p> <p className="a1a1a111px500">UI/UX Designer</p></div>

                                            </div>

                                        </FlexibleDivTwo>
                                        <FlexibleDivTwo width="20%">
                                            <p className="text12px">Overseeing Sprint 2</p>
                                        </FlexibleDivTwo>
                                        <FlexibleDivTwo width="13.33%">
                                            <p className="text12px">DesignXcel</p>
                                        </FlexibleDivTwo>
                                        <FlexibleDivTwo width="13.33%">
                                            <p className="text22C55Epx400">Active</p>
                                        </FlexibleDivTwo>
                                        <FlexibleDivTwo width="13.33%">
                                            <p className="a1a1a111px500">1/4 done</p>
                                        </FlexibleDivTwo>

                                        <FlexibleDivTwo width="20%">
                                            <div className="d-flex justify-content-between">
                                                <p className="a1a1a111px500">Just Now</p>

                                                <FlexibleDiv width="50%" bgc='#09090b'> <p className="text10px700 text-center">View Profile</p></FlexibleDiv>
                                            </div>
                                        </FlexibleDivTwo>


                                    </div>


                                </div>
                            )}
                        </div>

                    </div>
                    <ProjectWorkspaceFooter />
                </div>
                <ProjectWorkspaceChat projectId={activeProject.id} pageName="Project Team" />
            </div>
        </>

    )

}

export default ProjectTeam