// Context for Project Data (Global State)
import { createContext, useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../UserProvider";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const apiUrl = process.env.REACT_APP_API_URL;

// Create Context
const ProjectContext = createContext();

// Provide Context
export const ProjectProvider = ({ children }) => {
    const { user } = useContext(UserContext);
    const [projects, setProjects] = useState([]); // Store all projects
    const [currentProject, setCurrentProject] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [activeProject, setActiveProject] = useState(() => {
        return JSON.parse(localStorage.getItem("activeProject")) || null;
    });
    const hasFetched = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();


    // Fetch all projects for the user
    useEffect(() => {
        const fetchProjects = async () => {
            if (user?.token) {
                try {
                    const response = await fetch(`${apiUrl}/api/all_projects/`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                        },
                    });

                    if (response.status === 401) {
                        console.warn("Unauthorized access, redirecting...");
                        navigate("/"); // Redirect to home if token is expired/invalid
                        return;
                    }

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();
                    setProjects(data);

                    // Automatically set the latest project as active
                    // if (data.length > 0) {
                    // setCurrentProject(data[0]); 
                    // }
                } catch (error) {
                    console.error("Error fetching projects:", error);
                }
            } else {
                console.error("No token found");
            }
        };

        fetchProjects();
    }, [user]);

    const updateProjectName = (projectId, newName, newTime) => {
        setProjects((prevProjects) =>
          prevProjects.map((project) =>
            project.id === projectId ? { ...project, name: newName, last_updated: newTime  } : project
          )
        );
    
        // If this is the current project, update its name in the header too
        if (currentProject?.id === projectId) {
          setCurrentProject((prev) => ({ ...prev, name: newName }));
        }
    };


    const handleCreateNewProject = (e) => {
        e.preventDefault();
        localStorage.removeItem("chatSession");
        localStorage.removeItem("currentProject");
        setSelectedProject("new");
        setCurrentProject(null);
        hasFetched.current = false;  // Reset fetch flag in NewProject.js
        navigate("/project/create-project", { state: { fromButton: true } });
    };



    const handleProjectClick = async (project) => {
      if (hasFetched.current) return; // Prevent duplicate execution
        hasFetched.current = true;
      try {
        const response = await fetch(`${apiUrl}/api/check_project/`, {
              method: 'POST',
              credentials: 'include', 
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${user.token}`,
              },
              body: JSON.stringify({
                  project_id: project.id
              }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const projectInfo = {
            id: data.project_id,
            name: data.project_name,
            status: data.project_status,
        };

        if (data.status === "Complete setting up your project."){
            // Update current project state
            hasFetched.current = false;  // Allow fetch to re-run
            setSelectedProject(projectInfo);
            setCurrentProject(projectInfo);
            localStorage.setItem("chatSession", data.chat_session_id);
            localStorage.setItem("currentProject", JSON.stringify(projectInfo));
        };

        if (data.status.trim() === "Continue with your project workspace."){
            hasFetched.current = false;
            setActiveProject(projectInfo);
            localStorage.setItem("activeProject", JSON.stringify(projectInfo));
        };

        toast.success(`${data.status}`, {
            position: "bottom-right"
        });

        

        // Navigate to the returned route
        setTimeout(() => {
            navigate(data.route, { state: { fromButton: true } }); // Pass resetFetch state
        }, 100);   
      } catch (error) {
          console.error("Error checking project:", error);
      } finally {
          hasFetched.current = false;
      }
    };


    useEffect(() => {
        if (activeProject) {
            localStorage.setItem("activeProject", JSON.stringify(activeProject));
        }
    }, [activeProject]);



    const getProjectById = (id) => {
      return projects.find(project => String(project.id) === String(id)) || null;
    };



  return (
    <ProjectContext.Provider value={{ projects, setProjects, currentProject, setCurrentProject, updateProjectName, handleCreateNewProject, handleProjectClick, selectedProject, setSelectedProject, activeProject, setActiveProject, getProjectById }}>
      {children}
    </ProjectContext.Provider>
  );
};

// Custom Hook to use Project Context
export const useProject = () => useContext(ProjectContext);