import React, { useContext, useState, useEffect, useRef } from "react";
import ProjectWorkspaceNav from "./ProjectWorkspaceNav";
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter";
import "../Styles/TaskDetails.css";

const TaskDetails = () => {

    const [chatHistory, setChatHistory] = useState([]);


    // Address Bar for the In-App Browser



    // Ref for the chat container
    const chatContainerRef = useRef(null);

    // Scroll to the last message
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth", // Adds the smooth scrolling effect
            });
        }
    };

    // Scroll to the last message on load or when messages change
    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);




    const FlexibleDiv = ({ width, children, bgc }) => {
        return (
            <div
                style={{
                    width: width,
                    border: "0.5px solid #a1a1a1",
                    borderRadius: "10px",
                    backgroundColor: bgc,
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                }}
            >
                {children}
            </div>
        );
    };
    const Div = () => {
        return (
            <div
                style={{
                    marginTop: 16,
                    width: "100%",
                    height: 66,
                    borderRadius: "6px",
                    backgroundColor: "#30363d",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                }}
            >
                <p className="text12px500 mt-2">Add new task</p>
            </div>
        );
    };

    return (
        <>
            <div id="userpage_container">
                <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader />
                    <div className="ProjectWorkspaceBody">
                        <div className="file_preview_frame overflow-auto" style={{ overflow: "scroll" }}>
                            <div className="d-flex justify-content-between">
                                <p className="text16px600 mt-2 w-50">Develop User Authentication Flow</p>
                                <button className="Btn27272a">In Progress</button>

                            </div>
                            <div class=" mt-4" style={{ gap: "16px" }}>
                                <FlexibleDiv width="100%" bgc="#1e1e1e">

                                    <p className="a1a1a112px500 ">Description</p>
                                    <p className="text12px500 mt-4">This task involves designing and implementing the user authentication system for the VDT ERP platform. It includes creating a secure login and signup process, implementing role-based access control, integrating third-party authentication (Google & Microsoft), and ensuring compliance with security best practices.</p>


                                </FlexibleDiv>

                                <div className="d-flex justify-content-between mt-4">
                                    <FlexibleDiv width="49%" bgc="#1e1e1e">

                                        <p className="text12px500">Assigned To</p>
                                        <div className="d-flex mt-4 gap-4">
                                            <img src="/static/images/Ellipse 1-2.png" style={{ width: "40px", height: "40px" }} alt="" />
                                            <div>
                                                <p className="text12px500">Sarah Wilson</p>
                                                <p className="a1a1a112px500 mt-2">UI/UX Designer</p>

                                            </div>
                                        </div>

                                    </FlexibleDiv>
                                    <FlexibleDiv width="49%" bgc="#1e1e1e">

                                        <p className="text12px500">Timeline</p>
                                        <div className="d-flex justify-content-between mt-2">
                                            <p className="a1a1a112px500">Start Date:</p>
                                            <p className="text12px500">February 5, 2025</p>

                                        </div>
                                        <div className="d-flex justify-content-between mt-2">
                                            <p className="a1a1a112px500">Estimated Completion: </p>
                                            <p className="text12px500">February 5, 2025</p>

                                        </div>
                                        <div className="d-flex justify-content-between mt-2">
                                            <p className="a1a1a112px500">Estimated Completion:</p>
                                            <p className="text12px500">February 5, 2025</p>

                                        </div>

                                    </FlexibleDiv>
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                    <FlexibleDiv width="49%" bgc="#1e1e1e">

                                        <p className="text12px500">Linked Items</p>

                                        <div className="d-flex justify-content-between mt-4">
                                            <button className="Btn27272a">Project Timeline</button>

                                            <p className="text12px500">Sprint 2 – Core System Development</p>

                                        </div>
                                        <div className="d-flex justify-content-between mt-4">
                                            <button className="Btn27272a">Feature</button>

                                            <p className="text12px500">User Authentication & Security</p>

                                        </div>


                                    </FlexibleDiv>
                                    <FlexibleDiv width="49%" bgc="#1e1e1e">

                                        <p className="text12px500">Files & Attachments</p>
                                        <div className="d-flex justify-content-between mt-2">
                                            <p className="text12px500">Auth Wireframes.pdf</p>

                                            <p className="a1a1a112px500">Feb 4</p>

                                        </div>
                                        <div className="d-flex justify-content-between mt-2">
                                            <p className="text12px500">API Documentation</p>
                                            <p className=" a1a1a112px500">Feb 4</p>

                                        </div>
                                        <div className="d-flex justify-content-between mt-2">
                                            <p className=" text12px500">Figma Design</p>
                                            <p className="a1a1a112px500">Feb 4</p>

                                        </div>

                                    </FlexibleDiv>

                                </div>
                                <div className="mt-4">
                                    <FlexibleDiv width="100%" bgc="#1e1e1e">

                                        <p className="text12px500">Task History</p>
                                        <div className="d-flex gap-4 mt-2 align-items-center">
                                            <p className="a1a1a112px500">Feb 4</p>
                                            <p className="text12px500">Task created and assigned to John & Jane.</p>



                                        </div>

                                    </FlexibleDiv>
                                </div>
                                <div className="mt-4">
                                    <FlexibleDiv width="100%" bgc="#1e1e1e">

                                        <p className="text12px500">Next Steps</p>
                                        <div className="d-flex justify-content-between mt-4">
                                            <p className="text12px500">UI Design Review</p>

                                            <button className="Btn000000">Feb 4</button>

                                        </div>
                                        <div className="d-flex justify-content-between mt-4">
                                            <p className="text12px500">Backend Testing</p>
                                            <button className="Btn000000">Feb 4</button>

                                        </div>
                                        <div className="d-flex justify-content-between mt-4">
                                            <p className=" text12px500">Final Approval</p>
                                            <button className="Btn000000">Feb 4</button>

                                        </div>

                                    </FlexibleDiv>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProjectWorkspaceFooter />
                </div>
                <ProjectWorkspaceChat />
            </div >
        </>
    );
};

export default TaskDetails;
