import React, { useState, useContext, useEffect }  from 'react'
import { useNavigate } from 'react-router-dom';
import { FormTokenContext } from './FormTokenProvider';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const apiUrl = process.env.REACT_APP_API_URL;


const RequestForm =() =>{
    const {formToken} = useContext(FormTokenContext);
    const navigate = useNavigate();
    const[request, setRequest] = useState({
        fullname: '',
        honeyPot: '',
        email: '',
        phone: '',
        companyName: '',
        serviceType: '',
        projectDesc: '',
        contactMethod: ''
    });

    const handleViewRequest = () => {
    
        const emailFromStorage = sessionStorage.getItem('email');
        if (emailFromStorage) {
            const successModal = document.getElementById('exampleModalToggle2');
    
            if (successModal) {
                // Remove Bootstrap's "show" class manually
                successModal.classList.remove('show');
                successModal.setAttribute('aria-hidden', 'true');
                successModal.style.display = 'none';
    
                // Remove backdrop if it exists
                const backdrop = document.querySelector('.modal-backdrop');
                if (backdrop) {
                    backdrop.remove();
                }
    
                // Allow time for the modal to close before navigating
                setTimeout(() => {
                    navigate('/requests', { state: { email: emailFromStorage } });
                }, 300); // Small delay to ensure modal closes smoothly
                return;
            }
    
            // If modal isn't found, navigate immediately
            navigate('/requests', { state: { email: emailFromStorage } });
        }
    };




    const fieldChange = (e) => {
        const { name, value, type } = e.target;
        setRequest(prev => ({
            ...prev,
            [name]: type === "radio" ? value : value
        }));
    };

    const requestSubmit =(e) =>{
        e.preventDefault();
        if (request.honeyPot){
            toast.error('Bad Request.', {
                position: "bottom-right"
            });
            return;
        }
        const trimmedRequest = {
            fullname: request.fullname?.trim() || '',
            email: request.email?.trim() || '',
            phone: request.phone?.trim() || '',
            companyName: request.companyName?.trim() || '',
            serviceType: request.serviceType?.trim() || '',
            projectDesc: request.projectDesc?.trim() || '',
            contactMethod: request.contactMethod || ''
        };

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phonePattern = /^(070|071|080|081|090|091)\d{8}$/;

        if (!trimmedRequest.fullname || !trimmedRequest.email || !trimmedRequest.phone || !trimmedRequest.serviceType || !trimmedRequest.projectDesc) {
            toast.error('All fields are required.', {
                position: "bottom-right"
            });
            return;
        }

        if (!emailPattern.test(trimmedRequest.email)) {
            toast.error('Please enter a valid email address.', {
                position: "bottom-right"
            });
            return;
        }

        if (!phonePattern.test(trimmedRequest.phone)) {
            toast.error('Please enter a valid phone number.', {
                position: "bottom-right"
            });
            return;
        }
        
        fetch(`${apiUrl}/api/service-request/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': formToken, 
            },
            body: JSON.stringify(trimmedRequest),
        })
        .then(async response => {
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'An error occurred');
            }
            return response.json();
        })
        .then(data => {
            if (data.message === 'Contact submitted successfully') {
                // Store email in sessionStorage for navigation
                sessionStorage.setItem('email', trimmedRequest.email);
                // Hide the form modal
                const requestModal = document.getElementById('exampleModal');
                if (requestModal) {
                    window.$(requestModal).modal('hide');
                }
        
                // Show the success modal
                const successModal = document.getElementById('exampleModalToggle2');
                if (successModal) {
                    window.$(successModal).modal('show');
                }
        
                setRequest({
                    fullname: '',
                    email: '',
                    phone: '',
                    companyName: '',
                    serviceType: '',
                    projectDesc: '',
                    honeyPot: '',
                    contactMethod: ''
                });
                
            } else {
                toast.error(`${data.message}`, {
                    position: "bottom-right"
                });
            }
        })
        .catch(error => {
            toast.error(`${error}`, {
                position: "bottom-right"
            });
        });
    }
    

    useEffect(() => {
        // Cleanup function to clear sessionStorage when the component unmounts
        return () => {
            sessionStorage.removeItem('email');
        };
    }, []);




    return(
        <>
            {/* Modal */}
            <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header my-0">
                            <button
                                type="button"
                                className="btn back_btn"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ><img src="/static/images/back_icon.png" className="img-fluid" alt="back icon"  />Back</button>
                        </div>
                        <div className="modal-body">
                            <h1>Request a Service</h1>
                            <p>Fill in the form provided below to request a service and we will be in touch with you!</p>
                            <form onSubmit={requestSubmit}>
                                <div className='mb-2'>
                                    <label htmlFor='fullname'>Full Name</label>
                                    <input type='text' className='form-control' id='fullname' name='fullname' value={request.fullname} onChange={fieldChange} placeholder='John Doe'/>
                                    <input type='hidden' name='honeyPot' value={request.honeyPot} onChange={fieldChange} />
                                </div>
                                <div className='mb-2'>
                                    <label htmlFor='email'>Email Address</label>
                                    <input type='email' className='form-control' id='email' name='email' value={request.email} onChange={fieldChange} placeholder='example@example.com' />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor='phone'>Phone Number</label>
                                    <div className='input-group'>
                                        <span className="input-group-text" id="inputGroup-sizing-default">+234</span>
                                        <input type="tel" className="form-control" id='phone' name='phone' value={request.phone} onChange={fieldChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder='1234567890' />
                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <label htmlFor='company_name'>Company Name (Optional)</label>
                                    <input type='text' className='form-control' id='company_name' name='companyName' value={request.companyName} onChange={fieldChange} placeholder='DesignXcel' />
                                </div>
                                <div className='mb-2'>
                                    <label htmlFor='service_type'>Sercvice Type</label>
                                    <select className='form-select' name="serviceType" value={request.serviceType} onChange={fieldChange}>
                                        <option value="">Please select</option>
                                        <option value="Design Services">Design Services</option>
                                        <option value="Development Services">Development Services</option>
                                        <option value="Printing Services">Printing Services</option>
                                        <option value="Recruitment Services">Recruitment Services</option>
                                        <option value="Support Services">Support Services</option>
                                        <option value="Training Services">Training Services</option>
                                    </select>
                                </div>
                                <div className='mb-2'>
                                    <label htmlFor='project_desc'>Project Description</label>
                                    <textarea className='form-control' rows='4' placeholder='Enter project description here' id='project_desc' name='projectDesc' value={request.projectDesc} onChange={fieldChange} />
                                </div>
                                <div className='mb-4 prefered_contact_method'>
                                    <label htmlFor="contactMethod" className="d-block mb-2">Preferred Contact Method</label>
                                    <div className="d-flex align-items-center gap-4">
                                        <label className="d-flex align-items-center">
                                        <input type="radio" id="email-radio" name="contactMethod" className="custom-radio" value="Email" onChange={fieldChange}/>
                                        <span>Email</span>
                                        </label>
                                        
                                        <label className="d-flex align-items-center">
                                        <input type="radio" id="phone-radio" name="contactMethod" className="custom-radio" value="Phone" onChange={fieldChange}/>
                                        <span>Phone</span>
                                        </label>
                                        
                                        <label className="d-flex align-items-center">
                                        <input type="radio" id="chat" name="contactMethod" className="custom-radio" value="Chat" onChange={fieldChange} />
                                        <span>Chat</span>
                                        </label>
                                    </div>
                                </div>
                                <div align='start'>
                                    <button className='btn btn-lg' type='submit'>Submit Request</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>




            {/* Alert Modal */}

            <div className="modal fade request_notification_modal" id="exampleModalToggle2" aria-hidden={true} aria-labelledby="exampleModalToggleLabel2" tabIndex={-1} >
                    <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                            <div className="modal-body">
                                <img src="/static/images/notification_message_icon.png" className="img-fluid" alt="Success Message icon"  />
                                <h4>Request Sent Successfully</h4>
                                <p>We will get back to you.</p>
                                <div>
                                    <button
                                    className="btn"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Stop interference
                                        handleViewRequest();
                                    }}>
                                    View Requests
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default RequestForm