import React from "react";
import '../../Styles/Meetings.css'
const MeetingCard = ({
  title,
  date,
  description,
  duration,
  participants,
  peopleNote,
  actionLabel,
  onActionClick,
  notes,
}) => {
  return (
    notes ? <div className="meeting-card">
      <div className="content">
        <h3 className="meeting-title">{title}</h3>
        <p className="meeting-date">{date}</p>
      </div>
      <p className="notes">{notes}</p>
      <div className="content">
        <div className="meeting-meta">
          <p>{description}</p>
          <p>{duration}</p>
          <p>{participants} Participants</p>
        </div>
        <div className="meeting-people">
          <div className="avatar-group">
            <img src='/static/images/person2.png' className='avatar' alt='person' />
            <img src='/static/images/person1.png' className='avatar' alt='person' />
            <img src='/static/images/person2.png' className='avatar' alt='person' />
            <img src='/static/images/person1.png' className='avatar' alt='person' />
          </div>
          <p className="people-note">{peopleNote}</p>
        </div>
      </div>
    </div> : <div className="meeting-card">
        <div className="content">
          <div className="meeting-info">
            <div className="img">
              <img src='/static/images/video.svg' alt='video icon' />
            </div>
            <div>
              <h3 className="meeting-title">{title}</h3>
              <p className="meeting-date">{date}</p>
              <div className="meeting-meta">
                <p>{description}</p>
                <p>{duration}</p>
                <p>{participants} Participants</p>
              </div>
            </div>
          </div>
          <div>
            {actionLabel && (
              <button className="top-tab-btn" onClick={onActionClick}>
                {actionLabel}
              </button>
            )}
            <div className="meeting-people">
              <div className="avatar-group">
                <img src='/static/images/person2.png' className='avatar' alt='person' />
                <img src='/static/images/person1.png' className='avatar' alt='person' />
                <img src='/static/images/person2.png' className='avatar' alt='person' />
                <img src='/static/images/person1.png' className='avatar' alt='person' />
              </div>
              <p className="people-note">{peopleNote}</p>
            </div>
          </div>
        </div>
</div>
  );
};

export default MeetingCard;