import React, { useContext } from "react";
import DashboardNav from './DashboardNav';
import '../Styles/TeamPayments.css'
import Table from "../Components/Table";
import { useNavigate } from "react-router-dom";
import NotificationProfile from "../Components/NotificationProfile";

const TeamPayments = () => {
  const navigate = useNavigate();
  // Payment Stats Data
  const stats = [
    { title: "Total Payroll", amount: "₦2,750,000.00", description: "65% processed this month" },
    { title: "Breakdown", amount: "Talent Pay: ₦2,750.00", highlight: "Taxes: ₦2,750.00" },
    { title: "Outstanding", amount: "₦2,750,000.00", description: "Pending payments" },
    { title: "Upcoming", amount: "₦2,750,000.00", description: "Due in the next 7 days" }
  ];

  // Table Headers
  const columns = ["Team Member", "Payment Type", "Amount", "Status", "Next Due Date", "Contract Duration", ""];

  // Table Rows
  const rows = [
    ["John Doe", "Monthly", "₦2,750.00", <span className="status-pending">Pending</span>, "2025-02-15", "Permanent", <button className="button-text" onClick={() => navigate("/billing/team-payments/1")}>Process</button>],
    ["John Doe", "Monthly", "₦2,750.00", <span className="status-pending">Pending</span>, "2025-02-15", "Permanent", <button className="button-text" onClick={() => navigate("/billing/team-payments/2")}>Process</button>]
  ];


  const goBack = () => {
    window.history.back();
  }

  return (
    <>
      <div id="userpage_container">
        <DashboardNav />
        <div className="wrapper card">
          <header className="mb-5">
            <div className="flex mb-3">
            <h2>Team Payments</h2>
              <NotificationProfile />
            </div>
            <div className="flex mt-4">
              <button className="button" onClick={goBack}><img src='/static/images/back.svg' alt="Angle Left" className="me-2" /> Back</button>
              <div className="plan text-center">Business</div>
            </div>
          </header>
          <main>
            <div className="card dark p-4">
              <div className="flex mt-4">
                <p className="text-white">Manage and process payments for your team members</p>
                <button className="button">Feb 2025</button>
              </div>
              <div className="grid mt-3 grid-4">
                {stats.map((stat, index) => (
                  <div className="card dark" key={index}>
                    <h6>{stat.title}</h6>
                    <h6>{stat.amount}</h6>
                    {stat.highlight && <strong>{stat.highlight}</strong>}
                    <p>{stat.description}</p>
                  </div>
                ))}
              </div>

              <div className="my-5">
                <h6>Team Members</h6>
                <p className="text-white">Manage and process payments for your team members</p>
                <Table columns={columns} rows={rows} />
             </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default TeamPayments