import React, { useEffect } from "react";
import Nav from "./Nav";
import Footer from "./Footer";


const Pricing =() =>{
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return(
        <>
        <Nav />
        {/* Pricing header start */}
        <section className="pricing_header py-3">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Pricing Plans</h1>
                        <p>Choose the perfect plan for your needs. All plans include access to our core services with dedicated resources.</p> 
                    </div>
                </div>
            </div>
        </section>
        {/* Pricing header end */}
        {/* Services Body start */}
        <section className="pricing_body mt-1 py-3">
            <div className="container">
                <div className="row">
                    <div className="col-md-5 col-lg-4 col-12">
                        <div>
                            <p>Free Plan (Starter)</p>
                            <small>Perfect for getting started</small>
                            <h2>&#8358;0<span>/month per user</span></h2>
                            <ul>
                                <li>1 project</li>
                                <li>1 free seat</li>
                                <li>Basic workspace tools</li>
                            </ul>
                        </div>
                        <div className="d-grid mt-2">
                            <button className="btn btn-lg" onClick={()=> document.location.href='/login'}>Get Started</button>
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-4 col-12">
                        <div>
                            <p>Startup Plan</p>
                            <small>Best for small businesses & startups</small>
                            <h2>Pay-As-You-Go</h2>
                            <ul>
                                <li>Unlimited projects</li>
                                <li>1 free seat</li>
                                <li>Full Workspace access</li>
                                <li>Pay-as-you-go talent hiring</li>
                                <li>Standard turnaround times</li>
                                <li>Project-based collaboration</li>
                            </ul>
                        </div>
                        <div className="d-grid mt-2">
                            <button className="btn btn-lg" onClick={()=> document.location.href='/login'}>Get Started</button>
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-4 col-12">
                        <div>
                            <p>Growth Plan</p>
                            <small>Perfect for scaling agencies.</small>
                            <h2>&#8358;10,000<span>/month per user</span></h2>
                            <ul>
                                <li>Unlimited projects</li>
                                <li>Unlimited team members</li>
                                <li>Advanced AI project setup & tools</li>
                                <li>Custom workflow automation</li>
                                <li>Advanced reporting & analytics</li>
                                <li>Private teams</li>
                                <li>Team Management & Role Assignments</li>
                                <li>Automated Payroll & Payment Distribution</li>
                                <li>Client Billing & Invoicing</li>
                                <li>Project Tracking & Reporting</li>
                                <li>AI-Powered Task & Feature Planning</li>
                                <li>Tool & Access Management</li>
                                <li>Custom Branding & White-Labeling</li>
                            </ul>
                        </div>
                        <div className="d-grid mt-2">
                            <button className="btn btn-lg" onClick={()=> document.location.href='/login'}>Get Started</button>
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-4 col-12">
                        <div>
                            <p>Enterprise Plan</p>
                            <small>For large teams & corporate agencies</small>
                            <h2>Custom Pricing</h2>
                            <ul>
                                <li>Unlimited Team Members & Clients</li>
                                <li>Advanced Role-Based Access Control</li>
                                <li>Automated Task & Deadline Management</li>
                                <li>Real-Time Dashboards & Reports</li>
                                <li>Automated Payroll & Invoicing</li>
                                <li>White-Label Client Portal</li>
                                <li>Talent & Work Quality Scoring</li>
                                <li>Custom Workflows & Project Templates</li>
                                <li>Custom API & Webhook Access</li>
                                <li>Priority Support & Dedicated Account Manager</li>
                            </ul>
                        </div>
                        <div className="d-grid mt-2">
                            <button className="btn btn-lg" onClick={()=> document.location.href='/login'}>Get Started</button>
                        </div>
                    </div>

                </div>
                
            </div>
        </section>
        {/* Services Body end */}
        <Footer />

        </>
    )
}

export default Pricing