import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from './UserProvider';
const apiUrl = process.env.REACT_APP_API_URL;

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { user, logout } = useContext(UserContext);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    
    useEffect(() => {
        const checkAuth = async () => {
            if (user?.token) {
                try {
                    const response = await fetch(`${apiUrl}/api/check-auth/`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const data = await response.json();
                    
                    if (data.message === 'User is authenticated') {
                        setIsAuthenticated(true);
                    } else {
                        console.error('Not authenticated:', data.message);
                        logout();
                        setIsAuthenticated(false);
                    }
                } catch (error) {
                    console.error('Error during authentication check:', error);
                    logout();
                    setIsAuthenticated(false);
                }
            } else {
                logout();
                setIsAuthenticated(false);
            }
            setLoading(false); 
        };

        checkAuth();
    }, [user, logout]);

    // Block rendering while loading
    if (loading) {
        return <div>Loading...</div>;
    }

    // Render protected component if authenticated, otherwise redirect
    return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
