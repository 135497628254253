import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormTokenContext } from './FormTokenProvider';
import Nav from './Nav';
import Footer from "./Footer";
const apiUrl = process.env.REACT_APP_API_URL;




const Requests =() =>{
    const {formToken} = useContext(FormTokenContext);
    const [email, setEmail] = useState({
        honeyPot: '',
        userEmail: '',
    });;
    const [userRequests, setUserRequests] = useState([]);
    const [hasSearched, setHasSearched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // setEmail({ honeyPot: '', userEmail: '' });
        // setUserRequests(null);
        // sessionStorage.removeItem('email');
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        if (location.state?.email) {
            const emailFromState = location.state.email;
            sessionStorage.setItem('email', emailFromState);
    
            // Set email state (for UI)
            setEmail(prevEmail => ({
                ...prevEmail, 
                userEmail: emailFromState
            }));
    
            // 🔥 Call handleSearch with correct email immediately
            handleSearch({ userEmail: emailFromState, honeyPot: '' });
        }
    
        return () => {
            setEmail({ honeyPot: '', userEmail: '' });
            setUserRequests(null);
            sessionStorage.removeItem('email');
        };
    }, [location.state]);



    
    const handleSearch = (emailData) => {
        setIsLoading(true);
        setHasSearched(true);
        if (emailData.honeyPot) {
            toast.error('Bad Request.', {
                position: "bottom-right"
            });
            setHasSearched(false);
            setIsLoading(false);
            return;
        }


        const trimmedUserEmail = {
            email: (emailData.userEmail || '').trim()
        };
        
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if (!trimmedUserEmail.email) {
            toast.error('Email is required', { position: "bottom-right" });
            setHasSearched(false);
            setIsLoading(false);
            return;
        }
        
        if (!emailPattern.test(trimmedUserEmail.email)) {
            toast.error('Enter a valid email address', { position: "bottom-right" });
            setHasSearched(false);
            setIsLoading(false);
            return;
        }

        fetch(`${apiUrl}/api/view-requests/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': formToken, 
            },
            body: JSON.stringify(trimmedUserEmail),
        })
        .then(async response => {
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'An error occurred');
            }
            return response.json();
        })
        .then(data => {
            setUserRequests(data.requests.length > 0 ? data.requests : []);
            setEmail(prevEmail => ({
                ...prevEmail, 
                userEmail: data.email
            }));
            setIsLoading(false);
        })
        .catch(error => {
            toast.error(`${error}`, {
                position: "bottom-right"
            });
            setHasSearched(false);
            setIsLoading(false);
        });
    };



    const fieldChange = (e) => {
        setEmail((prevEmail) => ({
            ...prevEmail, 
            [e.target.name]: e.target.value
        }));
        setHasSearched(false);
        setUserRequests([])
    };
    
    
    



    return (
        <>
        <Nav />
        { /* Home header start */}
        <section className="all_requests_section mt-1 py-3">
            <div className="container">
                <div className="row">
                    <div className="landing_page_back_button">
                        <button className="btn" onClick={() => navigate(-1)}><img src="/static/images/back_icon.png" className="img-fluid" alt="back icon"  />Back</button>
                    </div>
                    <div className="col all_requests_header" align="center">
                        <h1>{userRequests && userRequests.length > 0 ? "Your Requests" : "Requests"}</h1>
                    </div>
                    <div className="col-md-6 request_email_input_section" align="center">
                        <form>
                            <label htmlFor="email">Enter your email to view your requests</label>
                            <input 
                                type="email" 
                                id="email" 
                                className="form-control mt-2"
                                placeholder="Enter email"
                                name="userEmail"
                                value={email.userEmail} 
                                onChange={fieldChange} 
                            />
                            <input type='hidden' name='honeyPot' value={email.honeyPot} onChange={fieldChange} />
                            <button className="btn mt-2 request_search_button" onClick={(e) => {
                                        e.preventDefault();
                                        handleSearch(email); // Pass the current email state to handleSearch
                                    }} disabled={isLoading}>{isLoading ? 'Searching...' : 'Search'}</button>
                        </form>
                    </div>
                    {userRequests && userRequests.length > 0 && (<div className="col user_contact_deets" align="center">
                            <h4>Contact</h4>
                            <p>{email.userEmail}</p>
                            <button className="btn mt-2 no_request_button" data-bs-toggle="modal" data-bs-target="#exampleModal">Leave a New Request</button>
                        </div>)}
                    
                    <div className="col user_requests_body" align="center">
                    {isLoading ? (
                                <div>Loading...</div> 
                            ) : hasSearched ? (
                                userRequests.length > 0 ? (
                                    userRequests.map((request, index) => (
                                        <div key={index} className="row">
                                            <h4>{request.title}</h4>
                                            <p>{request.description}</p>
                                            <span>{request.details}</span>
                                            <div className="user_requests_status">
                                                <div>
                                                    <h6>Status</h6>
                                                    <span>{request.status}</span>
                                                </div>
                                                <div>
                                                    <h6>Budget</h6>
                                                    <span>{request.budget}</span>
                                                </div>
                                                <div>
                                                    <h6>Type</h6>
                                                    <span>{request.type}</span>
                                                </div>
                                                <div>
                                                    <h6>Mode</h6>
                                                    <span>{request.mode}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <button className="btn schedule_call_btn">Schedule a Call</button>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="col no_requests_message" align="center">
                                        <h4>No request associated with this email.</h4>
                                        <button className="btn mt-2 no_request_button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            Leave a Request
                                        </button>
                                    </div>
                                )
                            ) : null}
                    </div>
        



                </div>
            </div>
        </section>
        { /* Home header end */}
      

       
        <Footer />
        </>
    )
}


export default Requests