import React, { useContext } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from '../UserProvider';
import { useProject } from "./ProjectData";
import '../Styles/ProjectWorkspaceFooter.css';
const apiUrl = process.env.REACT_APP_API_URL;

const ProjectWorkspaceFooter = () => {
    const { user } = useContext(UserContext);
    const { activeProject } = useProject();
    

    return (
        <div className="row project_workspace_footer">

            <div className="col delivery_deets">
                <h4>Deliverables Details</h4>
                <div>
                    <small><img src="/static/images/deliverables_details_icon.png" className="img-fluid" alt="address bar refresh icon" />{activeProject.type}</small>
                </div>
            </div>
            <div className="col tools_used">
                <h4>Tools Used</h4>
                <div>
                    <small>No tools added yet</small>
                    {/* <small><img src="/static/images/figma_icon.png" className="img-fluid" alt="Figma icon" />Figma</small>
                    <small><img src="/static/images/webflow_icon.png" className="img-fluid" alt="webflow icon" />Webflow</small> */}
                </div>
            </div>
            <div className="col est_deleivery_date">
                <h4>Est. Completion Date:</h4>
                <div>
                    <small><img src="/static/images/calendar_icon.png" className="img-fluid" alt="calendar_icon" />No estimated date yet</small>
                </div>
            </div>
            <div className="col collaborators">
                <h4>Collaborators</h4>
                <div className="collaborators_info">
                    {/* <div>
                        <img className="img-fluid img_1" src="/static/images/bcs1.png"/>
                        <img className="img-fluid img_2" src="/static/images/bcs2.png"/>
                        <img className="img-fluid img_3" src="/static/images/bcs1.png"/>
                        <img className="img-fluid img_4" src="/static/images/bcs2.png"/>
                    </div>
                    <small>Kunle & 2 others online</small> */}
                    <small>No team added yet</small>
                </div>
            </div>
        </div>
    );
};



export default ProjectWorkspaceFooter;
