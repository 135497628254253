import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormTokenContext } from './FormTokenProvider';
import { UserContext } from './UserProvider';
import './Styles/VerifyEmail.css'
const apiUrl = process.env.REACT_APP_API_URL;


const VerifyEmail =() =>{
    const {formToken} = useContext(FormTokenContext);
    const { login } = useContext(UserContext);
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const inputRefs = useRef([]);
    const effectRan = useRef(false);


    const email = location.state?.email || "";

    useEffect(() => {
        if (effectRan.current) return;  // Prevent running twice in development
    
        effectRan.current = true;  // Mark effect as run
    
        if (!email) {  // If email is missing, navigate back
            toast.error("Email is required to proceed.", { position: "bottom-right" });
            navigate("/register", { replace: true });
        }
    }, [email, navigate]); 





    const handleChange = (index, event) => {
        const value = event.target.value;

        if (/^\d$/.test(value)) { // Allow only digits
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Move to next input if it exists
            if (index < otp.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        } else if (value === "") {
            const newOtp = [...otp];
            newOtp[index] = "";
            setOtp(newOtp);
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace") {
            if (otp[index] === "") {
                // Move to previous input if exists
                if (index > 0) {
                    inputRefs.current[index - 1].focus();
                }
            }
            const newOtp = [...otp];
            newOtp[index] = "";
            setOtp(newOtp);
        }
    };
    
    const isButtonEnabled = otp.every((digit) => /^\d$/.test(digit));




    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setLoading(true);

        const otpCode = otp.join(""); // Convert array to string
        if (!email) {
            setError("Email is missing.");
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/verify-otp/`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': formToken, 
                },
                body: JSON.stringify({ email, otp: otpCode })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || "Verification failed.");
            }

            const token = data.access_token;
            login(token);
            toast.success(`${data.message}`, {
                position: "bottom-right"
            });
            navigate("/create-profile");
        } catch (error) {
            setError(error.message);
            toast.error(`${error.message}`, {
                position: "bottom-right"
            });
        }

        setLoading(false);
    };


    return (
        <>
        {/* Verify Email Section Start */}
        <section className='verify_email_section'>
            <div className='row'>
                <div className='col-md-7 col-lg-4'>
                    <h1>Verify your email</h1>
                    <p>A code has been sent to {email}.</p>
                    <p>Enter the code below to complete your registration.</p>
                    <p><Link className='account_link' to='/login'>Change email address</Link></p>
                    <form className='mt-4 mb-3' onSubmit={handleSubmit}>
                        <div className="otp_box">
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    className={`form-control otp-input ${digit ? "active" : "inactive"}`}
                                    type="text"
                                    value={digit}
                                    onChange={(e) => handleChange(index, e)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    maxLength="1"
                                />
                            ))}
                        </div>
                        <div className='d-grid mt-4'>
                            <button type='submit' className={`btn btn-lg ${isButtonEnabled ? "active-btn" : "disabled-btn"}`} 
                                disabled={!isButtonEnabled}>Verify</button>
                        </div>
                    </form>
                    <p><Link className='account_link' to=''>Resend code</Link></p>
                    <div align='center' className='mt-5'>
                         <Link to="/"><img src='/static/images/designxcel_logo.png' className='img-fluid' alt='Designxcel Logo'/></Link>
                    </div>
                </div>
            </div>
        </section>
        {/* Verify Email Section End */}
        </>
    )
}


export default VerifyEmail