import React, { useContext } from "react";
import { SidebarContext } from "./Sidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { UserDataContext } from "./UserData";
import { useProject } from "./ProjectData";
import '../Styles/DashboardHeader.css'
import NotificationProfile from "../Components/NotificationProfile";




const DashboardHeader = () => {
    const { toggleSidebar } = useContext(SidebarContext);
    const { userFirstName } = useContext(UserDataContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { currentProject } = useProject();
    

    


    

    return (
        <nav className="user-topnav navbar navbar-expand">
            <div className="container-fluid d-flex align-items-center">
                <div className="flex-grow-1 ms-3 d-flex align-items-center justify-content-between">
                    {location.pathname === "/project/create-project" ? (
                        <div className="d-flex align-items-center">
                            <button onClick={() => navigate(-1)} className="btn back-btn">
                                <img src="/static/images/back_icon.png" className="img-fluid back_icon" alt="back icon" />
                            </button>
                            <p className="dashboard-text mb-0 ms-2">{currentProject ? currentProject.name : "Loading..."}</p>
                        </div>
                    ) : (
                        <p className="dashboard-text mb-0">
                            Welcome, <strong>{userFirstName ? userFirstName : "Loading..." }</strong>
                        </p>
                    )}


                    {/* Search Box */}
                    <div className="search-box mx-auto">
                        <div className="input-group">
                            <span className="input-group-text">
                                <img src="/static/images/search_icon.png" className="img-fluid" alt="Search Icon" />
                            </span>
                            <input
                                type="text"
                                className="form-control"
                                id="search"
                                name="search"
                                placeholder="Search for anything"
                            />
                        </div>
                    </div>
                    <NotificationProfile />
                </div>
            </div>
        </nav>
    );
};

export default DashboardHeader;
