// CalendarTimePicker.jsx
import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import { format } from 'date-fns';
import 'react-calendar/dist/Calendar.css';
import './CalendarTimePicker.css';

// Utility to generate half-hour time strings (12:00AM -> 11:30PM)
function generateHalfHourTimes() {
  const times = [];
  for (let i = 0; i < 24 * 2; i++) {
    // Each "i" is a 30-minute block
    const hour24 = Math.floor(i / 2); // 0..23
    const minute = i % 2 === 0 ? '00' : '30';

    // Convert 24h -> 12h
    const suffix = hour24 < 12 ? 'AM' : 'PM';
    let hour12 = hour24 === 0 ? 12 : hour24;
    if (hour24 > 12) {
      hour12 = hour24 - 12;
    }

    times.push(`${hour12}:${minute}${suffix}`);
  }
  return times;
}

export default function CalendarTimePicker({ onDateTimeChange }) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('');

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  // All half-hour increments in a day
  const times = generateHalfHourTimes();

  // Handle calendar date change
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // Handle time slot click
  const handleTimeClick = (time) => {
    setSelectedTime(time);
  };

  // Whenever date or time changes, format and pass up to parent
  useEffect(() => {
    if (selectedDate && selectedTime) {
      // Parse the "hh:mmAM/PM" string to set hours/minutes on the date
      const [timePart, ampm] = selectedTime.split(/(AM|PM)/);
      const [hourString, minuteString] = timePart.split(':');
      let hour = parseInt(hourString, 10);
      const minute = parseInt(minuteString, 10);

      // Adjust for 12-hour clock
      if (ampm === 'PM' && hour < 12) hour += 12;
      if (ampm === 'AM' && hour === 12) hour = 0;

      // Create a new date with the chosen time
      const dateWithTime = new Date(selectedDate);
      dateWithTime.setHours(hour, minute, 0, 0);

      // Format: e.g. "Thursday, 12th January, 2025, 11:00AM"
      // date-fns format reference: https://date-fns.org/v2.29.3/docs/format
      const formatted = format(dateWithTime, 'EEEE, do MMMM, yyyy, h:mma');

      // Send that string up to the parent
      onDateTimeChange(formatted);
    }
  }, [selectedDate, selectedTime, onDateTimeChange]);

  return (
    <div className="calendar-time-container">
      {/* Left: Calendar */}
      <div className="calendar-wrapper">
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
          prev2Label={''}
          next2Label={''}
          formatMonthYear={(locale, date) => format(date, 'MMMM')}
          calendarType='gregory'
          formatShortWeekday={(locale, date) => {
            const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
            return days[date.getDay()];
          }}
          prevLabel={<span className="nav-arrow">{'<'}</span>}
          nextLabel={<span className="nav-arrow">{'>'}</span>}
          // 1) Disable all past dates:
          minDate={today}

          // 2) Disable Saturdays (6) & Sundays (0):
          tileDisabled={({ date, view }) => {
            // Only disable on the month view to avoid disabling months/years in other views
            if (view === 'month') {
              const day = date.getDay();
              // 0 = Sunday, 6 = Saturday
              return day === 0 || day === 6;
            }
            return false;
          }}
        />

      </div>

      {/* Right: Time Slots */}
      <div className="time-wrapper">
        {times.map((time) => (
          <button
            key={time}
            onClick={() => handleTimeClick(time)}
            className={`time-slot ${time === selectedTime ? 'active' : ''}`}
          >
            {time}
          </button>
        ))}
      </div>
    </div>
  );
}