import React, { useContext } from "react";
import DashboardNav from './DashboardNav';
import '../Styles/Plans.css'
import NotificationProfile from "../Components/NotificationProfile";

const plans = [
  {
    title: "Free Plan (Starter)",
    description: "Perfect for getting started",
    price: "₦0",
    features: ["1 project", "1 free seat", "Basic workspace tools"],
    buttonText: "Choose Plan",
    period: '/month per user'
  },
  {
    title: "Startup Plan",
    description: "Best for small businesses & startups",
    price: "Pay-As-You-Go",
    features: [
      "Unlimited projects",
      "1 free seat",
      "Basic workspace tools",
      "Pay-as-you-go talent hiring",
      "Standard turnaround times",
      "Project-based collaboration",
    ],
    buttonText: "Current Plan",
    highlight: true,
    period: ''
  },
  {
    title: "Growth Plan",
    description: "Perfect for scaling agencies",
    price: "₦10,000",
    features: [
      "Unlimited projects",
      "Unlimited team members",
      "Advanced AI project setup & tools",
      "Custom workflow automation",
      "Advanced reporting & analytics",
      "Private teams",
      "Team Management & Role Assignments",
      "Automated Payroll & Payment Distribution",
      "Client Billing & Invoicing",
      "Project Tracking & Reporting",
      "AI-Powered Task & Feature Planning",
      "Tool & Access Management",
      "Custom Branding & White-Labeling",
    ],
    buttonText: "Choose Plan",
    period: '/month per user'
  },
  {
    title: "Enterprise Plan",
    description: "For large teams & corporate agencies",
    price: "Custom Pricing",
    features: [
      "Unlimited Team Members & Clients",
      "Advanced Role-Based Access Control",
      "Automated Task & Deadline Management",
      "Real-Time Dashboards & Reports",
      "Automated Payroll & Invoicing",
      "White-Label Client Portal",
      "Talent & Work Quality Scoring",
      "Custom Workflows & Project Templates",
      "Custom API & Webhook Access",
      "Priority Support & Dedicated Account Manager",
    ],
    buttonText: "Choose Plan",
    period: ''
  },
];

const Plans = () => {

  const goBack = () => {
    window.history.back();
  }

  return (
    <>
      <div id="userpage_container">
        <DashboardNav />
        <div className="wrapper card plan_container">
          <header >
            <div className="flex mb-3">
            <h2>Plans</h2>
            <NotificationProfile />
            </div>
            <div className="flex">
              <button className="button" onClick={goBack}><img src='/static/images/back.svg' alt="Angle Left" className="me-2" /> Back</button>
              <div className="plan text-center">Business</div>
            </div>
          </header>
          <main>
            <div className="mt-5 mb-4">
              <h6>Available Plans</h6>
              <p>Compare and upgrade your plan</p>
            </div>
            <div className="grid grid-2 m-0">
              {plans.map((plan, index) => (
                <div key={index} className={`plan-card card dark ${plan.highlight ? "highlight" : ""}`}>
                  <h6>{plan.title}</h6>
                  <p>{plan.description}</p>
                  <h4 className="mt-3">{plan.price}<span>{plan.period}</span></h4>
                  <ul>
                    {plan.features.map((feature, index) => (
                      <li key={index}>
                        <img src='/static/images/tick_icon.png' alt="Check" width={24} height={24}/> {feature}
                      </li>
                    ))}
                  </ul>
                  <button className={`button ${plan.highlight ? "current-plan" : ""}`}>
                    {plan.buttonText}
                  </button>
                </div>
              ))}
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default Plans