import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from '../UserProvider';
import { useProject } from "./ProjectData";
import TeamMemberContext from "./TeamMemberContext";
import ProjectWorkspaceNav from './ProjectWorkspaceNav';
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";

import { SidebarContext } from "./Sidebar";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter";
import '../Styles/AddTeamMember.css'
const apiUrl = process.env.REACT_APP_API_URL;



const AddTeamMember = () => {
    const { user } = useContext(UserContext);
    const { activeProject } = useProject();
    const { formData, setFormData } = useContext(TeamMemberContext);
    const [suggestions, setSuggestions] = useState([]); // Stores fetched users
    const [showDropdown, setShowDropdown] = useState(false);
    const [showRoleDropdown, setShowRoleDropdown] = useState(false);
    const [showAccessDropdown, setShowAccessDropdown] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(null);
    const emailDropdownRef = useRef(null);
    const roleDropdownRef = useRef(null);
    const accessDropdownRef = useRef(null);
    const navigate = useNavigate()


    const roles = [
        "Admin",
        "Editor",
        "Reviewer",
        "Viewer",
        "Project Manager"
    ];

    const access_type = [
        "Full Access",
        "Custom Permissions"
    ];


    const handleRoleSelect = (role) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            role: role,
        }));
        setShowRoleDropdown(false);
    };

    const handleAccessSelect = (access) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            access: access,
        }));
        setShowAccessDropdown(false);
    };


    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    
        if (name === "email" && value.trim().length > 2) {
            try {
                const response = await fetch(`${apiUrl}/api/search-users?query=${value}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    }
                });
                const data = await response.json();
                setSuggestions(Array.isArray(data.users) ? data.users : []); // Ensure it's always an array
                setShowDropdown(true);
            } catch (error) {
                console.error("Error fetching users:", error);
                setSuggestions([]); // Reset suggestions on error
                setShowDropdown(false);
            }
        } else {
            setSuggestions([]);
            setShowDropdown(false);
        }
    };


    const handleSelectUser = (user) => {
        setFormData({ 
            ...formData, 
            email: user.email, 
            // name: user.name 
        });
        setShowDropdown(false);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emailDropdownRef.current && !emailDropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
            if (roleDropdownRef.current && !roleDropdownRef.current.contains(event.target)) {
                setShowRoleDropdown(false);
            }
            if (accessDropdownRef.current && !accessDropdownRef.current.contains(event.target)) {
                setShowAccessDropdown(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);


    const handleSendInvite = async () => {
        if (loading) return; // Prevent multiple clicks
    
        setLoading(true); // Start loading
    
        const { email, role, access, ...permissions } = formData;
    
        // Check required fields
        if (!email.trim() || !role.trim() || !access.trim()) {
            toast.error(`Email, Role, and Access are required!`, {
                position: "bottom-right"
            });
            setLoading(false);
            return;
        }


        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailPattern.test(email)) {
            toast.error('Please enter a valid email address.', {
                position: "bottom-right"
            });
            setLoading(false);
            return;
        }

        if (!activeProject?.id) {
            toast.error("Project ID is missing!", { position: "bottom-right" });
            setLoading(false);
            return;
        }
    
        // If Full Access, send only necessary fields
        if (access === "Full Access") {
            sendToServer({ email, role, access, projectId: activeProject.id });
            return;
        }
    
        // If Custom Permissions, check all permission fields
        const hasEmptyPermissions = Object.values(permissions).some(value => value.trim() === "");
        if (access === "Custom Permissions" && hasEmptyPermissions) {
            toast.error(`All custom permission fields must be filled!`, {
                position: "bottom-right"
            });
            setLoading(false);
            return;
        }
    
        // Send complete data for Custom Permissions
        sendToServer({ email, role, access, projectId: activeProject.id, ...permissions });
    };
    
    // Function to send data to the server
    const sendToServer = async (payload) => {
        try {
            const response = await fetch(`${apiUrl}/api/add-team-member/`, {
                method: "POST",
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                },
                body: JSON.stringify(payload),
            });
    
            const data = await response.json();
            if (response.ok) {
                alert("Team member added successfully!");
            } else {
                alert(data.message || "Failed to add team member.");
            }
        } catch (error) {
            alert("Error sending data to server.");
        } finally {
            setLoading(false); // Stop loading
        }
    };







        const FlexibleDiv = ({ width, children, bgc }) => {
        return (
            <div
                style={{
                    width: width,
                    border: '0.5px solid #a1a1a1',
                    borderRadius: '10px',
                    backgroundColor: bgc,
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px"
                }}
            >
                {children}
            </div>
        );
    };

    

    



    return (
        <>
            <div id="userpage_container">
                <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader pageName={`Add Team Member`} />
                    <div className="ProjectWorkspaceBody">

                        <div className="file_preview_frame">

                            <div class="add_team_member_section">
                                <div className="row" align='start'>
                                    <p className="text15px">Member Details</p>
                                    <p className="a1a1a112px500">Fill in the details of the team member you want to add</p>
                                </div>
                                <div className="row add_team_member_body">
                                    <div className="col-md-5">
                                        <div className="team_member_form">
                                            <input type="email" className="form-control" placeholder="Enter email address" name="email" value={formData.email} onChange={handleChange} autoComplete="off" />
                                            <label className="">Email</label>
                                            {showDropdown && suggestions.length > 0 && (
                                            <div className="dropdown-menu show" ref={emailDropdownRef}>
                                                {suggestions.map(user => (
                                                    <div 
                                                        key={user.id} 
                                                        className="dropdown-item" 
                                                        onClick={() => handleSelectUser(user)}
                                                    >
                                                        <img src={user.img || "/default-avatar.png"} alt={user.name} style={{ width: "30px", height: "30px", borderRadius: "50%", marginRight: "10px" }} />
                                                        <span>{user.name} ({user.email})</span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        </div>
                                        {/* <div className="team_member_form">
                                            <select className="form-select">
                                                <option value="">Select a Role</option>
                                            </select>
                                            <label className="">Email</label>
                                        </div> */}
                                        <div className="team_member_form">
                                            <input type="text" className="form-control" placeholder="Select Role" name='role'  value={formData.role || ""} readOnly // Prevent typing
                                            onFocus={() => setShowRoleDropdown(true)} />
                                            <label className="">Role</label>
                                            {showRoleDropdown && (
                                                <div className="dropdown-menu show" ref={roleDropdownRef}>
                                                    {roles.map((role, index) => (
                                                        <div 
                                                            key={index} 
                                                            className="dropdown-item role_options" 
                                                            onClick={() => handleRoleSelect(role)}
                                                        >
                                                            {role}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <div className="team_member_form">
                                            <input type="text" className="form-control" placeholder="Full Name" name='name' value={formData.name} onChange={handleChange} disabled/>
                                            <label className="">Name</label>
                                        </div>
                                        <div className="team_member_form">
                                            <input type="text" className="form-control" placeholder="Select Access Type" name='access' value={formData.access || ""} readOnly // Prevent typing
                                            onFocus={() => setShowAccessDropdown(true)}/>
                                            <label className="">Access</label>
                                            {showAccessDropdown && (
                                                <div className="dropdown-menu show" ref={accessDropdownRef}>
                                                    {access_type.map((access, index) => (
                                                        <div 
                                                            key={index} 
                                                            className="dropdown-item role_options" 
                                                            onClick={() => handleAccessSelect(access)}
                                                        >
                                                            {access}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <div className="custom_permission_box_and_invite gap-4">
                                            <label className="custom_permission_box gap-2">
                                            <input type="checkbox" name="setCustomPermission" className="custom-radio" checked={formData.access === "Custom Permissions"} />
                                            <span className="custom_permission_link" onClick={()=>navigate(`/project/workspace/${activeProject.id}/${activeProject.name}/add-team-member/permissions`)}>Set Custom Permissions</span>
                                            </label>
                                            <button className="ru_blackBtn" onClick={handleSendInvite} disabled={loading}>{loading ? "Sending..." : "Send Invite"}</button>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <p className="text15px">Next Steps</p>
                                        <p className="a1a1a112px500">After adding a team member:
                                        </p>
                                        <ul className="mt-2">
                                            <li className="a1a1a112px500">They will receive an email invitation</li>
                                            <li className="a1a1a112px500">You can assign them to specific tasks</li>
                                            <li className="a1a1a112px500">They will get access to project resources</li>
                                            <li className="a1a1a112px500">You can track their progress and contributions</li>
                                        </ul>

                                    </div>

                                </div>
                            </div>





                            <div class="d-flex flex-row justify-content-between   mt-4">
                                <FlexibleDiv width="48%" bgc='#09090b'>
                                    <p className="text16px600">💼 Hire DesignXcel</p>
                                    <p className="a1a1a111px500 mt-2">Let DesignXcel handle everything. Our expert team takes over project management.</p>
                                    <button className="whiteBtn mt-2">Start Hiring Process</button>
                                </FlexibleDiv>
                                <FlexibleDiv width="48%" bgc='#30363D'>
                                    <p className="text16px600">🌍 Talent & Agency Marketplace</p>
                                    <p className="a1a1a111px500 mt-2">Browse and hire top-rated talents or agencies directly.</p>
                                    <button className="whiteBtn mt-2">Access Marketplace</button>
                                </FlexibleDiv>

                            </div>

                        </div>

                    </div>
                    <ProjectWorkspaceFooter />
                </div>
                <ProjectWorkspaceChat projectId={activeProject.id} pageName="Project Team" />
            </div>
        </>

    )

}

export default AddTeamMember