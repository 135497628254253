// ------------------------------------------
import React, { useState } from "react";
import "./ScheduleModal.css";

const ALL_TEAMS = [
  { name: "Project Team", count: 1 },
  { name: "Design Team", count: 1 },
  { name: "Development Team", count: 1 },
  { name: "Support", count: 1 },
];

const SelectTeamModal = ({ onClose, onTeamsChosen, initiallySelected = [] }) => {
  const [searchTerm, setSearchTerm] = useState("");
  // Local copy of which teams are checked
  const [teams, setTeams] = useState(
    ALL_TEAMS.map((team) => ({
      ...team,
      checked: initiallySelected.length
        ? initiallySelected.some((sel) => sel.name === team.name)
        : true
    }))
  );

  const handleCheck = (teamName) => {
    setTeams((prev) =>
      prev.map((t) =>
        t.name === teamName ? { ...t, checked: !t.checked } : t
      )
    );
  };

  const handleContinue = () => {
    // Return only the checked teams
    const chosen = teams.filter((t) => t.checked);
    onTeamsChosen(chosen);
  };

  const filteredTeams = teams.filter((t) =>
    t.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="team-modal-overlay">
      <div className="team-modal-container">
        {/* Close (X) */}
        <button className="close-btn" onClick={onClose}>
          <img src='/static/images/close.svg' alt='angle right icon' />
        </button>

        <h3>Select Team</h3>
        <div className="team-search">
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="team-list">
          {filteredTeams.map((team) => (
            <label key={team.name} className="team-item">
              <div>
                <span>{team.name}</span>
                <small>{team.count} available</small>
              </div>
              <input
                type="checkbox"
                checked={team.checked}
                onChange={() => handleCheck(team.name)}
              />
            </label>
          ))}
        </div>

        <div className="text-right">
          <button className="continue-btn" onClick={handleContinue}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectTeamModal;