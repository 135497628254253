import React, { useContext } from "react";
import DashboardNav from './DashboardNav';
import '../Styles/TeamPaymentsDetails.css'
import Table from "../Components/Table";
import { Link, useNavigate } from "react-router-dom";
import NotificationProfile from "../Components/NotificationProfile";

const TeamPaymentsDetails = () => {
  const navigate = useNavigate();
  // Payment Stats Data
  const stats = [
    { title: "Total Payroll", amount: "₦2,750,000.00", description: "65% processed this month" },
    { title: "Breakdown", amount: "Talent Pay: ₦2,750.00", highlight: "Taxes: ₦2,750.00" },
    { title: "Outstanding", amount: "₦2,750,000.00", description: "Pending payments" },
    { title: "Upcoming", amount: "₦2,750,000.00", description: "Due in the next 7 days" }
  ];

  // Table Headers
  const columns = ["Invoice No", "Period", "Amount", "Status", "Due Date", "Payment Date", ""];

  // Table Rows
  const rows = [
    ["INV-S-008", "Feb 2024", "₦2,750.00", <span className="status-pending">Pending</span>, "2025-02-15", "2025-02-15", <button className="button-text" onClick={() => navigate("/billing/team-payments/invoice-details/1")}>Process</button>],
    ["INV-S-008", "Feb 2024", "₦2,750.00", <span className="status-pending">Pending</span>, "2025-02-15", "Unpaid", <button className="button-text" onClick={() => navigate("/billing/team-payments/invoice-details/2")}>Process</button>]
  ];

  return (
    <>
      <div id="userpage_container">
        <DashboardNav />
        <div className="wrapper card">
          <header className="mb-5">
            <div className="flex mb-3">
            <Link to={'/billing/team-payments'}>
            <button className="button"><img src='/static/images/back.svg' alt="Angle Left" className="me-2" /> Back to Team Payments</button>
            </Link>
              <NotificationProfile />
            </div>
            <div className="flex">
              <p></p>
              <div className="plan text-center">Business</div>
            </div>
          </header>
          <main>
            <div className="card dark ">
              <div className="flex mb-5">
                <div>
                  <h6>Payment Details</h6>
                  <p className="text-white">Review and manage payment information</p>
                </div>
                <div className="d-flex">
                  <img src='/static/images/prev.svg' alt="Angle Left" className="me-2" />
                  <img src='/static/images/next.svg' alt="Angle Right" className="me-2" />
                <button className="button">Feb 2025</button>
                </div>
              </div>

              <div className="flex mb-5">
                <div className="profile-card">
                  <div className="d-flex gap-4">
                    <img src='/static/images/profile.png' alt="Profile"/>
                    <div>
                      <h2>Sarah Wilson</h2>
                      <p className="role">UI/UX Designer</p>
                      <span className="status-active">Active</span>
                    </div>
                  </div>
                  <div className="buttons mt-4 mb-5">
                      <button className="button trans me-3">View Profile</button>
                      <button className="button">Process Payment</button>
                  </div>
                  <div className="d-flex gap-3">
                    <div>
                      <p className="mb-2 text-white">Contract Status</p>
                      <span className="status-pending">Signed</span>
                    </div>
                    <div>
                      <p className="mb-2 text-white">Hired By</p>
                      <span className="status-pending">Designstack</span>
                    </div>
                  </div>
                </div>
                <div className="contract-details">
                  <div className="d-flex gap-95 mb-5">
                    <div>
                      <p className="mb-2">Contract Type:</p>
                      <span className="badge">Full-time</span>
                    </div>
                    <div>
                      <p className="mb-2">Start Date:</p>
                      <h6>Jan 1, 2025</h6>
                    </div>
                  </div>
                  <div className="d-flex gap-95">
                    <div>
                      <p className="mb-2">Duration:</p>
                      <h6>8 years</h6>
                    </div>
                    <div>
                      <p className="mb-2">Contract Value:</p>
                      <h6>₦600,000/year</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-4">
                <div className="payment-info w-25 !text-white">
                  <div className="flex mb-5">
                    <h6>Due Payment</h6>
                    <h4>₦5,000</h4>
                 </div>
                  <p className="text-white mb-3">Payment History</p>
                  <div className="flex mb-2">
                    <h6>Last Payment:</h6>
                    <p className="text-white">₦5,000</p>
                  </div>
                  <div className="flex mb-2">
                    <h6>Reference:</h6>
                    <p className="text-white">₦5,000</p>
                  </div>
                  <div className="flex mb-2">
                    <h6>Last Payment:</h6>
                    <p className="text-white">#PAY-2025011501</p>
                  </div>
                </div>
                <div className=" w-50">

                  <h6>Linked Tasks</h6>
                  <div className="card dark">
                    <div className="task-summary">
                      <div className="flex mb-3">
                        <div>
                          <p className="mb-2">Active Tasks:</p>
                          <h6>2</h6>
                        </div>
                        <div>
                          <p className="mb-2">Completed This Month:</p>
                          <h6>12</h6>
                        </div>
                        <div>
                          <p className="mb-2">Task Completion Rate:</p>
                          <h6>95%</h6>
                        </div>
                      </div>
                  </div>
                  <h6>Current Tasks</h6>
                  <div className="flex my-4">
                    <p>Homepage Redesign</p>
                    <span className="badge">Design</span>
                  </div>
                  <div className="flex mb-4">
                    <p>Homepage Redesign</p>
                    <span className="badge">Design</span>
                  </div>
                </div>
                </div>
              </div>
              <div className="my-5">
                <h6>Recent Invoices</h6>
                <p className="text-white">Review and manage payments</p>
                <Table columns={columns} rows={rows} />
             </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default TeamPaymentsDetails