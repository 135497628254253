import React, { createContext, useState, useContext } from "react";

// Create Context
const NotificationContext = createContext();

// Provider Component
export const NotificationProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([
    { type: "Task", message: "New task 'Homepage Redesign' assigned.", time: "10 min ago" },
    { type: "Contract", message: "John Doe signed the Talent Contract.", time: "10 min ago" },
  ]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <NotificationContext.Provider value={{ isModalOpen, openModal, closeModal, notifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Custom Hook for easy access
export const useNotification = () => useContext(NotificationContext);