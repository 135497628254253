import React from "react";
import { useNavigate } from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer";

const Services =() =>{
    const navigate = useNavigate();


    return(
        <>
        <Nav />
        {/* Services header start */}
        <section className="services_header py-3 mt-4">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Our Services</h1>
                        <p>All your creative and development needs made possible</p> 
                    </div>
                </div>
            </div>
        </section>
        {/* Services header end */}
        {/* Services Body start */}
        <section className="creative_development_section mt-1 py-3">
            <div className="container">
                {/* <div className="row main">
                    <div className="col-12 service_box">
                        <img className="img-fluid" src="/static/images/services_icon.png" alt="design icon"/>
                        <p>Design Services</p>
                        <small>Professional UI/UX design, branding, and visual identity creation</small>
                        <div className="service_what_covered">
                            <small>What's covered?</small>
                            <div></div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>User Interface Design</li>
                                    <li>User Experience Design</li>
                                    <li>Brand Identity</li>
                                    <li>Logo Design</li>
                                    <li>Design Systems</li>
                                    <li>Prototyping</li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>Flyers</li>
                                    <li>Posters</li>
                                    <li>Brochures</li>
                                    <li>Wireframes</li>
                                    <li>Motion Graphics</li>
                                    <li>Animation</li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>Explainer Videos</li>
                                    <li>PoAnimated Adssters</li>
                                    <li>Product Design</li>
                                    <li>Packaging</li>
                                    <li>Product Mockups</li>
                                    <li>Social Media Templates</li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>Social Media Banners</li>
                                    <li>Post Designs</li>
                                    <li>More</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 service_box">
                        <img className="img-fluid" src="/static/images/services_icon.png" alt="development icon"/>
                        <p>Development Services</p>
                        <small>Full-stack development solutions for web and mobile applications</small>
                        <div className="service_what_covered">
                            <small>What's covered?</small>
                            <div></div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>Web Development</li>
                                    <li>Mobile App Development</li>
                                    <li>Frontend Development</li>
                                    <li>Backend Development</li>
                                    <li>AI-Powered Solutions</li>
                                    <li>Cloud Solutions</li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>E-commerce Sites</li>
                                    <li>Landing Pages</li>
                                    <li>Custom Websites</li>
                                    <li>CMS Platforms</li>
                                    <li>Cross-Platform Apps</li>
                                    <li>Native Android/iOS</li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>Hybrid Apps</li>
                                    <li>Automation</li>
                                    <li>Custom Software</li>
                                    <li>CRM</li>
                                    <li>ERP</li>
                                    <li>SaaS Platforms</li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>No-Code/Low-Code</li>
                                    <li>API Integrations</li>
                                    <li>Payment Gateways</li>
                                    <li>Third-Party Services</li>
                                    <li>Custom Integrations</li>
                                    <li>More</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 service_box">
                        <img className="img-fluid" src="/static/images/services_icon.png" alt="printing icon"/>
                        <p>Printing Services</p>
                        <small>On demand printing of marketing materials and products</small>
                        <div className="service_what_covered">
                            <small>What's covered?</small>
                            <div></div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>Business Cards</li>
                                    <li>Flyers</li>
                                    <li>Posters</li>
                                    <li>Brochures</li>
                                    <li>Letterheads</li>
                                    <li>Branded Notebooks</li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>Office Signage</li>
                                    <li>Event Backdrops</li>
                                    <li>Roll-Up Banners</li>
                                    <li>Invitations</li>
                                    <li>T-Shirts</li>
                                    <li>Mugs</li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>Menu Cards</li>
                                    <li>Table Mats</li>
                                    <li>Branded Food Packaging</li>
                                    <li>Branded Stickers</li>
                                    <li>Labels</li>
                                    <li>Delivery Bags</li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>Invitations</li>
                                    <li>Save-the-Date Cards</li>
                                    <li>Event Programs</li>
                                    <li>Tickets</li>
                                    <li>Wristbands</li>
                                    <li>More</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 service_box">
                        <img className="img-fluid" src="/static/images/services_icon.png" alt="recruitment icon"/>
                        <p>Recruitment Services</p>
                        <small>On demand teams to build and scale your startup</small>
                        <div className="service_what_covered">
                            <small>What's covered?</small>
                            <div></div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>Full-Time Employees</li>
                                    <li>Part-Time Employees</li>
                                    <li>Freelancers</li>
                                    <li>Remote Workers</li>
                                    <li>Contract-Based Workers</li>
                                    <li>Interns</li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>C-Level Executives</li>
                                    <li>Developers</li>
                                    <li>Designers</li>
                                    <li>Product Managers</li>
                                    <li>Project Managers</li>
                                    <li>Software Engineers</li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>Technical Co-Founders</li>
                                    <li>FinTech Talent</li>
                                    <li>E-commerce Talent</li>
                                    <li>Product Teams</li>
                                    <li>Global Talent Pool</li>
                                    <li>Temporary Employeess</li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-12">
                                <ul>
                                    <li>Data Analysts and Scientists</li>
                                    <li>DevOps Engineers</li>
                                    <li>AI/ML Specialists</li>
                                    <li>Cloud Engineers</li>
                                    <li>Cybersecurity Experts</li>
                                    <li>More</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row bottom">
                        <div className="col-md-5 service_box flex-grow-1">
                            <img className="img-fluid" src="/static/images/services_icon.png" alt="support icon"/>
                            <p>Support Services</p>
                            <small>Expert assistance when needed</small>
                            <div className="service_what_covered">
                                <small>Features</small>
                                <div></div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <ul>
                                        <li>Technical Support</li>
                                        <li>Design Support</li>
                                        <li>Development Support</li>
                                        <li>Platform and Tool Support</li>
                                        <li>Project Consultation</li>
                                        <li>Product Support</li>
                                    </ul>
                                </div>
                                <div className="col-md-6 col-12">
                                    <ul>
                                        <li>Dedicated Support Team</li>
                                        <li>More</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 service_box flex-grow-1">
                            <img className="img-fluid" src="/static/images/services_icon.png" alt="training icon"/>
                            <p>Training Services</p>
                            <small>Hands-on Training</small>
                            <div className="service_what_covered">
                                <small>Features</small>
                                <div></div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <ul>
                                        <li>One-on-One Training</li>
                                        <li>Group Training</li>
                                        <li>Full Stack</li>
                                        <li>Design</li>
                                        <li>No Code</li>
                                        <li>Product Development</li>
                                    </ul>
                                </div>
                                <div className="col-md-6 col-12">
                                    <ul>
                                        <li>Wordpress</li>
                                        <li>Flutterflow</li>
                                        <li>Figma</li>
                                        <li>AI Integration</li>
                                        <li>Automation</li>
                                        <li>More</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-5">
                        <img className="img-fluid" src="/static/images/expert_icon.png" alt="deployment services icon" />
                        <p>Development Services</p>
                        <div className="creative_tags">
                            <span>MVPs</span>
                            <span>Websites</span>
                            <span>Mobile Apps</span>
                            <span>ERP</span>
                            <span>No Code Development</span>
                            <span>Custom Development</span>
                            <span>SaaS</span>
                            <span>More</span>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <img className="img-fluid" src="/static/images/ondemand_icon.png" alt="design services icon" />
                        <p>Design Services</p>
                        <div className="creative_tags">
                        <span>Logo</span>
                            <span>UI/UX</span>
                            <span>Product Packaging</span>
                            <span>Branding</span>
                            <span>Presentations</span>
                            <span>Prototype</span>
                            <span>Social Media Graphics</span>
                            <span>More</span>
                        </div>
                    </div>    
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <img className="img-fluid" src="/static/images/training_support_icon.png" alt="support logo" />
                        <p>Support</p>
                        <div className="creative_tags">
                            <span>Debugging</span>
                            <span>Trouble Shooting</span>
                            <span>Live Sessions</span>
                            <span>Ongoing Support</span>
                            <span>Consultations</span>
                            <span>Maintenance</span>
                            <span>More</span>
                        </div>
                        
                    </div>
                    <div className="col-md-5">
                        <img className="img-fluid" src="/static/images/training_support_icon.png" alt="training logo" />
                        <p>Training</p>
                        <div className="creative_tags">
                            <span>No-Code</span>
                            <span>Frontend Development</span>
                            <span>Backend Development</span>
                            <span>Full Stack</span>
                            <span>UI/UX</span>
                            <span>Design</span>
                            <span>Artificial Intelligence</span>
                            <span>More</span>
                        </div>
                    </div>    
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <img className="img-fluid" src="/static/images/print_icon.png" alt="recruitment logo" />
                        <p>Recruitment</p>
                        <div className="creative_tags">
                            <span>Single Role Recruitment</span>
                            <span>Team Recruitment</span>
                            <span>Payments</span>
                            <span>Dedicated Resources</span>
                            <span>Shared Resources</span>
                            <span>Management</span>
                            <span>More</span>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <img className="img-fluid" src="/static/images/print_icon.png" alt="on deman logo" />
                        <p>On-Demand Printing</p>
                        <div className="creative_tags">
                            <span>Business Cards</span>
                            <span>Flyers</span>
                            <span>Banners</span>
                            <span>Stickers</span>
                            <span>Custom Packaging</span>
                            <span>Event Materials</span>
                            <span>Branded Apparel</span>
                            <span>More</span>
                        </div>
                    </div>    
                </div>
                <div className="services_cta">
                    <button className="btn btn-lg" data-bs-toggle="modal" data-bs-target="#exampleModal">Request a Service</button>
                    <button className="btn btn-lg" onClick={()=>navigate('/lets-talk')}>Can't see what you need? Let's have a chat</button>
                </div>
            </div>
        </section>
        {/* Services Body end */}
        <Footer />
        </>
    );
}

export default Services