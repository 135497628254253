import React, {useContext, useState, useEffect, useRef} from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useProject } from "./ProjectData";
import { UserContext } from '../UserProvider';
import ProjectWorkspaceNav from './ProjectWorkspaceNav';
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter";
import '../Styles/ProjectWorkspace.css'
const apiUrl = process.env.REACT_APP_API_URL;



const ProjectWorkspace =() =>{
    const { id, name } = useParams();
    const { activeProject, setActiveProject, getProjectById, projects} = useProject();
    const [browserUrl, setBrowserUrl] = useState("No preview links found");
    const navigate = useNavigate();




    

    return(
        <>
        <div id="userpage_container">
            <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader pageName={`Overview`} />
                    <div className="ProjectWorkspaceBody">
                        {/* In-App Browser Section */}
                        <div className="row ProjectWorkspaceBody_addressbar">
                            <div className="col left_addressbar_quick_link">
                                <button className="btn btn-sm"><img src="/static/images/in_app_broswer_back_icon.png" className="img-fluid" alt="address bar back icon" /></button>
                                <button className="btn btn-sm"><img src="/static/images/in_app_browser_forward_icon.png" className="img-fluid" alt="address bar forward icon" /></button>
                                <button className="btn btn-sm"><img src="/static/images/in_app_browser_reload_icon.png" className="img-fluid" alt="address bar refresh icon" /></button>
                            </div>
                            <div className="col addy_bar d-grid">
                                <input type="text" value={browserUrl} readOnly className="address-bar-input" />
                            </div>
                            
                            <div className="col right_addressbar_quick_link">
                                <button className="btn btn-sm"><img src="/static/images/hyperlink_icon.png" className="img-fluid" alt="address bar refresh icon" /></button>
                                <button className="btn btn-sm"><img src="/static/images/source_code_icon.png" className="img-fluid" alt="address bar refresh icon" /></button>
                                <div>
                                    <img src="/static/images/comment_icon.png" className="img-fluid" alt="comment icon" />
                                </div>
                            </div>
                        </div>

                        {/* Iframe to Load External Content */}
                        <div className="preview_frame">
                            <img src="/static/images/ghost_icon.png" className="img-fluid ghost_icon" alt="ghost icon"/>
                            <p>Your work is in progress at the moment.</p>
                            <p>Please check back later.</p>
                        </div>
                    </div>
                    <ProjectWorkspaceFooter />
                </div>
            <ProjectWorkspaceChat projectId={activeProject.id} pageName="Workspace Overview" />
        </div>
        </>

    )

}

export default ProjectWorkspace

