import React, {useState, useEffect, useRef} from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useProject } from "./ProjectData";
import { useAiHelper } from './AiHelper';
import '../Styles/ProjectWorkspaceChat.css'






const ProjectWorkspaceChat = ({ projectId, pageName }) =>{
    const { activeProject } = useProject();
    const {insight, pageMessage, aiChatHistory, fetchAIInsights} = useAiHelper();

    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const navigate = useNavigate();

    // Ref for the chat container
    const chatContainerRef = useRef(null);




    // Scroll to the last message
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: "smooth", // Adds the smooth scrolling effect
          });
        }
    };

    // Scroll to the last message on load or when messages change
    useEffect(() => {
        scrollToBottom();
    }, [aiChatHistory]);


    const handleInputChange = (e) => {
        setInput(e.target.value);
    };
    

    const handleKeyDown = (e) => {
    if (e.key === "Enter") {
        handleSendMessage();
    }
    };



    useEffect(() => {
        fetchAIInsights(projectId, pageName);
    }, [fetchAIInsights, projectId, pageName]);





    const handleSendMessage = async () => {
        // console.log(input)
        // if (!input.trim() || !chatSession) return;

        // // Append user message to chat history immediately
        // setChatHistory((prevHistory) => [...prevHistory, { text: input, type: "user" }]);
        // setInput(""); // Clear the input field

        // try {
        //     setLoading(true);
        //     setError(null);

        //     const response = await fetch(`${apiUrl}/api/chat/response/`, {
        //         method: 'POST',
        //         credentials: 'include', 
        //         headers: {
        //             'Content-Type': 'application/json',
        //             Authorization: `Bearer ${user.token}`,
        //         },
        //         body: JSON.stringify({
        //             session_id: chatSession,
        //             user_response: input,
        //         }),
        //     });

        //     if (!response.ok) {
        //         throw new Error(`HTTP error! status: ${response.status}`);
        //     }

        //     const data = await response.json();

        //     if (data.status === "complete" || data.status === "incomplete") {
        //         setChatHistory((prevHistory) => [
        //             ...prevHistory,
        //             { text: data.message, type: "system" },
        //         ]);
    
        //         if (data.redirect_url) {
        //             navigate(data.redirect_url);
        //         }
        //     }
        // } catch (err) {
        //     setError('Failed to send message. Please try again.');
        //     console.error(err);
        // } finally {
        //     setLoading(false);
        // }
    };


    return(
        <>
        <div className="project_workspace_chat">
            <div className="project_workspace_chat_head">
                <h4>Chat with your assistant</h4>
                <button className="btn"><img src="/static/images/new_call_icon.png" className="img-fluid" alt='call icon'/>Join</button>
                <Link><div><img src="/static/images/mic_icon.png" className="img-fluid" alt='mic icon'/></div>
                </Link>
            </div>
            <div className="project_workspace_chat_body">
                <div className="page_title my-0">
                    <h4>{pageName}</h4>
                    <button className="btn">Setup in progress</button>
                </div>
                <hr/>
                {/* Display chat history */}
                <div ref={chatContainerRef} className="project_workspace_chat_box">
                    <h4 className="page_message">{pageMessage}</h4> {/* Show welcome message */}
                    {insight && <p className="page_insight">{insight}</p>}
                    <div>
                        {aiChatHistory.map((chat, index) => (
                            <div key={index} className={`${
                                chat.type === 'user' ? 'user_message' :
                                chat.type === 'log' ? 'log_message' :
                                'system_message'
                            }`}>
                                <p>{chat.text}</p>
                            </div>
                        ))}
                    </div>
                    <div>
                        {loading && <p>Loading chat...</p>}
                        {error && <p className="error-message">{error}</p>}
                    </div>
                </div>
                <div class="input-group">
                    <textarea class="form-control" value={input} onChange={handleInputChange} onKeyDown={handleKeyDown} placeholder="Create tasks. Enter details"></textarea>
                    <span class="input-group-text">
                        <div className="voice_input"><img src="/static/images/send_message_icon.png" className="img-fluid" alt='send message icon' onClick={handleSendMessage} /></div>
                        <Link to={`/project/workspace/${activeProject.id}/${activeProject.name}/meetings`}><div className="conference_button"><img src="/static/images/dail_icon.png" className="img-fluid" alt='call icon'/></div>
                        </Link>
                    </span>
                </div>
            </div>
            {/* project chatbox end */}
        </div>
        </>
    )

}

export default ProjectWorkspaceChat