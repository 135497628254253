import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from '../UserProvider';
import { useNotification } from "./NotificationContext";
import '../Styles/NotificationModal.css'
const apiUrl = process.env.REACT_APP_API_URL;


const NotificationModal=() =>{
    const { user } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { isModalOpen, closeModal, notifications } = useNotification();
    const modalRef = useRef(null);

    // Close when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeModal();
        }
        };

        if (isModalOpen) {
        document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isModalOpen, closeModal]);

    if (!isModalOpen) return null;

    return (
        <>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <div className="notification-overlay">
                    <div ref={modalRef} className="notification-modal">
                        {/* Header */}
                        <div className="modal-header">
                            <h5 className="modal-title">Notifications</h5>
                            <button className="btn" onClick={closeModal}>
                                <img src='/static/images/close.svg' alt='angle right icon' />
                            </button>
                        </div>

                        {/* Account Overview Section */}
                        <div className='account_overview_container'>
                            <div className='account_overview_header'>
                                <h5>Account Overview</h5>
                                <button>Feb 2025</button>
                            </div>
                            <div className="account-overview">
                                <div className="overview-box">
                                    <h6>Active Projects</h6>
                                    <p>3</p>
                                    <small>65% completed this month</small>
                                </div>
                                <div className="overview-box">
                                    <h6>Completed Tasks</h6>
                                    <p>23</p>
                                </div>
                                <div className="overview-box">
                                    <h6>Pending Tasks</h6>
                                    <p>23</p>
                                </div>
                                <div className="overview-box">
                                    <h6>Pending Reviews</h6>
                                    <p>7</p>
                                </div>
                            </div>
                        </div>

                        {/* Notification Tabs */}
                        <div className='notification_tab_container'>
                            <div className="notification-tabs">
                                <button className="btn active">All Notifications</button>
                                <button className="btn">Tasks</button>
                                <button className="btn">Reviews</button>
                                <button className="btn">Approvals</button>
                                <button className="btn">Payments</button>
                                <button className="btn">Project</button>
                            </div>
                            <button className='btn view_all'>View All</button>
                        </div>

                        {/* Notification List */}
                        <div className="notification-list">
                            {/* <p className="text-muted">No new notifications</p> */}
                            <div className="notification-item">
                                <div className='notification-item-header'>
                                    <p>Task Assignment</p>
                                    <small>New</small>
                                </div>
                                <div className='notification-item-body'>
                                    <div className='left'>
                                        <h6>New Task <strong>'Homepage Design'</strong> has been assigned to you on <strong>'Crestal'</strong></h6>
                                        <small>Due: Mar 01, 2025</small>
                                    </div>
                                    <div className='right'>
                                        <p>10 minutes ago</p>
                                        <small>Task</small>
                                    </div>
                                </div>
                            </div>
                            <div className="notification-item">
                                <div className='notification-item-header'>
                                    <p>Task Assignment</p>
                                    <small>New</small>
                                </div>
                                <div className='notification-item-body'>
                                    <div className='left'>
                                        <h6>New Task <strong>'Homepage Design'</strong> has been assigned to you on <strong>'Crestal'</strong></h6>
                                        <small>Due: Mar 01, 2025</small>
                                    </div>
                                    <div className='right'>
                                        <p>10 minutes ago</p>
                                        <small>Task</small>
                                    </div>
                                </div>
                            </div>
                            <div className="notification-item">
                                <div className='notification-item-header'>
                                    <p>Task Assignment</p>
                                    <small>New</small>
                                </div>
                                <div className='notification-item-body'>
                                    <div className='left'>
                                        <h6>New Task <strong>'Homepage Design'</strong> has been assigned to you on <strong>'Crestal'</strong></h6>
                                        <small>Due: Mar 01, 2025</small>
                                    </div>
                                    <div className='right'>
                                        <p>10 minutes ago</p>
                                        <small>Task</small>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            )}
        </>
    );
}


export default NotificationModal;