import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from '../UserProvider';
import '../Styles/CreateProfile.css'
const apiUrl = process.env.REACT_APP_API_URL;



const CreateProfile=() =>{
    const { user } = useContext(UserContext);
    const [preview, setPreview] = useState(null);
    const [initials, setInitials] = useState(null);
    const [userInfo, setUserInfo] = useState({
        business_name: '',
        industry : '',
        address : '',
        number_of_team_members : '',
    })
    const [email, setEmail] = useState(null);
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${apiUrl}/api/create-profile/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setInitials(data.user_initials);
                setEmail(data.user_email);
            } catch (error) {
                console.error("Error fetching user info", error);
            } finally {
                setIsLoading(false);
            }
        };
    
        if (user.token) {
            fetchUserInfo();
        }
    }, [user.token]);


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // Save the file to the state
            setFile(selectedFile);
    
            // Preview the selected image
            const reader = new FileReader();
            reader.onloadend = () => setPreview(reader.result);
            reader.readAsDataURL(selectedFile);
        }
    };

    const triggerFileInput = () => {
        document.getElementById("fileInput").click();
    };

    const fieldChange =(e) =>{
        setUserInfo({
            ...userInfo, [e.target.name]:e.target.value
        });
    }

    const profileInfoSubmit = async (e) =>{
        e.preventDefault();

        const trimmedProfile = {
            business_name: userInfo.business_name?.trim() || '',
            industry: userInfo.industry?.trim() || '',
            address: userInfo.address?.trim() || '',
            number_of_team_members: userInfo.number_of_team_members?.trim() || '',
        };

        if (!trimmedProfile.business_name || !trimmedProfile.industry || !trimmedProfile.address || !trimmedProfile.number_of_team_members || !file) {
            toast.error('All fields, including profile picture, are required.', {
                position: "bottom-right"
            });
            return;
        }

        const formData = new FormData();
        formData.append("business_name", trimmedProfile.business_name);
        formData.append("industry", trimmedProfile.industry);
        formData.append("address", trimmedProfile.address);
        formData.append("number_of_team_members", trimmedProfile.number_of_team_members);
        formData.append("profile_picture", file); // Append the file

        fetch(`${apiUrl}/api/create-profile/`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
            body: formData, // Send FormData
        })
        .then(async response => {
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'An error occurred');
            }
            return response.json();
        })
        .then(data => {
            toast.success(data.message, {
                position: "bottom-right",
            });
            navigate('/dashboard');
        })
        .catch(error => {
            toast.error(`${error}`, {
                position: "bottom-right"
            });
        });
    }

    return (
        <>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <section className='create_new_profile'>
                    <div className='row d-flex'>
                        <div className='col-lg-5'>
                            <h1>Create your profile</h1>
                            <p>Fill in the following fields to update your profile information for the best user experience.</p>
                            <p>You're creating a business account</p>
                            <div className='business_account_info_box'>
                                <div className='business_account_info'>
                                    <img src='/static/images/create_profile_check_icon.png' className='img-fluid check_icon' alt='check icon'/>
                                    <div>
                                        <h4>Account Type</h4>
                                        <button className='btn btn-sm'>Business</button>
                                    </div>
                                </div>
                                <div className='business_account_info'>
                                    <img src='/static/images/create_profile_check_icon.png' className='img-fluid check_icon' alt='check icon'/>
                                    <div>
                                        <h4>Complete your profile</h4>
                                        <small>Add your business details, logo and team members</small>
                                    </div>
                                </div>
                                <div className='business_account_info'>
                                    <img src='/static/images/create_profile_check_icon.png' className='img-fluid check_icon' alt='check icon'/>
                                    <div>
                                        <h4>Explore AI and DIY Tools & Features</h4>
                                        <small>Access the dashboard, manage projects, DIY or connect with talents or agencies</small>
                                    </div>
                                </div>
                                <div className='business_account_info'>
                                    <img src='/static/images/create_profile_check_icon.png' className='img-fluid check_icon' alt='check icon'/>
                                    <div>
                                        <h4>Post a job or project</h4>
                                        <small>Find the right talent for your business needs</small>
                                    </div>
                                </div>
                                <div className='business_account_info'>
                                    <img src='/static/images/create_profile_check_icon.png' className='img-fluid check_icon' alt='check icon'/>
                                    <div>
                                        <h4>Manage Collaborations</h4>
                                        <small>Assign roles, track progress and request reviews</small>
                                    </div>
                                </div>
                                <p>Next Steps:</p>
                                <small>Start creating projects, posting jobs or hiring talents for your needs.</small>
                                <button className='btn btn-lg'> Switch to Talent/Agency</button>
                            </div>

                        </div>
                        <div className='col-lg-5'>
                            <div className='profile_form'>
                                <div className='profile_img mt-1 mb-3' onClick={triggerFileInput}>
                                    {preview ? (
                                        <img
                                            src={preview}
                                            alt="Profile Picture"
                                            style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "top" }}
                                        />
                                        ) : (
                                        <span>{initials}</span>
                                    )}
                                </div>
                                <p>Upload Your Profile Picture</p>
                                <form onSubmit={profileInfoSubmit}>
                                    <input type="file" id="fileInput" accept="image/*" style={{ display: "none" }} onChange={handleFileChange} />
                                    <div className="field-group mb-4">
                                        <input
                                        type="text"
                                        className="form-control"
                                        id="business_name"
                                        placeholder=""
                                        name='business_name'
                                        value={userInfo.business_name}
                                        onChange={fieldChange}
                                        autoComplete='off'
                                        />
                                        <label htmlFor="business_name">Business Name</label>
                                    </div>
                                    <div className="field-group mb-4">
                                        <input
                                        type="text"
                                        className="form-control"
                                        id="industry"
                                        name='industry'
                                        value={userInfo.industry}
                                        onChange={fieldChange}
                                        placeholder=""
                                        autoComplete='off'
                                        />
                                        <label htmlFor="industry">Industry</label>
                                    </div>
                                    <div class="field-group mb-4">
                                        <textarea class="form-control" placeholder="" id="address" name='address' autoComplete='off' value={userInfo.address}
                                        onChange={fieldChange}></textarea>
                                        <label for="address">Address</label>
                                    </div>
                                    <div className="field-group mb-4">
                                        <input
                                        type="email"
                                        className="form-control"
                                        id="floatingEmail"
                                        name='email'
                                        value={email}
                                        disabled
                                        placeholder=""
                                        />
                                        <label htmlFor="floatingEmail">Email Address</label>
                                    </div>
                                    <div className="field-group mb-4">
                                        <input
                                        type="num"
                                        className="form-control"
                                        id="num_of_members"
                                        name='number_of_team_members'
                                        value={userInfo.number_of_team_members}
                                        onChange={fieldChange}
                                        placeholder=""
                                        autoComplete='off'
                                        />
                                        <label htmlFor="num_of_members">Number of Team Members</label>
                                    </div>
                                    <div align='end'>
                                        <button type='submit' className='btn btn-lg'>FINISH</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </section>
            )}
        </>
    );
}


export default CreateProfile;