import React, { useContext, useState, useEffect, useRef } from "react";
import ProjectWorkspaceNav from "./ProjectWorkspaceNav";
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter";
import { Flex, Progress } from "antd";
import "../Styles/Onboarding.css";

const Onboarding = () => {
    const [chatHistory, setChatHistory] = useState([]);

    // Address Bar for the In-App Browser

    // Ref for the chat container
    const chatContainerRef = useRef(null);

    // Scroll to the last message
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth", // Adds the smooth scrolling effect
            });
        }
    };

    // Scroll to the last message on load or when messages change
    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);

    const FlexibleDiv = ({ width, children, bgc }) => {
        return (
            <div
                style={{
                    width: width,
                    border: "0.5px solid #a1a1a1",
                    borderRadius: "10px",
                    backgroundColor: bgc,
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                }}
            >
                {children}
            </div>
        );
    };
    const Div = ({ children }) => {
        return (
            <div
                style={{
                    display: "flex",
                    width: "47%",
                    height: 20,
                    borderRadius: "6px",
                    border: "solid #a1a1a1 0.5px",
                    alignItems: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                }}
            >
                {children}
            </div>
        );
    };

    return (
        <>
            <div id="userpage_container">
                <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader />
                    <div className="ProjectWorkspaceBody">
                        <div
                            className="file_preview_frame overflow-auto"
                            style={{ overflow: "scroll" }}
                        >
                            <div class="flex mt-4" style={{ gap: "16px" }}>
                                <div className="d-flex justify-content-between ">
                                    <div className="" style={{ width: "49%" }}>
                                        <FlexibleDiv width="100%" bgc="#1e1e1e">
                                            <p className="text14px600 ">Onboarding Progress</p>
                                            <p className="a1a1a112px500 mt-2">
                                                Current status: Team Onboarding in Progress
                                            </p>

                                            <div className="flex mt-4">
                                                <Progress percent={50} showInfo={false} />
                                            </div>
                                            <div className="d-flex gap-4 mt-2 flex-grow-1 w-100">
                                                <div className="ru_circle">
                                                    <img src="/static/images/done.svg" alt="" />
                                                </div>
                                                <div className="flex w-100">
                                                    <div className="d-flex justify-content-between">

                                                        <p className="text12px500">
                                                            Payment Processed
                                                        </p>
                                                        <p className="a1a1a112px500">Feb 11, 2025</p>
                                                    </div>
                                                    <p className="a1a1a112px500 mt-2">Initial payment has been confirmed</p>
                                                </div>
                                            </div>
                                            <div className="d-flex gap-4 mt-4 flex-grow-1 w-100">
                                                <div className="ru_circle">
                                                    <p className="text222121">
                                                        2
                                                    </p>
                                                </div>
                                                <div className="flex w-100">
                                                    <div className="d-flex justify-content-between">

                                                        <p className="text12px500">
                                                            Team Onboarding
                                                        </p>
                                                        <p className="a1a1a112px500">Feb 11, 2025</p>
                                                    </div>
                                                    <p className="a1a1a112px500 mt-2">Team members are being notified and prepared</p>
                                                </div>
                                            </div>
                                            <div className="d-flex gap-4 mt-4 flex-grow-1 w-100">
                                                <div className="ru_circle">
                                                    <p className="text222121">
                                                        3
                                                    </p>
                                                </div>
                                                <div className="flex w-100">
                                                    <div className="d-flex justify-content-between">

                                                        <p className="text12px500">
                                                            Project Setup
                                                        </p>
                                                        <p className="a1a1a112px500">Feb 11, 2025</p>
                                                    </div>
                                                    <p className="a1a1a112px500 mt-2">Setting up project infrastructure and tools</p>
                                                </div>
                                            </div>
                                            <div className="d-flex gap-4 mt-4 flex-grow-1 w-100">
                                                <div className="ru_circle">
                                                    <p className="text222121">
                                                        4
                                                    </p>
                                                </div>
                                                <div className="flex w-100">
                                                    <div className="d-flex justify-content-between">

                                                        <p className="text12px500">
                                                            Kickoff Meeting
                                                        </p>
                                                        <p className="a1a1a112px500">Feb 11, 2025</p>
                                                    </div>
                                                    <p className="a1a1a112px500 mt-2">Initial team meeting and project planning</p>
                                                </div>
                                            </div>
                                        </FlexibleDiv>
                                    </div>
                                    <div
                                        style={{
                                            width: "49%",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "16px",
                                        }}
                                    >
                                        <FlexibleDiv width="100%" bgc="#1e1e1e">
                                            <p className="text14px600">Next Steps</p>
                                            <div className="gap-4 mt-4">
                                                <p className="text12px500">Team Introduction</p>

                                                <p className="a1a1a112px500 mt-2">
                                                    You'll receive an email introduction to your team
                                                    members within the next 24 hours.
                                                </p>
                                            </div>
                                            <div className="gap-4 mt-4">
                                                <p className="text12px500">Project Setup</p>

                                                <p className="a1a1a112px500 mt-2">
                                                    Our team is preparing your project workspace and
                                                    setting up necessary tools.
                                                </p>
                                            </div>
                                            <div className="gap-4 mt-4">
                                                <p className="text12px500">Kickoff Meeting</p>

                                                <p className="a1a1a112px500 mt-2">
                                                    Schedule your kickoff meeting with the team to align
                                                    on project goals and timeline.
                                                </p>
                                            </div>
                                        </FlexibleDiv>
                                        <FlexibleDiv width="100%" bgc="#1e1e1e">
                                            <p className="text14px600">Need Help?</p>
                                            <p className="a1a1a112px500 mt-4">
                                                If you have any questions or concerns during the
                                                onboarding process, our support team is here to help.
                                            </p>

                                            <button className="mt-4 ru_whiteBtn">
                                                Contact Support
                                            </button>
                                        </FlexibleDiv>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProjectWorkspaceFooter />
                </div>
                <ProjectWorkspaceChat />
            </div>
        </>
    );
};

export default Onboarding;
