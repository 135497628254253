import React from "react";
import "./Modal.css";

const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content card modal_content_box" onClick={(e) => e.stopPropagation()}>
                <h6>{title}</h6>
                <button className="modal-close" onClick={onClose}>
                    <img src='/static/images/close.svg' alt='Close' />
                </button>
                <div className="modal-body">{children}</div>
            </div>
        </div>
    );
};

export default Modal;
