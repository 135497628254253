import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserProvider";
import ProjectWorkspaceNav from "./ProjectWorkspaceNav";
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import { SidebarContext } from "./Sidebar";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter";
import "../Styles/TeamSelection.css";
const apiUrl = process.env.REACT_APP_API_URL;

const TeamSelection = () => {
    const [chatSession, setChatSession] = useState(null);
    const { user } = useContext(UserContext);
    const [input, setInput] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    // Address Bar for the In-App Browser

    const navigate = useNavigate();

    // Ref for the chat container
    const chatContainerRef = useRef(null);

    // Scroll to the last message
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth", // Adds the smooth scrolling effect
            });
        }
    };

    // Scroll to the last message on load or when messages change
    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);

    // useEffect(() => {
    //     const fetchInitialPrompt = async () => {
    //         try {
    //             setLoading(true);
    //             setError(null);
    //             const response = await fetch(`${apiUrl}/api/chat/start/`, {
    //                 method: 'GET',
    //                 credentials: 'include',
    //                 headers: {
    //                     Authorization: `Bearer ${user.token}`,
    //                 },
    //             });

    //             if (!response.ok) {
    //                 throw new Error(`HTTP error! status: ${response.status}`);
    //             }

    //             const data = await response.json();

    //             console.log(data)
    //             // Update state with the fetched data
    //             if (data.status === "new_session") {
    //                 setChatSession(data.session_id);
    //                 setChatHistory([{ text: data.message, type: "system" }]);
    //             } else if (data.status === "existing_session") {
    //                 setChatSession(data.session_id);
    //                 setChatHistory(data.messages.map((msg) => ({
    //                     text: msg.text,
    //                     type: msg.type,
    //                 })));
    //             }
    //         } catch (err) {
    //             // Handle errors
    //             setError('Failed to load chat session. Please try again.');
    //             console.error(err);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchInitialPrompt();
    // }, [user]);


    const handleSendMessage = async () => {
        if (!input.trim() || !chatSession) return;

        // Append user message to chat history immediately
        setChatHistory((prevHistory) => [
            ...prevHistory,
            { text: input, type: "user" },
        ]);
        setInput(""); // Clear the input field

        try {
            setLoading(true);
            setError(null);

            const response = await fetch(`${apiUrl}/api/chat/response/`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                },
                body: JSON.stringify({
                    session_id: chatSession,
                    user_response: input,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.status === "complete" || data.status === "incomplete") {
                setChatHistory((prevHistory) => [
                    ...prevHistory,
                    { text: data.message, type: "system" },
                ]);

                if (data.redirect_url) {
                    navigate(data.redirect_url);
                }
            }
        } catch (err) {
            setError("Failed to send message. Please try again.");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    const FlexibleDiv = ({ width, children, bgc }) => {
        return (
            <div
                style={{
                    width: width,
                    border: "0.5px solid #a1a1a1",
                    borderRadius: "10px",
                    backgroundColor: bgc,
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                }}
            >
                {children}
            </div>
        );
    };


    const ProfileIndicator = () => {
        return (
            <div className="position-relative d-inline-block">
                <div
                    className="bg-white border border-secondary rounded-circle"
                    style={{
                        width: "89px",
                        height: "89px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {/* <img
                        src="https://via.placeholder.com/50"
                        alt="User"
                        className="rounded-circle w-20 h-20"
                    /> */}
                </div>

                <div
                    className="position-absolute bg-success rounded-circle border border-white"
                    style={{
                        width: "32px",
                        height: "32px",
                        bottom: "8px",
                        right: "4px",
                    }}
                ></div>
            </div>
        );
    };

    return (
        <>
            <div id="userpage_container">
                <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader />
                    <div className="ProjectWorkspaceBody">
                        <div className="file_preview_frame">
                            <p className="a1a1a112px500 mt-2 w-50">Choose from the suggested team existing team members or get recommended professionals</p>

                            <div class="d-flex justify-content-between mt-4">

                                <FlexibleDiv width="49%" bgc="#09090b">
                                    <div className="d-flex justify-content-between">
                                        <div className="w-50">
                                            <p className="text16px600 ">Unassigned

                                            </p>
                                            <p className="text14px700 mt-2">Role</p>
                                            <p className="a1a1a112px500 mt-2">UI/UX Designer</p>
                                            <p className="text14px700 mt-4">Rate</p>
                                            <p className="a1a1a112px500 mt-2">N1,000,000/month</p>


                                        </div>
                                        <div className="w-50">
                                            <p className="text14px700">Select for project</p>
                                            <p className="text14px700 mt-2">Experience</p>
                                            <p className="a1a1a112px500 mt-2">UI/UX Designer</p>
                                            <p className="text14px700 mt-4">Skills</p>
                                            <div className="mt-2">

                                                <button className="ru_whiteBtn">Figma</button>

                                            </div>


                                        </div>
                                    </div>


                                </FlexibleDiv>
                                <FlexibleDiv width="49%" bgc="#09090b">
                                    <div className="d-flex justify-content-between">
                                        <div className="w-50">
                                            <p className="text16px600 ">Unassigned

                                            </p>
                                            <p className="text14px700 mt-2">Role</p>
                                            <p className="a1a1a112px500 mt-2">UI/UX Designer</p>
                                            <p className="text14px700 mt-4">Rate</p>
                                            <p className="a1a1a112px500 mt-2">N1,000,000/month</p>


                                        </div>
                                        <div className="w-50">
                                            <p className="text14px700">Select for project</p>
                                            <p className="text14px700 mt-2">Experience</p>
                                            <p className="a1a1a112px500 mt-2">UI/UX Designer</p>
                                            <p className="text14px700 mt-4">Skills</p>
                                            <div className="mt-2">

                                                <button className="ru_whiteBtn">Figma</button>

                                            </div>


                                        </div>
                                    </div>


                                </FlexibleDiv>
                            </div>




                        </div>
                    </div>
                    <ProjectWorkspaceFooter />
                </div>
                <ProjectWorkspaceChat />
            </div>
        </>
    );
};

export default TeamSelection;
