import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormTokenContext } from './FormTokenProvider';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Styles/ForgotPassword.css'
const apiUrl = process.env.REACT_APP_API_URL;


const ForgotPassword =() =>{
    const {formToken} = useContext(FormTokenContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState("");


    // Check if email is not empty
    const isButtonEnabled = email.trim() !== "";


    const submitEmail =(e) =>{
            e.preventDefault();
    
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
            if (!email) {
                toast.error('Email is required.', {
                    position: "bottom-right"
                });
                return;
            }
    
            if (!emailPattern.test(email)) {
                toast.error('Please enter a valid email address.', {
                    position: "bottom-right"
                });
                return;
            }
    
            fetch(`${apiUrl}/api/request-password-reset/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': formToken, 
                },
                body: JSON.stringify({"email" : email}),
            })
            .then(async response => {
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'An error occurred');
                }
                return response.json();
            })
            .then(data => {
                toast.success(data.message, {
                    position: "bottom-right"
                });
                navigate('/verify-password-link', { state: { email: email } })
                
            })
            .catch(error => {
                toast.error(`${error}`, {
                    position: "bottom-right"
                });
            });
        }



    return (
        <>
        {/* Forgot Password Section Start */}
        <section className='forgot_password_section'>
            <div className='row'>
                <div className='col-md-7 col-lg-4'>
                    <h1>Forgot Password</h1>
                    <p>Enter your registered email address to recover your account</p>
                    <form onSubmit={submitEmail}>
                        <div className="field-group mt-3 mb-3">
                            <input
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder=""
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            />
                            <label htmlFor="floatingInput">Email Address</label>
                            <img src='/static/images/email_icon.png' className='img-fluid email_icon' alt='email icon' />
                        </div>
                        <div className='d-grid mt-2'>
                            <button type='submit' className={`btn btn-lg ${isButtonEnabled ? "active-btn" : "disabled-btn"}`} disabled={!isButtonEnabled}>Continue</button>
                        </div>
                    </form>
                    <p>Don’t yet have an account? <Link className='account_link' to='/register'>Create an account</Link></p>
                    <div align='center'>
                         <Link to="/"><img src='/static/images/designxcel_logo.png' className='img-fluid' alt='Designxcel Logo'/></Link>
                    </div>
                </div>
            </div>
        </section>
        {/* Forgot Password Section End */}
        </>
    )
}


export default ForgotPassword