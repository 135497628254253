import React, {useState, createContext, useEffect } from "react";
const apiUrl = process.env.REACT_APP_API_URL;



export const FormTokenContext = createContext();

export const FormTokenProvider = ({ children }) => {
    const [formToken, setFormToken] = useState(sessionStorage.getItem("formToken") || "");

    useEffect(() => {
        // Fetch only if there's no token in sessionStorage
        if (!sessionStorage.getItem("formToken")) {
            const fetchToken = async () => {
                try {
                    const response = await fetch(`${apiUrl}/api/get-form-token`, {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! ${response.status}`);
                    }

                    const data = await response.json();
                    setFormToken(data.formToken);
                    sessionStorage.setItem("formToken", data.formToken); // Store in sessionStorage

                } catch (err) {
                    console.error("Error fetching form token:", err);
                }
            };

            fetchToken();
        }

        return () => {
            // Cleanup on unmount
            sessionStorage.removeItem("formToken");
            setFormToken("");
        };
    }, []); // Empty dependency array ensures it runs only once

    return (
        <FormTokenContext.Provider value={{ formToken }}>
            {children}
        </FormTokenContext.Provider>
    );
};