import React, { useContext, useState, useEffect, useRef } from "react";
import ProjectWorkspaceNav from "./ProjectWorkspaceNav";
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import { useProject } from "./ProjectData";
import TeamMemberContext from "./TeamMemberContext";
import { FaAngleDown } from "react-icons/fa6";
import "../Styles/AddTeamPermissions.css";

const AddTeamSuccess = () => {
    const { activeProject } = useProject();
    




    return (
        <>
            <div id="userpage_container">

                <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader pageName={`Back to Project Team`} />
                    <div className="ProjectWorkspaceBody">
                        <div className="team_permission_frame">
                            <div className="ru_2f2f2f_div">
                                <p className="text16px600">Team Member Invitation – Seat Cost Notification</p>
                                <p className="text12px400 mt-2">💡 Adding a new team member may require an additional seat purchase.</p>
                                <ul className="mt-4 d-flex flex-column gap-2">
                                    <li className="text12px400">Plan Limit: Your current plan allows 5 seats, and you have 4 seats occupied.</li>
                                    <li className="text12px400">New Member Role: Project Manager</li>
                                    <li className="text12px400">Additional Seat Cost: N20/month (Billed Annually: N240/year)</li>
                                    <li className="text12px400">Billing Confirmation: This amount will be added to your next billing cycle.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <ProjectWorkspaceChat projectId={activeProject.id} pageName="Project Team" />
            </div>
        </>
    );
};

export default AddTeamSuccess;
