import React, { useContext, useState } from "react";
import DashboardNav from './DashboardNav';
import '../Styles/InvoiceDetails.css'
import Table from "../Components/Table";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../Components/Billing/Modal/Modal";
import NotificationProfile from "../Components/NotificationProfile";

const paymentOptions = ["Regular Payment", "Bonus Payment", "Installment Payment"];

const InvoiceDetails = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
   const [amount, setAmount] = useState("");
   const [paymentType, setPaymentType] = useState("");

  const handleNext = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % paymentOptions.length);
  };

  const handlePrevious = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === 0 ? paymentOptions.length - 1 : prevIndex - 1
    );
  };
  const goBack = () => {
    window.history.back();
  }
  // Table Headers
  const columns = ["Description", "Type", "Amount", "Status", "No", "Use", "Notes"];

  // Table Rows
  const rows = [
    ["Feb Talent Pay", "Monthly", "₦2.075,000.00", <span className="status-pending">Pending</span>, "1", "User Research Software Subscriptions", <button className="button-text">Process</button>],
    ["Figma Subscription", "Monthly", "₦2,750.00", <span className="status-pending">Pending</span>, "1", "User Research Software Subscriptions", <button className="button-text">Process</button>]
  ];

  const [tasks, setTasks] = useState([
    { id: 1, name: 'Homepage Redesign', team: 'Design', progress: 40 },
    { id: 2, name: 'Design Implementation & Documentation', team: 'Design', progress: 40 },
  ]);
  return (
    <>
      <div id="userpage_container">
        <DashboardNav />
        <div className="wrapper card">
          <header className="mb-5">
            <div className="flex mb-3">
            <Link to={'/billing/team-payments'}>
              <button className="button" onClick={goBack}><img src='/static/images/back.svg' alt="Angle Left" className="me-2" /> Back</button>
            </Link>
              <NotificationProfile />
            </div>
            <div className="flex">
              <p></p>
              <div className="plan text-center">Business</div>
            </div>
          </header>
          <main>
            <div className="card dark ">
              <div className="flex mb-5">
                <div>
                  <h4 className="mb-5">Invoice #INV-2024-002</h4>
                  <div className="d-flex gap-79">
                    <div>
                      <span>Issue Date:</span>
                      <p className="text-white mt-2">January 31, 2024</p>
                    </div>
                    <div>
                      <span>Due Date:</span>
                      <p className="text-white mt-2">January 31, 2024</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <span className="align-self-end">Paid</span>
                  <h4 className="my-3 align-self-end">₦5,000</h4>
                  <button className="button" onClick={() => setModalOpen(true)}>Process Payment</button>
                </div>
              </div>
              {/* Payment Modal */}
              <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} title="Process Payment - Sarah Johnson">
                {/* Payment Type */}
                <div className="meeting-hint-container mt-5 mb-3">
                  <label className="meeting-label ">Payment Type</label>
                  <input
                    className="meeting-input"
                    required
                    type="text"
                    value={paymentOptions[selectedIndex]} readOnly
                    onChange={(e) => setPaymentType(e.target.value)}
                  />
                  <div className="d-flex flex-column">
                    <button className="arrow-button up" onClick={handlePrevious}><img src='/static/images/angle-up.svg' alt="Angle" /></button>
                    <button className="arrow-button down" onClick={handleNext}><img src='/static/images/angle-down.svg' alt="Angle" /></button>
                  </div>
                </div>

                {/* Amount Field */}
                <div className="meeting-hint-container mb-4">
                  <label className="meeting-label ">Amount (₦)</label>
                  <input
                    className="meeting-input"
                    required
                    type="text"
                    placeholder="₦5000"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>

                {/* Continue Button */}
                <div className="text-end mb-3">
                  <button className="button continue-btn">Continue</button>
                </div>
              </Modal>
              <div className="flex mb-5">
                <div>
                  <p className="mb-3">From</p>
                  <h6>Designstack Africa</h6>
                  <p className="mb-2">contact@designstack.io</p>
                </div>
                <div>
                  <p className="mb-3">To</p>
                  <h6>Tech Innovations Corp</h6>
                  <p className="mb-2">accounts@techinnovations.com</p>
                  <p className="mb-2">456 Innovation Park, Silicon Valley, CA 94025</p>
                </div>
              </div>
              <div className="flex mt-4">
                <div className="payment-info w-25 !text-white">
                  <p>Talent(s)</p>
                  <div className="d-flex gap-4 mt-4 mb-3">
                    <img src='/static/images/profile.svg' alt="Profile" className="align-self-baseline"/>
                    <div>
                      <h6>Sarah Wilson</h6>
                      <p>UI/UX Designer</p>
                      <span className="status-active">Active</span>
                    </div>
                    <span className="badge">Full-time</span>
                  </div>
                  <button className="button trans">View Profile</button>
                </div>
                <div className=" w-50">

                  <div className="card dark">
                  <h6>Tasks</h6>
                    <div className="task-summary">
                      <div className="flex mb-3">
                        <div>
                          <p className="mb-2">Active Tasks:</p>
                          <h6>2</h6>
                        </div>
                        <div>
                          <p className="mb-2">Completed This Period:</p>
                          <h6>12</h6>
                        </div>
                        <div>
                          <p className="mb-2">Task Completion Rate:</p>
                          <h6>95%</h6>
                        </div>
                      </div>
                  </div>
                  <h6>All Tasks</h6>
                    {tasks.map((task) => (
                      <div key={task.id} className="flex mb-3">
                        <div className="col-md-4">
                          <p>{task.name}</p>
                          </div>
                        <div className="col-md-4">
                          <span
                            className="badge"
                          >
                            {task.team}
                          </span>
                         </div>
                        <div className="pro">
                          <div>
                            {task.progress}%
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar bg-white"
                              role="progressbar"
                              style={{ width: `${task.progress}%` }}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >

                            </div>
                          </div>
                       </div>
                      </div>
                    ))}
                </div>
                </div>
              </div>
              <div className="my-5">
                <h6>Recent Invoices</h6>
                <p className="text-white">Review and manage payments</p>
                <Table columns={columns} rows={rows} />
             </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default InvoiceDetails