import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormTokenProvider } from "./FormTokenProvider";
import UserProvider from './UserProvider';
import ProtectedRoute from './ProtectedRoute';
import Home from './Home';
import Services from './Services';
import Pricing from './Pricing';
import Login from './Login';
import RequestForm from "./RequestForm";
import Register from "./Register";
import GoogleCallback from './GoogleCallback';
import VerifyEmail from "./VerifyEmail";
import { SidebarProvider } from "./UserContext/Sidebar";
import { NotificationProvider } from "./UserContext/NotificationContext";
import { ProjectProvider } from "./UserContext/ProjectData";
import { UserDataProvider } from "./UserContext/UserData";
import { AiProvider } from "./UserContext/AiHelper";
import { TeamMemberProvider } from "./UserContext/TeamMemberContext";
import Dashboard from "./UserContext/Dashboard";
import Projects from './UserContext/Projects';
import NewProject from "./UserContext/NewProject";
import ForgotPassword from "./ForgotPassword";
import CreateNewPassword from "./CreateNewPassword";
import PasswordResetLink from "./PassResetLinkPage";
import PasswordUpdated from "./PasswordUpdated";
import CreateProfile from "./UserContext/CreateProfile";
import ProjectWorkspace from "./UserContext/ProjectWorkspace";
import TalentRegister from "./TalentRegister";
import TalentCreateProfile from "./TalentContext/TalentCreateProfile";
import TalentSkillsFormA from "./TalentContext/TalentSkillsFormA";
import AddNewTask from "./UserContext/AddNewTask";
import FilesAndResources from "./UserContext/FilesAndResources";
import ProjectBriefAndScope from "./UserContext/ProjectBriefAndScope";
import ReviewAndApproval from "./UserContext/ReviewAndApproval";
import MobilePayment from "./UserContext/MobilePayment";
import SubmitReview from "./UserContext/SubmitReview"; import Plans from "./UserContext/Plans";
import Meetings from "./UserContext/Meetings";
import Billing from "./UserContext/Billing";
import TeamPayments from "./UserContext/TeamPayments";
import TeamPaymentsDetails from "./UserContext/TeamPaymentDetails";
import InvoiceDetails from "./UserContext/InvoiceDetails";
import TeamSeats from "./UserContext/TeamSeats";
import ProjectBrief from "./UserContext/ProjectBrief";
import ProjectTeam from "./UserContext/ProjectTeam";
import TeamProfile from "./UserContext/TeamProfile";
import AddTeamMember from "./UserContext/AddTeamMember";
import TeamSelection from "./UserContext/TeamSelection";
import Hire from "./UserContext/Hire";
import Tasks from "./UserContext/Tasks";
import ContractAndPayment from "./UserContext/ContractAndPayment";
import TaskDetails from "./UserContext/TaskDetails";
import ReviewTeamAndCost from "./UserContext/Onboarding";
import Onboarding from "./UserContext/Onboarding";
import AddTask from "./UserContext/AddTask";
import AddTeamPermission from "./UserContext/AddTeamPermissions";
import LetsTalk from "./LetsTalk";
import LiveChat from "./LiveChat";
import Requests from "./Requests";
import CustomQuote from "./CustomQuote";
import AddTeamSuccess from "./UserContext/AddTeamSuccess";



const App = () => {
  return (
    <>
    <Router>
    <UserProvider>
    <UserDataProvider>
    <AiProvider>
    <SidebarProvider>
    <FormTokenProvider>
    <NotificationProvider>
    <ProjectProvider>
    <TeamMemberProvider>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/services' element={<Services />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/lets-talk' element={<LetsTalk />} />
        <Route path='/live-chat' element={<LiveChat/>} />
        <Route path='/custom-quote' element={<CustomQuote/>} />
        <Route path='/requests' element={<Requests/>} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/talent/register' element={<TalentRegister />} />
        <Route path='/talent/create-profile' element={<TalentCreateProfile />} />
        <Route path='/talent/skills-and-Experience' element={<TalentSkillsFormA />} />
        <Route path="/google-callback" element={<GoogleCallback />} />
        <Route path='/verify-email' element={<VerifyEmail />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/create-new-password' element={<CreateNewPassword />} />
        <Route path='/verify-password-link' element={<PasswordResetLink />} />
        <Route path='/password-updated' element={<PasswordUpdated />} />
        <Route path='/create-profile' element={<ProtectedRoute component={CreateProfile} />}/>
        <Route path='/dashboard' element={<ProtectedRoute component={Dashboard} />} />
        <Route path='/project/all-projects' element={<ProtectedRoute component={Projects} />} />
        <Route path='/project/create-project' element={<ProtectedRoute component={NewProject } />} />
        <Route path='/project/workspace/:id/:name' element={<ProtectedRoute component={ProjectWorkspace} />} />
        <Route path='/project/workspace/:id/:name/add-new-task' element={<ProtectedRoute component={AddNewTask} />}/>
        <Route path='/project/workspace/:id/:name/files-and-resources' element={<ProtectedRoute component={FilesAndResources} />} />
        <Route path='/project/project-brief-and-scope' element={<ProtectedRoute component={ProjectBriefAndScope} />} />
        <Route path='/billing' element={<ProtectedRoute component={Billing} />} />
        <Route path='/billing/plans' element={<ProtectedRoute component={Plans} />} />
        <Route path='/billing/team-payments' element={<ProtectedRoute component={TeamPayments} />} />
        <Route path='/billing/team-payment-details'  element={<ProtectedRoute component={TeamPaymentsDetails} />} />
        <Route path='/billing/team-payments/invoice-details' element={<ProtectedRoute component={InvoiceDetails} />} />
        <Route path='/billing/team-seats' element={<ProtectedRoute component={TeamSeats} />} />
        <Route path='/project/workspace/:id/:name/meetings'  element={<ProtectedRoute component={Meetings} />} />
        <Route path='/project/workspace/:id/:name/brief' element={<ProtectedRoute component={ProjectBrief} />} />
        <Route path='/project/workspace/:id/:name/project-team' element={<ProtectedRoute component={ProjectTeam} />} />
        <Route path='/project/workspace/:id/:name/team-profile' element={<ProtectedRoute component={TeamProfile} />}/>
        <Route path='/project/workspace/:id/:name/team-selection' element={<ProtectedRoute component={TeamSelection} />} />
        <Route path='/project/workspace/:id/:name/hire' element={<ProtectedRoute component={Hire} />} />
        <Route path='/project/workspace/:id/:name/tasks' element={<ProtectedRoute component={Tasks} />} />
        <Route path='/project/workspace/:id/:name/contract-and-payment' element={<ProtectedRoute component={ContractAndPayment} />} />
        <Route path='/project/workspace/:id/:name/task-details' element={<ProtectedRoute component={TaskDetails} />} />
        <Route path='/project/workspace/:id/:name/review-team-and-cost' element={<ProtectedRoute component={ReviewTeamAndCost} />} />
        <Route path='/project/workspace/:id/:name/onboarding' element={<ProtectedRoute component={Onboarding} />} />
        <Route path='/project/workspace/:id/:name/add-task' element={<ProtectedRoute component={AddTask} />} />
        <Route path='/project/workspace/:id/:name/add-team-member' element={<ProtectedRoute component={AddTeamMember} />} />
        <Route path='/project/workspace/:id/:name/add-team-member/permissions' element={<ProtectedRoute component={AddTeamPermission} />} />
        <Route path='/project/workspace/:id/:name/team-member-added-suucessfully' element={<ProtectedRoute component={AddTeamSuccess} />} />
        <Route path='/project/workspace/:id/:name/reviewandapproval' element={<ProtectedRoute component={ReviewAndApproval} />} />
        <Route path='project/mobilepayment' element={<ProtectedRoute component={MobilePayment} />} />
        <Route path='project/submitreview' element={<ProtectedRoute component={SubmitReview} />}/>
      </Routes>
      <RequestForm />
    </TeamMemberProvider>
    </ProjectProvider>
    </NotificationProvider>
    </FormTokenProvider>
    </SidebarProvider>
    </AiProvider>
    </UserDataProvider>
    </UserProvider>
    <ToastContainer />
    </Router>
    </>
  )
}



export default App;
