// ------------------------------------------
import React, { useState } from "react";
import SelectTeamModal from "./SelectTeamModal";
import "./ScheduleModal.css";
import CalendarTimePicker from "./CalendarTimePicker/CalendarTimePicker";

// Define ALL_TEAMS here for comparison.
const ALL_TEAMS = [
  { name: "Project Team", count: 1 },
  { name: "Design Team", count: 1 },
  { name: "Development Team", count: 1 },
  { name: "Support", count: 1 },
];
const ScheduleModal = ({ onClose }) => {
  // 1) Meeting Hint
  const [meetingHint, setMeetingHint] = useState("");

  // 2) Select Date & Time
  const [selectedDateTime, setSelectedDateTime] = useState('');

  // This function is called by the child whenever a new date/time is picked
  const handleDateTimeChange = (formattedDateTime) => {
    setSelectedDateTime(formattedDateTime);
  };


  // 3) Select Team
  // entireTeam = true => user is inviting everyone
  // entireTeam = false => user picks specific teams
  const [entireTeam, setEntireTeam] = useState(true);
  // Track which teams are selected if not entire team
  const [selectedTeams, setSelectedTeams] = useState([]);

  // Control the sub-modal for team selection
  const [showSelectTeamModal, setShowSelectTeamModal] = useState(false);

  // 4) Optional Meeting Link
  // Only shows after date/time/team are all chosen
  const [meetingLink, setMeetingLink] = useState("");

  const handleEntireTeamToggle = (checked) => {
    setEntireTeam(checked);
    if (checked) {
      // If user toggles entire team ON,
      // we can clear any specifically selected teams
      setSelectedTeams([]);
    } else {
      // If user toggles entire team OFF,
      // open sub-modal to let them pick teams
      setShowSelectTeamModal(true);
    }
  };

  const handleSelectTeam = () => {
    // If user manually wants to pick teams,
    // open the sub-modal
    setEntireTeam(false);
    setShowSelectTeamModal(true);
  };

  const handleTeamsChosen = (teams) => {
    // Callback from sub-modal
    setSelectedTeams(teams);
    setShowSelectTeamModal(false);
  };

  // Condition to show the meeting link field:
  // date, time, and "team chosen" must be set
  // "team chosen" means entireTeam === true OR selectedTeams.length > 0
  const isTeamChosen = entireTeam || selectedTeams.length > 0;
  const canShowLink = selectedDateTime;

  const handleSchedule = () => {
    // Combine all info here
    const payload = {
      meetingHint,
      entireTeam,
      selectedTeams,
      meetingLink,
    };
    console.log("Scheduling meeting with data:", payload);

    // You can do further validation or an API call here
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className={`modal-container ${showSelectTeamModal ? 'hidden' : ''}`}>
        {/* Close (X) */}
        <button className="close-btn" onClick={onClose}>
          <img src='/static/images/close.svg' alt='angle right icon' />
        </button>

        <div className="header">
          <div>
            {/* Title & Subtitle */}
            <h4>Schedule a Call</h4>
            <p className="text">Book a call with your team</p>
          </div>
          {/* Show the meeting link field only after date/time/team are chosen */}
          {canShowLink && (
            <div className="meeting-link-container">
              <label className="meeting-label">Meeting link (optional)</label>
              <input
                type="text"
                className="meeting-input"
                placeholder="Add an external link: Google Meet, Teams or Zoom"
                value={meetingLink}
                onChange={(e) => setMeetingLink(e.target.value)}
              />
            </div>

          )}
        </div>

        {/* "Entire Team" toggle + possibly a button to select partial teams */}
        <div className="top-row mt-3">
          <div className="select-day-time">
            <p className="text">With</p>
          </div>
          <div className="entire-team-toggle">
            <button
              className="select-team-btn"
              onClick={handleSelectTeam}
            >
              {selectedTeams.length === ALL_TEAMS.length
                ? "Entire Team"
                : selectedTeams.length > 0
                  ? selectedTeams.map((team) => team.name).join(", ")
                  : "Entire Team"}
              <img src='/static/images/gt.svg' alt='angle right icon' />
            </button>
          </div>
        </div>
        <h6>Select a Day</h6>
        {/* Main Body: Calendar + Times */}
        <CalendarTimePicker onDateTimeChange={handleDateTimeChange} />

        {/* Footer with chosen date/time and Schedule button */}
        <div className="schedule-footer">
          <div style={{ width: '100%' }}>
            <div className="meeting-hint-container">
              <label className="meeting-label ">Meeting hint</label>
              <input
                className="meeting-input"
                required
                type="text"
                placeholder="Name this meeting or the purpose"
                value={meetingHint}
                onChange={(e) => setMeetingHint(e.target.value)}
              />
            </div>
            <p className="mt-2">{meetingHint}</p>
            <p>{selectedDateTime}</p>
          </div>
          <button className="schedule-confirm-btn" onClick={handleSchedule}>
            SCHEDULE
          </button>
        </div>
      </div>

      {/* Sub-modal for selecting teams (if entireTeam == false) */}
      {showSelectTeamModal && (
        <SelectTeamModal
          onClose={() => setShowSelectTeamModal(false)}
          onTeamsChosen={handleTeamsChosen}
          initiallySelected={selectedTeams}
        />
      )}
    </div>
  );
};

export default ScheduleModal;