import React from 'react';
import { Link } from 'react-router-dom';
// import { UserContext } from '../UserProvider';
import { useProject } from "./ProjectData";
import '../Styles/CurrentProjectNav.css';


const CurrentProjectNav =() =>{
    // const { user } = useContext(UserContext);
    const { projects, handleProjectClick} = useProject();
    // const navigate = useNavigate();

    





    return(

        <div id="dashboard_nav">
            <div className="dashboard_nav_logo_container">
                <Link to='/dashboard' className="navbar-brand">
                    <img src="/static/images/designxcel_logo.png" alt="designxcel logo" className="img-fluid" />
                </Link>
                {/* <button className="btn btn-lg d-none" id="sidebarToggle" type="button"><i className="fas fa-bars" /></button> */}
            </div>
          <nav className="sb-sidenav">
            <div className="sb-sidenav-menu">
              <div className="mt-4 current_project_nav">
                <div className='current_projects_box'>
                    <p className='current_project_title'>Current Projects</p>
                    {projects.map((project) => {
                        let statusClass = "";
                        switch (project.status) {
                            case "Draft":
                            case "Pending Review":
                            statusClass = "pending_review";
                            break;
                            case "In Progress":
                            statusClass = "in_progress";
                            break;
                            case "Completed":
                            statusClass = "completed";
                            break;
                            default:
                            statusClass = "";
                        }

                        return (
                            <div key={project.id} className="current_project_link" onClick={() => handleProjectClick(project)}>
                            <div>
                                <Link className="project_title_link" to="">
                                <h4>{project.name}</h4>
                                </Link>
                                <p>View Project {'>>'}</p>
                            </div>

                            <div className="collaborators">
                                <span className={statusClass}>
                                {project.status.replace("_", " ")}
                                </span>
                                <div>
                                    <span>No team added yet</span>
                                {/* <img className="img-fluid img_1" src="/static/images/bcs1.png" alt='collaborator' />
                                <img className="img-fluid img_2" src="/static/images/bcs2.png" alt='collaborator'/>
                                <img className="img-fluid img_3" src="/static/images/bcs1.png" alt='collaborator' />
                                <img className="img-fluid img_4" src="/static/images/bcs2.png" alt='collaborator' /> */}
                                </div>
                            </div>
                            <hr />
                            </div>
                        );
                    })}
                </div>
              </div>
              <div className='d-grid'>
                    <button className='btn btn-lg btn-outline-light'>Get Support</button>
              </div>
            </div>
          </nav>
        </div>
    )
}

export default CurrentProjectNav