import React, { useContext } from "react";
import DashboardNav from './DashboardNav';
import '../Styles/Billing.css'
import { Link } from "react-router-dom";
import NotificationProfile from "../Components/NotificationProfile";

const payments = [
  { name: "Sarah Johnson", role: "Senior Designer", type: "Full Time", amount: "₦250,000", status: "Paid" },
  { name: "Michael Chen", role: "UI Developer", type: "Freelance", amount: "₦175,000", status: "Pending" },
  { name: "Michael Chen", role: "UI Developer", type: "Freelance", amount: "₦175,000", status: "Pending" },
  { name: "Michael Chen", role: "UI Developer", type: "Freelance", amount: "₦175,000", status: "Pending" },
];

const invoices = [
  { id: "INV-2024-001", plan: "Professional License - Team Plan", dueDate: "Mar 01, 2025", amount: "₦2,750", status: "Pending" },
  { id: "INV-2024-002", plan: "Professional License - Team Plan", dueDate: "Mar 01, 2025", amount: "₦2,750", status: "Pending" },
  { id: "INV-2024-003", plan: "Professional License - Team Plan", dueDate: "Mar 01, 2025", amount: "₦2,750", status: "Pending" },
];

const Billing = () => {

  return (
    <>
      <div id="userpage_container">
        <DashboardNav />
        <div className="wrapper card">
          <header className='flex mb-3'>
            <h2>Billing & Subscription</h2>
            <NotificationProfile />
          </header>
          <main>
            <div className="flex">
              <div>
                <p className="price"><strong>Current Plan:</strong> Growth</p>
                <p className="id">Next billing date: March 07, 2025</p>
              </div>
              <div className="plan text-center">Business</div>
            </div>
            <div className="flex my-3">
              <div>
                <p className="price">₦200,000</p> <p className="id muted">per month</p>
              </div>
              <Link to={'/billing/plans'}><button className="button">Change Plan</button></Link>
            </div>
            <div className="card">
              <p className="muted">You are currently using all 20 seats. Consider upgrading your plan to add more team members.</p>
            </div>

            <div className="grid grid-2">
              {/* <TeamPayments /> */}
              <div className="card dark">
                <div className="flex id mb-3">
                  <p>Team Payments</p>
                  <Link to={'/billing/team-payments'}><button className="button">Manage Team Payments</button></Link>
                </div>
                {payments.map((p, index) => (
                  <div key={index} style={{ marginTop: "10px" }} className="border-b flex ">
                    <div>
                      <p className="price" >{p.name}</p>
                      <p className="id"> {p.role}</p>
                    </div>
                    <div className="text-center">
                      <p className="price">{p.amount}</p>
                      <p className="id">{p.type}</p>
                    </div>
                    <button className="status" style={{ color: p.status === "Paid" ? "#1E1E1E" : "white", background: p.status === "Paid" ? "white" : "#27272A" }}>{p.status}</button>
                  </div>
                ))}
              </div>
              {/* <TeamSeats /> */}
              <div className="card dark">
                <div className="flex mb-3">
                  <div className="id">
                    <p>Team Seats Breakdown</p>
                    <p className="muted">20 of 20 seats used</p>
                  </div>
                  <Link to={'/billing/team-seats'}><button className="button">Manage Seats</button></Link>
                </div>
                <div className="card dark mb-4 price"><strong className="id">Full Time:</strong> 10</div>
                <div className="card dark mb-4 price"><strong className="id">Freelance:</strong> 3</div>
                <div className="card dark mb-4 price"><strong className="id">Client:</strong> 7</div>
              </div>
            </div>

            {/* <Invoices /> */}
            <div className="invoice">
              <div className="flex">
                <div>
                  <h3 className="id">Recent Invoices</h3>
                  <p className="due text-white my-3">View and download your recent invoices</p>
                  <button className="button">Process Payment</button>
                </div>
                <div className="flex">
                  <div class="box">
                    <img src='/static/images/total-inv.svg' alt='invoice icon' />
                    <div class="info">
                      <p>Total Invoiced</p>
                      <h6>₦1,460,250.00</h6>
                    </div>
                  </div>

                  <div class="box">
                    <img src='/static/images/outstanding-inv.svg' alt='invoice icon' />
                    <div class="info">
                      <p>Outstanding</p>
                      <h6>₦2,750.00</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid">
                {invoices.map((invoice, index) => (
                  <div className="card dark" key={index}>
                    <p className="id"><strong>{invoice.id}</strong></p>
                    <div className="flex">
                      <div>
                        <h5 className="price">{invoice.plan}</h5>
                        <p className="due">Due: {invoice.dueDate}</p>
                      </div>
                      <div>
                        <p className="inline-block price">{invoice.amount}</p>
                        <button className="status inline-block">{invoice.status}</button>
                        <img src='/static/images/invoice.svg' alt='invoice icon' />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default Billing