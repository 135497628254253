import React, { useContext, useState } from "react";
import DashboardNav from './DashboardNav';
import Table from "../Components/Table";
import { useNavigate } from "react-router-dom";
import Modal from "../Components/Billing/Modal/Modal";
import NotificationProfile from "../Components/NotificationProfile";

const TeamSeats = () => {
    const [isModalOpen, setModalOpen] = useState(false);
     const [amount, setAmount] = useState("");
  const [seatNo, setSeatNo] = useState(0);
  const handleIncrement = () => {
    setSeatNo(prevSeatNo => prevSeatNo + 1);
  };
  // Payment Stats Data
  const stats = [
    { title: "Total", amount: "₦2,750,000.00", description: "₦2,750.00" },
    { title: "Full Time", amount: "Talent Pay: ₦2,750.00", description: "10" },
    { title: "Clients", amount: "₦2,750,000.00", description: "7" },
    { title: "Freelance", amount: "₦2,750,000.00", description: "3" }
  ];

  // Table Headers
  const columns = ["Name", "Email", "Role", "Type", "Seat Rate/Month", "Status", ""];

  // Table Rows
  const rows = [
    ["John Doe", "john@afriai.com", "Lead Designer", <span className="badge">Full-time</span>, "₦2,750.00", <span className="status-active">Active</span>, <button className="button-text text-muted">Remove</button>]
  ];


  const goBack = () => {
    window.history.back();
  }

  return (
    <>
      <div id="userpage_container">
        <DashboardNav />
        <div className="wrapper card">
          <header className="mb-5">
            <div className="flex">
            <h2>Team Seats</h2>
            <NotificationProfile />
            </div>
            <div className="flex mt-4">
              <button className="button" onClick={goBack}><img src='/static/images/back.svg' alt="Angle Left" className="me-2" /> Back</button>
              <div>
                <div className="plan text-center">Business</div>
              </div>
            </div>
          </header>
          <main>
            <div className="card dark p-4">
              <div className="flex mt-4">
                <div>
                  <p className="text-white">Team Seats Breakdown:</p>
                  <span className="text-muted">20 of 20 seats used</span>
                </div>
                <button className="button" onClick={() => setModalOpen(true)}>Add Seats</button>
              </div>
              {/* Seat Number Modal */}
              <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} title="Add Seats">
                {/* Seat Number*/}
                <div className="meeting-hint-container mt-5 mb-3 position-relative">
                  <label className="meeting-label">Seat Number</label>
                  <input
                    className="meeting-input"
                    required
                    type="text"
                    value={seatNo}
                    onChange={(e) => setSeatNo(Number(e.target.value))}
                  />
                  <button
                    className="position-absolute top-50 translate-middle-y"
                    style={{ right: "18px", cursor: 'pointer' }}
                    onClick={handleIncrement}>
                      <img src='/static/images/plus.svg' alt="Plus" />
                    </button>
                </div>

                {/* Amount Field */}
                <div className="meeting-hint-container mb-4">
                  <label className="meeting-label ">Amount (₦)</label>
                  <input
                    className="meeting-input"
                    required
                    type="text"
                    placeholder="₦10000"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>

                {/* Continue Button */}
                <div className="text-end mb-3">
                  <button className="button continue-btn">Continue</button>
                </div>
              </Modal>
              <div className="grid mt-3 grid-4">
                {stats.map((stat, index) => (
                  <div className="card dark" key={index}>
                    <h6>{stat.title}</h6>
                    <p className="text-white">{stat.description}</p>
                  </div>
                ))}
              </div>

              <div className="my-5">
                <h6>Team Members</h6>
                <p className="text-white">Manage your team members and their access</p>
                <Table columns={columns} rows={rows} />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default TeamSeats