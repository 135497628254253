import React, { useContext, useState, useEffect, useRef } from "react";
import { FileText, Eye, CircleCheckBig } from 'lucide-react';
import ProjectWorkspaceNav from './ProjectWorkspaceNav';
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import { SidebarContext } from "./Sidebar";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter"
import '../Styles/ReviewAndApproval1.css';
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";

const apiUrl = process.env.REACT_APP_API_URL;

const tabs = ["All", "Pending Review", "Approved", "Rejected"];

const reviews = [
    { id: 1, title: "Homepage Design v2", status: "review", date: "Feb 14, 2025", user: "Kurie Fassi" },
    { id: 2, title: "Homepage Design v2", status: "approved", date: "Feb 14, 2025", user: "Kurie Fassi" },
    { id: 3, title: "Homepage Design v2", status: "rejected", date: "Feb 14, 2025", user: "Kurie Fassi" }
];

export default function ReviewAndApproval() {
    const [activeTab, setActiveTab] = useState("All");

    const filteredReviews =
        activeTab === "All" ? reviews : reviews.filter((review) => review.status === activeTab.toLowerCase());


    return (
            <div id="userpage_container">
                <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader />
                    <div className="review-container">
                        {/* Tabs Navigation */}
                        <div className="tabs">
                            {tabs.map((tab) => (
                                <div
                                    key={tab}
                                    className={`tab ${activeTab === tab ? "active" : ""}`}
                                    onClick={() => setActiveTab(tab)}
                                >
                                    {tab}
                                </div>
                            ))}
                        </div>



                        {/* Review Cards */}
                        {filteredReviews.map((review) => (
                            <div key={review.id} className="review-card">
                                <div className="review-icon">
                                    <FileText size={40} />
                                </div>
                                <div className="review-details">
                                    <h3 className="review-title">{review.title}</h3>
                                    <h3 className="review-meta">Updated layout with new navigation</h3>
                                    <div className="review-each-card">
                                        <h3 className="review-meta">UI Design</h3>
                                        <h3 className="review-meta">Linked To: Website</h3>
                                    </div>
                                </div>
                                {/* Status Buttons */}
                                <div className="">
                                    <div className="button-container">
                                        <div className="view-container">
                                            <Eye size={18} />
                                            <span className="button-text">View</span>
                                        </div>
                                        <button className={`status-btn status-${review.status}`}>
                                            <CircleCheckBig size={18} />
                                            {review.status === "review" ? "Review" : review.status.charAt(0).toUpperCase() + review.status.slice(1)}
                                        </button>
                                    </div>
                                    <div className="left-bottom-container">
                                        <span className="review-meta">{review.date}</span>
                                        <span className="review-meta"> {review.user}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <ProjectWorkspaceFooter />
                </div>
                <ProjectWorkspaceChat />
            </div>

    )

}

