import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import Nav from './Nav';
import Footer from "./Footer";
const apiUrl = process.env.REACT_APP_API_URL;

const Home =() =>{
    const [input, setInput] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const chatContainerRef = useRef(null);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const hasFetched = useRef(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0]; 
                if (entry.isIntersecting && !hasFetched.current) {
                    hasFetched.current = true; // Prevent future fetches
                    fetchWelcomeMessage();
                }
            },
            { threshold: 0.25 } // Trigger when 10% of chat box is visible
        );

        if (chatContainerRef.current) {
            observer.observe(chatContainerRef.current);
        }

        return () => observer.disconnect(); // Cleanup on unmount
    }, []);

    const fetchWelcomeMessage = async () => {
        try {
            setLoading(true);
            setError(null);

            const response = await fetch(`${apiUrl}/api/welcome/`, {
                method: "GET",
                credentials: "include",
            });

            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const data = await response.json();

            if (data.message === "existing chat" && data.chat_history) {
                // Format backend data for frontend
                const formattedChatHistory = data.chat_history.map(msg => ({
                    text: msg.content, 
                    type: msg.role,
                }));
                setChatHistory(formattedChatHistory);
            } else {
                setChatHistory([{ text: data.message, type: "assistant" }]);
            }
        } catch (err) {
            console.error("Error fetching chat session:", err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    
    const handleSendMessage = async () => {
        if (!input.trim()) return;

        // Append user message to chat history immediately
        setChatHistory((prevHistory) => [...prevHistory, { text: input, type: "user" }]);
        setInput(""); // Clear the input field

        try {
            setLoading(true);
            setError(null);

            const response = await fetch(`${apiUrl}/api/reply/`, {
                method: 'POST',  // Ensure it's a POST request
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message: input, // ✅ FIXED: Using "message" to match the backend
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const suggestSignin = data.suggest_signin || false;

            setChatHistory((prevHistory) => [
                ...prevHistory,
                { text: data.message, type: "assistant", button: suggestSignin }, // Keep AI responses consistent
            ]);

        } catch (err) {
            setError('Failed to send message. Please try again.');
            console.error("Error sending message:", err);
        } finally {
            setLoading(false);
        }
    };







    
    // Scroll to the last message
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: "smooth", // Adds the smooth scrolling effect
            });
        }
    };

    // Scroll to the last message on load or when messages change
    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);


    const handleInputChange = (e) => {
        setInput(e.target.value);
    };
    
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSendMessage();
        }
    };




    return (
        <>
        <Nav />
        { /* Home header start */}
        <section className="home_header mt-5 py-5">
            <div className="container">
                <div className="row">
                    <div className="col" align="center">
                        <h1>Build Faster. Smarter. With Less Hassle.</h1>
                    </div>
                    <div className="col" align="center">
                        <h6>Get your design and development projects done using AI, talents and tools tailored to your needs.</h6>
                    </div>
                    <div className="col cta_button_box">
                        <button className="btn btn-lg cta_button_a" onClick={()=>document.location.href='#create_project'}>Start Your Project</button>
                        <button className="btn btn-lg cta_button_b" onClick={()=>document.location.href='#how-it-works'}>See How It Works</button>
                    </div>
                </div>
            </div>
        </section>
        { /* Home header end */}
        {/* Home Coversation Section Start */}
        <section className="home_conversation_section py-3" id="create_project">
            <div className="container">
                <div className="home_conversation_title">
                    <img src="/static/images/designxcel_icon_white.png" className="img-fluid" alt="designxcel icon" />
                    <div>
                        <h4>Create a Project</h4>
                        <p>Chat with your creative partner</p>
                    </div>
                </div>
                <div className="home_conversation_box">
                    <div className="">
                        {/* Display chat history */}
                        <div ref={chatContainerRef} className="home_chat_box">
                            <div>
                                {chatHistory.map((chat, index) => (
                                    <div
                                        key={index}
                                        className={`${
                                            chat.type === 'user' ? 'home_user_message' : 'home_system_message'
                                        }`}
                                    >
                                        <p>{chat.text}</p>
                                        {chat.button && (
                                            <button className="btn chat-signup-button" onClick={() => window.location.href = "/login"}>
                                                Login
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div>
                                {loading && <p>Loading chat...</p>}
                                {error && <p className="error-message">{error}</p>}
                            </div>
                        </div>
                        <div class="input-group">
                            <textarea class="form-control" value={input} onChange={handleInputChange} onKeyDown={handleKeyDown}  placeholder="Type your request or ask for help"></textarea>
                            <span class="input-group-text">
                                <div onClick={handleSendMessage}><img src="/static/images/send_message_icon.png" className="img-fluid" alt='send message icon'  /></div>
                                <div><img src="/static/images/mic_icon.png" className="img-fluid" alt='mic icon'/></div>
                                <div><img src="/static/images/call_icon.png" className="img-fluid" alt='call icon'/></div>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="sample_prompts">
                    <h4>Sample Prompts</h4>
                    <div>
                        <span>Build a mobile app</span>
                        <span>Design a logo</span>
                        <span>Design a pitch deck</span>
                        <span>Design social media graphics</span>
                        <span>Design a flyer</span>
                    </div>
                </div>
                <div className="diy_tools">
                    <h4>DIY Tools</h4>
                    <div>
                        <span><img src="/static/images/figma_icon.png" className="img-fluid" alt="Figma icon" />Figma</span>
                        <span><img src="/static/images/webflow_icon.png" className="img-fluid" alt="webflow icon" />Webflow</span>
                        <span><img src="/static/images/open_ai_icon.png" className="img-fluid" alt="Open AI icon" />Open AI</span>
                        <span><img src="/static/images/co_dev_icon.png" className="img-fluid" alt="Co Dev icon" />Co Dev</span>
                        <span><img src="/static/images/canva_icon.png" className="img-fluid" alt="Canva icon" />Canva</span>
                        <span><img src="/static/images/lovable_icon.png" className="img-fluid" alt="Lovable icon" />Lovable</span><span><img src="/static/images/vs_code_icon.png" className="img-fluid" alt="VS Code icon" />VS Code</span>
                        <span>More</span>
                    </div>
                </div>
            </div>
        </section>
        {/* Home Coversation Section End */}



        { /* product branding  start */}
        <section className="branding_section mb-5 py-3">
            <div className="container">
                <div className="row">
                    <div className="col" align="center">
                        <h4>Whether you're buiding a product, branding your product, or scaling your platform, <span>we're here to make it happen-quickly, efficiently, and beautifully.</span></h4>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-11 col-lg-7">
                       <div className="row set">
                        <div className="col-md-5">
                            <img className="img-fluid" src="/static/images/expert_icon.png" alt="expert developer icon" />
                            <p>Expert designers and developers</p>
                        </div>
                        <div className="col-md-5">
                            <img className="img-fluid" src="/static/images/ondemand_icon.png" alt="on demand icon" />
                            <p>On-demand services</p>
                        </div>    
                       </div>
                       <div className="row set">
                        <div className="col-md-5">
                            <img className="img-fluid" src="/static/images/nocode_icon.png" alt="no code icon" />
                            <p>No-code solutions and training</p>
                        </div>
                        <div className="col-md-5">
                            <img className="img-fluid" src="/static/images/print_icon.png" alt="print icon" />
                            <p>DIY with AI and live support</p>
                        </div>    
                       </div>
                    </div>
                </div> */}
            </div>
        </section>
        { /* product branding end */}

        {/* Your project your way start */}
        <section id="how-it-works" className="your_project_way_section py-4">
            <div className="container">
                <div className="row">
                    <div className="col" align="center">
                        <h4>Your Project, Your Way</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-11 col-lg-7">
                       <div className="row set">
                        <div className="col-md-5">
                            <img className="img-fluid" src="/static/images/home_tool_icon.png" alt="expert developer icon" />
                            <p>AI + Human Expertise</p>
                            <span>The perfect blend of automation and skills 
                            </span>
                        </div>
                        <div className="col-md-5">
                            <img className="img-fluid" src="/static/images/home_team_icon.png" alt="on demand icon" />
                            <p>End to End Execution</p>
                            <span>From idea to launch, all in one place</span>
                        </div>    
                       </div>
                       <div className="row set">
                        <div className="col-md-5">
                            <img className="img-fluid" src="/static/images/home_platform_icon.png" alt="no code icon" />
                            <p>Seemless Collaboration</p>
                            <span>Work effortlessly with your team and our experts</span>
                        </div>
                        <div className="col-md-5">
                            <img className="img-fluid" src="/static/images/ai_icon.png" alt="print icon" />
                            <p>Results</p>
                            <span>Build your products faster than ever</span>
                        </div>    
                       </div>
                    </div>
                </div>
                <div align="center">
                    <button className="btn btn-lg platform_btn">Explore the Platform</button>
                </div>
            </div>

        </section>
        {/* Your project your way end */}


        { /* creative and development start */}
        {/* <section className="creative_development_section mb-5 py-3">
            <div className="container">
                <div className="row">
                    <div className="col" align="center">
                        <h4>All your creative and development needs in one place</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <img className="img-fluid" src="/static/images/ondemand_icon.png" alt="design services icon" />
                        <p>Design Services</p>
                        <div className="creative_tags">
                            <span>Logo</span>
                            <span>UI/UX</span>
                            <span>Product Packaging</span>
                            <span>Branding</span>
                            <span>Presentations</span>
                            <span>Prototype</span>
                            <span>Social Media Graphics</span>
                            <span>More</span>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <img className="img-fluid" src="/static/images/expert_icon.png" alt="deployment services icon" />
                        <p>Development Services</p>
                        <div className="creative_tags">
                            <span>MVPs</span>
                            <span>Websites</span>
                            <span>Mobile Apps</span>
                            <span>ERP</span>
                            <span>No Code Development</span>
                            <span>Custom Development</span>
                            <span>SaaS</span>
                            <span>More</span>
                        </div>
                    </div>    
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <img className="img-fluid" src="/static/images/print_icon.png" alt="on deman logo" />
                        <p>On-Demand Printing</p>
                        <div className="creative_tags">
                            <span>Business Cards</span>
                            <span>Flyers</span>
                            <span>Banners</span>
                            <span>Stickers</span>
                            <span>Custom Packaging</span>
                            <span>Event Materials</span>
                            <span>Branded Apparel</span>
                            <span>More</span>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <img className="img-fluid" src="/static/images/training_support_icon.png" alt="training logo" />
                        <p>Training</p>
                        <div className="creative_tags">
                            <span>No-Code</span>
                            <span>Frontend Development</span>
                            <span>Backend Development</span>
                            <span>Full Stack</span>
                            <span>UI/UX</span>
                            <span>Design</span>
                            <span>Artificial Intelligence</span>
                            <span>More</span>
                        </div>
                    </div>    
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <img className="img-fluid" src="/static/images/print_icon.png" alt="recruitment logo" />
                        <p>Recruitment</p>
                        <div className="creative_tags">
                            <span>Single Role Recruitment</span>
                            <span>Team Recruitment</span>
                            <span>Payments</span>
                            <span>Dedicated Resources</span>
                            <span>Shared Resources</span>
                            <span>Management</span>
                            <span>More</span>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <img className="img-fluid" src="/static/images/training_support_icon.png" alt="support logo" />
                        <p>Support</p>
                        <div className="creative_tags">
                            <span>Debugging</span>
                            <span>Trouble Shooting</span>
                            <span>Live Sessions</span>
                            <span>Ongoing Support</span>
                            <span>Consultations</span>
                            <span>Maintenance</span>
                            <span>More</span>
                        </div>
                    </div>    
                </div>
                <div align="center">
                    <button className="btn btn-lg creative_btn" onClick={() => navigate('/lets-talk')}>Can't see what you need? Let's have a chat</button>
                </div>
            </div>
        </section> */}
        { /* creative and development end */}
        {/* Hassle Free Section Start */}
        <section className="hassle_free_section mb-5 py-3">
            <div className="container">
                <div className="row">
                    <div className="col" align="center">
                        <h4>Simple, Transparent, Hassle-Free</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-11 col-lg-7">
                       <div className="row">
                            <div className="col-md-5">
                                <h5>1</h5>
                                <p>Define your project</p>
                                <small>Tell us what you need—design, development, or other.</small>
                            </div>
                            <div className="col-md-5">
                                <h5>2</h5>
                                <p>Select Your Approach</p>
                                <small>Choose tools, teams, platforms, or AI.</small>
                            </div>    
                       </div>
                       <div className="row">
                            <div className="col-md-5">
                                <h5>3</h5>
                                <p>Track Progress in Real Time</p>
                                <small>Stay on top of tasks, collaborate, and get live insights.</small>
                            </div>
                            <div className="col-md-5">
                                <h5>4</h5>
                                <p>Approve and Launch</p>
                                <small>Review, refine, and go live with confidence.</small>
                            </div>    
                       </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Hassle Free Section End */}
        { /* some of our work start */}
        <section className="work_section mb-5 py-3">
            <div className="container">
                <div className="row">
                    <div className="col" align="center">
                        <h4>Some of our work</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-11 work_img_box1">
                        <p>Splitpay UI design <span>simplify shared payments with elegant interface</span></p>
                        <img className="img-fluid" src="/static/images/work_img1.png" alt="work sample" />
                    </div>
                </div>
                <div className="row work_images_lower">
                    <div className="col-md-12 col-lg-10">
                        <div className="row">
                            <div className="col work_img_box2">
                                <p>FoodCap Mobile Application <span>helps users get their food and groceries ordered and delivered</span></p>
                                <img className="img-fluid" src="/static/images/work_img2.png" alt="work sample" />
                            </div>
                        <div className="col work_img_box2">
                                <p>MatchPoint Web and Mobile App<span> full-stack development, UI/UX design, and continued ongoing support. </span></p>
                                <img className="img-fluid" src="/static/images/work_img3.png" alt="work sample" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        { /* some of our work end */}
        { /* Home Pricing Start */}
        <section className="home_pricing mb-5 py-3">
            <div className="container">
                <div className="row">
                    <div className="col" align="center">
                        <h4>Flexible Pricing to Fit Your Needs</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-11 col-lg-7">
                        <div className="row pricing_type">
                            <div className="col-md-5">
                                <h5>Pay-As-You-Go</h5>
                                <p>Perfect for one-off projects</p>
                            </div>
                            <div className="col-md-5">
                                <h5>Subscription</h5>
                                <p>For ongoing projects and team</p>
                            </div>
                        </div>
                        <div className="row home_pricing_button_box">
                                <div className="col-md-5">
                                    <button className="btn btn-lg home_pricing_button1" onClick={()=>navigate('/pricing')}>Compare Plans</button>
                                </div>
                                <div className="col-md-5">
                                    <button className="btn btn-lg home_pricing_button2" onClick={()=>navigate('/custom-quote')}>Request a Custom Quote</button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        { /* Home Pricing End */}
        { /* Powered by  start */}
        <section className="powered_by_section mb-4 py-3">
            <div className="container">
                <div className="row">
                    <div className="col" align="center">
                        <h4>Designed for Startups, Agencies and Enterprises</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-11 col-lg-7">
                       <div className="row powered_by_box">
                            <div className="col-md-5">
                                <img className="img-fluid" src="/static/images/flexibility_icon.png" alt="flexibility icon" />
                                <p>On-demand service for flexibility</p>
                            </div>
                            <div className="col-md-5">
                                <img className="img-fluid" src="/static/images/team_icon.png" alt="dedicated icon" />
                                <p>Dedicated and shared team models</p>
                            </div>    
                       </div>
                       <div className="row powered_by_box">
                            <div className="col-md-5">
                                <img className="img-fluid" src="/static/images/support_icon.png" alt="support icon" />
                                <p>AI Assistance and expert support for all your needs</p>
                            </div>
                            <div className="col-md-5">
                                <img className="img-fluid" src="/static/images/tag_icon.png" alt="transparency icon" />
                                <p>Transparent pricing and fast turnaround</p>
                            </div>    
                       </div>
                    </div>
                </div>
                <div align="center">
                    <button className="btn btn-lg powered_by_btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Let's Build Something Together</button>
                </div>
            </div>
        </section>
        { /* Power by end */}
        <Footer />
        </>
    )
}


export default Home