import React, {useContext, useState, useEffect, useRef, useCallback} from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../UserProvider';
import { useProject } from "./ProjectData";
import DashboardHeader from "./DashboardHeader";
import CurrentProjectNav from './CurrentProjectNav';
import { SidebarContext } from "./Sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../Styles/NewProject.css'
const apiUrl = process.env.REACT_APP_API_URL;



const NewProject =() =>{
    const { isSidebarVisible } = useContext(SidebarContext);
    const [chatSession, setChatSession] = useState(null);
    const { user } = useContext(UserContext);
    const { setCurrentProject, currentProject, setProjects, updateProjectName, selectedProject,setSelectedProject, setActiveProject} = useProject();
    const [input, setInput] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const navigate = useNavigate();
    const location = useLocation();
    const chatContainerRef = useRef(null);
    const hasFetched = useRef(false);
    const storedSessionId = localStorage.getItem("chatSession");
    const storedProject = localStorage.getItem("currentProject");

    useEffect(() => {
        const fromButton = location.state?.fromButton || sessionStorage.getItem("fromButton");
    
        if (!fromButton) {
            navigate("/project/all-projects", { replace: true });
        } else {
            sessionStorage.setItem("fromButton", "true"); // Keep it for refresh
        }
    }, [location, navigate]);
    


    // Scroll to the last message
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: "smooth", // Adds the smooth scrolling effect
          });
        }
    };

    // Scroll to the last message on load or when messages change
    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);


    useEffect(() => {
        if (storedProject) {
            setSelectedProject(JSON.parse(storedProject));
        }
    }, [setSelectedProject, storedProject]); 

    const fetchInitialPrompt = useCallback(async () => {
    
        if (!user?.token) return;
        if (hasFetched.current) return; // Prevent duplicate execution
        hasFetched.current = true;
    
        try {
            setLoading(true);
            setError(null);
            
            const response = await fetch(`${apiUrl}/api/chat-start/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`,
                },
                body: JSON.stringify({ session_id: storedSessionId }),
            });

            if (response.status === 401) {
                console.warn("Unauthorized access, redirecting...");
                navigate("/login"); // Redirect to home if token is expired/invalid
                return;
            }
    
            if (!response.ok) {
                throw new Error(`HTTP error! ${response.status}`);
            }
    
            const data = await response.json();
    
            if (data.status === "existing_session") {
                setChatSession(data.session_id);
                setChatHistory(data.messages);
                const parsedProject = storedProject ? JSON.parse(storedProject) : null;
                if (parsedProject) {
                    setCurrentProject(parsedProject);
                }
            } else {
                setChatSession(data.session_id);
                const newProject = {
                    id: data.project_id,
                    name: data.project_name,
                    status: data.project_status,
                };
                setProjects((prevProjects) => [newProject, ...prevProjects]);
                setCurrentProject(newProject);
                setChatHistory([{ text: data.message, type: "system" }]);
                localStorage.setItem("chatSession", data.session_id);
                localStorage.setItem("currentProject", JSON.stringify(newProject));
            }
        } catch (err) {
            console.error("Error fetching chat session:", err);
        } finally {
            setLoading(false);
            hasFetched.current = false;
        }
    }, [user?.token, storedSessionId, storedProject, setCurrentProject, setProjects]); 
    



    useEffect(() => {
        if (!user || !selectedProject) return;
        fetchInitialPrompt();  // Now safe to call
    }, [user, selectedProject, fetchInitialPrompt, location.pathname]);
 
   


     

    

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };
    
    const handleKeyDown = (e) => {
    if (e.key === "Enter") {
        handleSendMessage();
    }
    };

    const handleSendMessage = async () => {
        if (!input.trim() || !chatSession) return;

        // Append user message to chat history immediately
        setChatHistory((prevHistory) => [...prevHistory, { text: input, type: "user" }]);
        setInput(""); // Clear the input field

        try {
            setLoading(true);
            setError(null);

            const response = await fetch(`${apiUrl}/api/chat/response/`, {
                method: 'POST',
                credentials: 'include', 
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`,
                },
                body: JSON.stringify({
                    session_id: chatSession,
                    project_id: currentProject.id,
                    user_response: input,
                }),
            });

            if (response.status === 401) {
                console.warn("Unauthorized access, redirecting...");
                navigate("/login"); // Redirect to home if token is expired/invalid
                return;
            }

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.status === "Project name and type updated") {
                updateProjectName(currentProject.id, data.project_name, data.last_updated);
                setChatHistory((prevHistory) => [
                    ...prevHistory,
                    { text: data.message, type: "system" },
                ]);
                toast.success(`New Project Created - ${data.project_name}`, {
                    position: "bottom-right"
                });
            }
            if (data.status === "complete" || data.status === "incomplete") {
                setChatHistory((prevHistory) => [
                    ...prevHistory,
                    { text: data.message, type: "system" },
                ]);
    
                if (data.redirect_url) {
                    setActiveProject(currentProject);
                    setTimeout(() => {
                        navigate(data.redirect_url);
                    }, 6000); // 6-second delay
                }
            }
        } catch (err) {
            setError('Failed to send message. Please try again.');
            console.error(err);
        } finally {
            setLoading(false);
            console.log(error)
        }
    };


    return(
        <>
        <div id="userpage_container">
        <CurrentProjectNav />
            <div id="dashboard_containter">
            <DashboardHeader />
                <main className="new_project_container">
                    {/* New project header start */}
                    <section className="new_project_header">
                        <h4>Your Personal Assistant</h4>
                        <p>Chat with your creative partner</p>
                    </section>
                    {/* New project header end */}
                    {/* project chatbox start */}
                    <div className="new_project_chat_body">
                        {/* Display chat history */}
                        <div ref={chatContainerRef} className="new_project_chat_box">
                            <div>
                                {chatHistory.map((chat, index) => (
                                    <div
                                        key={index}
                                        className={`${
                                            chat.type === 'user' ? 'user_message' : 'system_message'
                                        }`}
                                    >
                                        <p>{chat.text}</p>
                                    </div>
                                ))}
                            </div>
                            <div>
                                {loading && <p>Loading chat...</p>}
                                {error && <p className="error-message">{error}</p>}
                            </div>
                        </div>
                        <div class="input-group">
                            <textarea class="form-control" value={input} onChange={handleInputChange} onKeyDown={handleKeyDown} disabled={loading} placeholder="Type your request or ask for help"></textarea>
                            <span class="input-group-text">
                                <div><img src="/static/images/send_message_icon.png" className="img-fluid" alt='send message icon' onClick={handleSendMessage} /></div>
                                <div><img src="/static/images/mic_icon.png" className="img-fluid" alt='mic icon'/></div>
                                <div><img src="/static/images/call_icon.png" className="img-fluid" alt='call icon'/></div>
                            </span>
                        </div>
                    </div>
                    {/* project chatbox end */}
                </main>
            </div>
        </div>
        </>

    )

}


export default NewProject