import React, { useContext, useState, useEffect, useRef } from "react";
import { FileText, Repeat2 } from 'lucide-react';
import ProjectWorkspaceNav from './ProjectWorkspaceNav';
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import { SidebarContext } from "./Sidebar";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter"
import '../Styles/ReviewAndApproval1.css';
import '../Styles/MobilePayment.css';
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";


const tabs = ["Preview", "Reviews", "Changes", "Details"];



export default function MobilePayment() {
    const [activeTab, setActiveTab] = useState("All");

    const filteredTabs =
        activeTab === "All" ? tabs : tabs.filter((tab) => tab === activeTab.toLowerCase());


    return (
        <div id="userpage_container">
            <ProjectWorkspaceNav />
            <div className="project_workspace_container">
                <ProjectWorkspaceHeader />
                <div className="review-container">
                    <div className="mobile-payment-header">
                        {/* Tabs Navigation */}
                        <div className="tabs">
                            {tabs.map((tab) => (
                                <div
                                    key={tab}
                                    className={`tab ${activeTab === tab ? "active" : ""}`}
                                    onClick={() => setActiveTab(tab)}
                                >
                                    {tab}
                                </div>
                            ))}
                        </div>
                        <button className="mobile-payment-button">Approve</button>
                    </div>

                    {/* Review Cards */}
                    {activeTab === "Preview" ? (
                        <div className="preview-container">
                        </div>
                    ) : activeTab === "Reviews" ? (
                        <div className="">
                            <div className="send-review">
                                <div className="review-img">
                                    <img src="/static/images/x_icon.png" alt="profile" className="review-img" />
                                </div>
                                <div className="review-details">
                                    <h3 className="review-name">Kurie Fassi</h3>
                                    <h3 className="review-text">The color scheme needs to be more consistent with our brand guildelines</h3>
                                </div>
                                <div className="review-right-details">
                                    <h3 className="review-name">1 day ago</h3>
                                    <div className="review-reply">
                                        <h3 className='review-name'>3 replies</h3>
                                        <img src="/static/images/x_icon.png" alt="profile" className="review-img" />
                                    </div>
                                </div>
                            </div>

                            <div className="receive-review">
                                <h3 className="review-text">The color scheme needs to be more consistent with our brand guildelines</h3>
                                <div className="review-reply">
                                    <h3 className="review-name">1 day ago</h3>
                                    <div className="review-reply">
                                        <h3 className='review-name'>3 replies</h3>
                                        <img src="/static/images/x_icon.png" alt="profile" className="review-img" />
                                    </div>
                                </div>

                            </div>

                            <div className="post-review">
                                <input type="text" placeholder="Add your review" className="review-input" />
                                <button className="review-button">Save</button>
                            </div>
                        </div>
                    ) : activeTab === "Changes" ? (
                        <div className="">
                            <div className="send-review">
                                <div className="review-img">
                                    <img src="/static/images/x_icon.png" alt="profile" className="review-img" />
                                </div>
                                <div className="review-details">
                                    <h3 className="review-name">Kurie Fassi</h3>
                                    <h3 className="review-text">The color scheme needs to be more consistent with our brand guildelines</h3>
                                </div>
                                <div className="review-right-details">
                                    <h3 className="review-name">1 day ago</h3>
                                    <div className="review-reply">
                                        <h3 className='review-name'>3 replies</h3>
                                        <img src="/static/images/x_icon.png" alt="profile" className="review-img" />
                                    </div>
                                </div>
                            </div>
                            <div className="post-review">
                                <input type="text" placeholder="Add your review" className="review-input" />
                                <button className="review-button">Save</button>
                            </div>
                        </div>
                    ) : (
                        <div className="details-container">
                            {/* Deliverable Overview */}
                            <div className="details-card">
                                <div className="review-reply">
                                    <FileText size={24} />
                                    <h3 className="subvalue">Deliverable Overview</h3>
                                </div>
                                <p className="details-card-subtext">Updated timeline and deliverables</p>
                                <div className="info">
                                    <div className="space">
                                        <h3 className="subtext">Submission Date:</h3>
                                        <h3 className="subvalue">2025-02-12</h3>
                                    </div>
                                    <div className="space">
                                        <h3 className="subtext">Submission By:</h3>
                                        <h3 className="subvalue">Sarah Parker</h3>
                                    </div>
                                    <div className="space">
                                        <h3 className="subtext">Version:</h3>
                                        <h3 className="subvalue">v2.0</h3>
                                    </div>
                                </div>
                            </div>

                            {/* Tasks & Contributions */}
                            <div className="details-card">
                                <h3 className="tasktitle">📋 Tasks & Contributions</h3>
                                <div className="task-container">
                                    <div className="space">
                                        <h3 className="taskvalue">Design Social Media Templates </h3>
                                        <span className="taskbutton">Completed</span>
                                    </div>
                                    <div className="space">
                                        <h3 className="taskvalue">Design Social Media Templates </h3>
                                        <span className="taskbutton">Completed</span>
                                    </div>
                                    <div className="space">
                                        <h3 className="taskvalue">Design Social Media Templates </h3>
                                        <span className="taskbutton">Completed</span>
                                    </div>
                                </div>
                            </div>

                            {/* Linked Items */}
                            <div className="details-card">
                                <h3 className="subvalue">Linked Items</h3>
                                <div className="task-container">
                                    <div className="space">
                                        <h3 className="taskvalue">🔗 Scope Review Meeting </h3>
                                        <span className="taskbutton">File</span>
                                    </div>
                                    <div className="space">
                                        <h3 className="taskvalue">🔗 Scope Review Meeting </h3>
                                        <span className="taskbutton">Task</span>
                                    </div>
                                    <div className="space">
                                        <h3 className="taskvalue">🔗 Scope Review Meeting </h3>
                                        <span className="taskbutton">Feature</span>
                                    </div>
                                </div>
                            </div>

                            {/* Version History */}
                            <div className="details-card">
                                <h3 className="subvalue">Version History</h3>
                                <div className="version">
                                    <Repeat2 size={24} />
                                    <div className="version-details">
                                        <strong className="strong">v2.1</strong> by John Doe <span className="date">2025-01-15</span>
                                        <p>Updated timeline and deliverables</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <ProjectWorkspaceFooter />
            </div>
            <ProjectWorkspaceChat />
        </div>

    )

}

