import React, { useContext, useEffect } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from '../UserProvider';
import { useProject } from "./ProjectData";
import '../Styles/ProjectWorkspaceHeader.css';
const apiUrl = process.env.REACT_APP_API_URL;

const ProjectWorkspaceHeader = ({ pageName }) => {
    const { user } = useContext(UserContext);
    const { activeProject, setActiveProject, getProjectById, projects} = useProject();
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!activeProject && id) {
            const project = getProjectById(id);
            if (project) {
                setActiveProject(project);
            }
        }
    }, [activeProject, id, getProjectById, setActiveProject]);







    
    return (
        <div className="row ProjectWorkspaceBody_header">
            <div className='left col'>
            {pageName.trim() === "Back to Project Team" ? (
                <button onClick={() => navigate(`/project/workspace/${activeProject.id}/${activeProject.name}/project-team`)} className="btn back-btn project-team-btn"><img src="/static/images/back_icon.png" className="img-fluid back_icon" alt="back icon" />
                </button>
            ) : (
                pageName.includes("Team") && pageName.trim() !== "Team" && (
                    <button onClick={() => navigate(-1)} className="btn back-btn">
                        <img src="/static/images/back_icon.png" className="img-fluid back_icon" alt="back icon" />
                    </button>
                )
            )}
                <p className="my-0">{pageName}</p>
                {!pageName.includes("Team") && (
                    <button className="btn btn-sm">V:0</button>
                )}
            </div>
            <div className="right col">
                <button className="btn btn-sm"><img src="/static/images/edit_project_icon.png" className="edit_project_icon img-fluid" alt="edit project icon" />Edit Project</button>
                <Link to={`/project/workspace/${activeProject.id}/${activeProject.name}/add-new-task`} className="btn btn-sm task_btn">Add New Task</Link>
            </div>
        </div>
    );
};

export default ProjectWorkspaceHeader;
