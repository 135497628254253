import { createContext, useState } from "react";

const TeamMemberContext = createContext();

export const TeamMemberProvider = ({ children }) => {
    const [formData, setFormData] = useState({
        email: "",
        role: "",
        // name: "",
        access: "",
        projectOverviewAndScope: "",
        taskAndWorkflowManagement: "",
        projectFilesAndDocumentations: "",
        teamManagenentAndCollaboration:"",
        budgetAndFinancialControl: ""
    });

    return (
        <TeamMemberContext.Provider value={{ formData, setFormData }}>
            {children}
        </TeamMemberContext.Provider>
    );
};

export default TeamMemberContext;