import React, { useContext, useState, useEffect, useRef } from "react";
import ProjectWorkspaceNav from "./ProjectWorkspaceNav";
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter";
import { RiExpandUpDownLine } from "react-icons/ri";
import "../Styles/AddTask.css";

const AddTask = () => {
    const [chatHistory, setChatHistory] = useState([]);

    // Address Bar for the In-App Browser

    // Ref for the chat container
    const chatContainerRef = useRef(null);

    // Scroll to the last message
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth", // Adds the smooth scrolling effect
            });
        }
    };

    // Scroll to the last message on load or when messages change
    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);

    const FlexibleDiv = ({ width, children, bgc }) => {
        return (
            <div
                style={{
                    width: width,
                    border: "0.5px solid #a1a1a1",
                    borderRadius: "10px",
                    backgroundColor: bgc,
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                }}
            >
                {children}
            </div>
        );
    };


    return (
        <>
            <div id="userpage_container">
                <ProjectWorkspaceNav />
                <div className="project_workspace_container">
                    <ProjectWorkspaceHeader />
                    <div className="ProjectWorkspaceBody">
                        <div
                            className="file_preview_frame overflow-auto"
                            style={{ overflow: "scroll" }}
                        >
                            <div className="d-flex justify-content-between mt-4">
                                <div className="" style={{ width: "47%" }}>
                                    <FlexibleDiv width="100%" bgc="#4c4c4c">
                                        <p className="text14px600 ">Task Title</p>
                                        <input placeholder="File name" className="ru_input2" />
                                    </FlexibleDiv>
                                </div>
                                <div
                                    style={{
                                        width: "47%",
                                    }}
                                >
                                    <FlexibleDiv width="100%" bgc="#4c4c4c">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="text14px600 ">Task Type</p>
                                                <p className="a1a1a112px500 mt-2">Code</p>
                                            </div>
                                            <RiExpandUpDownLine size={32} color="#707070" />
                                        </div>
                                    </FlexibleDiv>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mt-4">
                                <div className="" style={{ width: "47%" }}>
                                    <FlexibleDiv width="100%" bgc="#4c4c4c">
                                        <p className="text14px600 ">Add Files (Optional)</p>
                                        <input placeholder="Selected file" className="ru_input2" />
                                    </FlexibleDiv>
                                </div>
                                <div
                                    style={{
                                        width: "47%",
                                    }}
                                >
                                    <FlexibleDiv width="100%" bgc="#4c4c4c">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="text14px600 ">Linked To:</p>
                                                <p className="a1a1a112px500 mt-2">File name</p>
                                            </div>
                                            <RiExpandUpDownLine size={32} color="#707070" />
                                        </div>
                                    </FlexibleDiv>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mt-4">
                                <div className="" style={{ width: "47%" }}>
                                    <FlexibleDiv width="100%" bgc="#4c4c4c">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="text14px600 ">Visibility</p>
                                                <p className="a1a1a112px500 mt-2">Entire project</p>
                                            </div>
                                            <RiExpandUpDownLine size={32} color="#707070" />
                                        </div>
                                    </FlexibleDiv>
                                </div>
                                <div
                                    style={{
                                        width: "47%",
                                    }}
                                >
                                    <FlexibleDiv width="100%" bgc="#4c4c4c">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="text14px600 ">Assign To</p>
                                                <p className="a1a1a112px500 mt-2">
                                                    John Doe, Jane Cameo
                                                </p>
                                            </div>
                                            <RiExpandUpDownLine size={32} color="#707070" />
                                        </div>
                                    </FlexibleDiv>
                                </div>
                            </div>
                            <p className="text14px600 mt-4">Linked To:</p>
                            <div className="d-flex justify-content-between mt-4">
                                <div
                                    style={{
                                        width: "30%",
                                    }}
                                >
                                    <div>
                                        <div className="d-flex gap-2">
                                            <img alt="" src="/static/images/done.svg" />{" "}
                                            <p className="text14px600">Project</p>
                                        </div>
                                        <div style={{ paddingLeft: "20px" }} className="mt-4">
                                            <div className="d-flex gap-2">
                                                <img alt="" src="/static/images/circle.svg" />{" "}
                                                <p className="text14px600">Brief</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: "30%",
                                    }}
                                >
                                    <div>
                                        <div className="d-flex gap-2">
                                            <img alt="" src="/static/images/done.svg" />{" "}
                                            <p className="text14px600">Feature</p>
                                        </div>
                                        <div style={{ paddingLeft: "20px" }} className="mt-4">
                                            <div className="d-flex gap-2">
                                                <img alt="" src="/static/images/circle.svg" />{" "}
                                                <p className="text14px600">Authentication</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: "30%",
                                    }}
                                >
                                    <div>
                                        <div className="d-flex gap-2">
                                            <img alt="" src="/static/images/done.svg" />{" "}
                                            <p className="text14px600">Task</p>
                                        </div>
                                        <div style={{ paddingLeft: "20px" }} className="mt-4">
                                            <div className="d-flex gap-2">
                                                <img alt="" src="/static/images/circle.svg" />{" "}
                                                <p className="text14px600">Complete User flow</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ProjectWorkspaceFooter />
                </div>
                <ProjectWorkspaceChat />
            </div>
        </>
    );
};

export default AddTask;
