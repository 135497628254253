import React, {useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Styles/CreateNewPassword.css'
const apiUrl = process.env.REACT_APP_API_URL;


const CreateNewPassword =() =>{
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const [status, setStatus] = useState("loading"); // 'loading' | 'success' | 'error'
    const [errorMessage, setErrorMessage] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        if (!token) {
          setStatus("error");
          setErrorMessage("Invalid reset link.");
          return;
        }
    
        fetch(`${apiUrl}/api/verify-reset-token/`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({"token": token }),
        })
        .then(async response => {
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'An error occurred');
            }
            return response.json();
        })
        .then(data => {
            if (data.success) {
                setStatus("success");
            } else {
                setStatus("error");
                setErrorMessage(data.error);
            }
        })
        .catch(error => {
            setStatus("error");
            setErrorMessage("Something went wrong.");
        });
    }, [token]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            toast.success("Passwords do not match.", {
                position: "bottom-right"
            });
            return;
        }
    
        setIsSubmitting(true);
        const response = await fetch(`${apiUrl}/api/reset-password`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ "token":token, "password": password }),
        });
    
        const data = await response.json();
        if (data.success) {
          setResetSuccess(true);
        } else {
          setErrorMessage(data.error);
        }
        setIsSubmitting(false);
    };
    

    if (status === "loading") return <p>Loading...</p>;
    if (status === "error") return <p style={{ color: "red" }}>{errorMessage}</p>;
    if (resetSuccess) {
        return navigate('/password-updated');
    }

    return (
        <>
        <section className='create_new_password_section'>
            <div className='row'>
                <div className='col-md-7 col-lg-4'>
                    <h1>Create new password</h1>
                    <p>create a new and minimum password that is of a minimum of 8 characters that consists of an uppercase, a number and a special character.</p> 
                    <form onSubmit={handleSubmit}>
                        <div className="field-group mb-2">
                            <input
                            type="password"
                            className="form-control"
                            id="floatingPassword"
                            name='password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder=""
                            />
                            <label htmlFor="floatingPassword">Password</label>
                            <i class="toggle-password fa fa-eye position-absolute" id="togglePassword"></i>
                        </div>

                        <div className="field-group mb-2">
                            <input
                            type="password"
                            className="form-control"
                            id="floatingPassword1"
                            name='confirmPassword'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)} required
                            placeholder=""
                            />
                            <label htmlFor="floatingPassword1">Confirm Password</label>
                            <i class="toggle-password fa fa-eye position-absolute" id="togglePassword"></i>
                        </div>
                        <div className='d-grid mt-2'>
                            <button type='submit' className='btn btn-lg' disabled={isSubmitting}>{isSubmitting ? "Updating" : "Update"}</button>
                        </div>
                    </form>
                   
                    <div align='center'>
                        <img src='/static/images/designxcel_logo.png' className='img-fluid' alt='Designxcel Logo'/>
                    </div>
                </div>
            </div>
        </section>
        {/* Sign In Section End */}
        </>
    )
}


export default CreateNewPassword