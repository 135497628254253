import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from './UserProvider';


const Nav =() =>{
    const Location = useLocation()
    const currentPath = Location.pathname
    const { user } = useContext(UserContext);

    const isActive = (path) => path === currentPath ? 'active' : '';
    


    return (
        <>
        <nav className="navbar navbar-expand-lg navbar-expand-lg mt-2">
            <div className="container d-flex justify-content-between align-items-center">
                <Link className="navbar-brand" to='/'>
                    <img src="/static/images/designxcel_logo.png" alt="designxcel logo" className="img-fluid" />
                    <span class="beta-badge">Beta</span>
                </Link>
                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon">
                        <div></div>
                    </span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto me-auto mb-2 mb-lg-0 gap-5 me-5">
                        <li className="nav-item">
                        <Link className={`nav-link ${isActive('/')}`} aria-current="page" to="/">
                            Home
                        </Link>
                        </li>
                        <li className="nav-item">
                        <Link className={`nav-link ${isActive('/services')}`} to="/services">
                            Services
                        </Link>
                        </li>
                        <li className="nav-item">
                        <Link className={`nav-link ${isActive('/pricing')}`} to='/pricing'>
                            Pricing
                        </Link>
                        </li>
                        <li className="nav-item">
                        <Link className={`nav-link ${isActive('/requests')}`} to='/requests'>
                            Requests
                        </Link>
                        </li>
                    </ul>
                    <div>
                        {user ? (<Link className="btn btn-lg login_button" to='/dashboard'>
                            Dashboard
                        </Link>) : (<Link className="btn btn-lg login_button" to='/login'>
                            Login
                        </Link>) }            
                    </div>
                </div>
            </div>
        </nav> 
        </>
    )
}

export default Nav