// import React, { useContext, useState, useEffect, useRef } from "react";
import ProjectWorkspaceNav from './ProjectWorkspaceNav';
import ProjectWorkspaceChat from "./ProjectWorkspaceChat";
// import { SidebarContext } from "./Sidebar";
import ProjectWorkspaceFooter from "./ProjectWorkspaceFooter"
import '../Styles/ReviewAndApproval1.css';
import '../Styles/SubmitReview.css';
import ProjectWorkspaceHeader from "./ProjectWorkspaceHeader";


export default function SubmitReview () {
    return (
        <div id="userpage_container">
            <ProjectWorkspaceNav />
            <div className="project_workspace_container">
                <ProjectWorkspaceHeader />
                <div className="container submit_review_container">
      {/* Submission Section */}
      <div className="grid">
        <div className="input-box">
          <label>Submission Name</label>
          <select>
            <option>Select a deliverable or add new</option>
          </select>
        </div>

        <div className="input-box">
          <label>Deliverable Type</label>
          <select>
            <option>Code</option>
          </select>
        </div>

        <div className="input-box">
          <label>Upload Files</label>
          <input type="file" />
        </div>

        <div className="input-box">
          <label>Provide Link</label>
          <input type="text" placeholder="Add Figma, GitHub, or relevant URLs" />
        </div>

        <div className="input-box">
          <label>Linked To</label>
          <select>
            <option>Authentication, Home, 2 others</option>
          </select>
        </div>
      </div>

      {/* Review Notes & Submission Details */}
      <h2>Review Notes & Submission Details</h2>
      <div className="grid">
        <div className="input-box2">Task Summary<p>Briefly describe what has been completed and any key points to note</p></div>
        <div className="input-box2">Changes Implemented<p>List major updates, bug fixes, or improvements made</p></div>
        <div className="input-box2">Reviews: Known Issues or Concerns<p>Highlight any pending issues that may need further attention</p></div>
      </div>

      {/* Review & Approval */}
      <h2>Review & Approval Process</h2>
      <div className="grid">
        <div className="input-box">
          <label>Review Type</label>
          <p>Internal Review</p>
        </div>
        <div className="input-box">
          <label>Team Access</label>
          <p>John Doe, Jane Cameo</p>
        </div>
      </div>
    </div>

                <ProjectWorkspaceFooter />
            </div>
            <ProjectWorkspaceChat />
        </div>

    )

}

